import { Stop } from "@mui/icons-material";
import React from "react";
// import { NavLink } from "react-router-dom";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const ERP = () => {

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoft.co.in/erp" />
          <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,ERP" />
          <title> ERP | Web Development Company In Pune | iCloudsoft Technology </title>
        </Helmet>
      </HelmetProvider>

      <Header />

      <div className="Company bg-light">
        <div className="company-boxA">
          <div className="row p-lg-4 g-4  m-2 ">
            <div className="col-md-6 ps-lg-5 mt-5">
              <h1 className="mb-3"><b>ERP Software</b></h1>
              <p>In accounting, the acronym ERP stands for enterprise resource planning – which is a type of business management software. ERP finance modules offer many of the same features as accounting software, such as tools for accounts receivable and payable, general ledger, expense management, reporting and analysis, and more.</p>
              <p>iCloudsoft offers end-to-end ERP development and support to help you get a deep visibility into business operations, streamline information flows and optimize business costs.ERP is an application that automates business processes and provides insights and internal controls, drawing on a central database that collects inputs from departments including accounting, manufacturing, supply chain management, sales, marketing and human resources</p>
            </div>
            <div className="col-md-6">
              <img src={require("../images/ERP-Header.png")} alt="ERP-Header" className="img-fluid w-75 " />
            </div>
          </div>
        </div>

        <div className="company-box2  bg-secondary-subtle m-3">
          <h2 className="text-center p-3 mb-3">ERP Development Service Option</h2>
          <div className="row g-4">

            <div className="col-md-6 ">
              <div className="co p-2 d-flex" >
                <h4 className="ms-3 mt-2 mb-2">Developing an ERP system from scratch</h4>

              </div>
              <h6 className="mt-3"><Stop className="ar" /> Business analysis.</h6>
              <h6><Stop className="ar" />End-to-end software development.</h6>
              <h6><Stop className="ar" />Data migration.</h6>
              <h6><Stop className="ar" />Quality assurance.</h6>
            </div>
            <div className="col-md-6">
              <div className="co p-2 d-flex" >
                <h4 className="ms-5 mt-2 mb-2">Developing an ERP module</h4>

              </div>
              <h6 className="mt-3"><Stop className="ar" />Architecture, UX and UI design of an ERP module.</h6>
              <h6><Stop className="ar" />Module development.</h6>
              <h6><Stop className="ar" />QA (manual and automated testing).</h6>
              <h6><Stop className="ar" />Maintenance and support.</h6>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}
export default ERP;