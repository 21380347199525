import React from "react";
import { Stop } from "@mui/icons-material";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const SharePoint = () => {

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoft.co.in/sharepoint-office-365" />
          <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,PowerBI " />
          <title> PowerBI | Web Development Company In Pune  </title>
        </Helmet>
      </HelmetProvider>

      <Header />

      <div className="Company bg-light">
        <div className="company-boxA">
          <div className="row p-lg-4 g-4 m-2 ">
            <div className="col-md-6 ps-lg-5 mt-5">
              <h1 className="mb-3"><b>SharePoint</b></h1>
              <p>Microsoft has been named a Leader in the  Content Services Platforms Magic Quadrant. The company’s most popular collaboration platforms are Office 365 and SharePoint Online. They are used to build feature-rich and cost-effective solutions for document, contract, and knowledge management and internal and external collaboration for businesses of different sizes and industries.</p>
              <p>SharePoint is a web-based collaboration and document management platform developed by Microsoft. It is primarily used for managing and sharing documents, lists, and information within organizations. SharePoint provides a centralized location for employees to store, organize, and access files and data from anywhere, using any device.</p>
            </div>
            <div className="col-md-6">
              <img src={require("../images/SharePoint.png")} alt="SharePoint" className="img-fluid w-50 mt-4" style={{ marginLeft: "3rem" }} />
            </div>
          </div>
        </div>


        <div className="company-box2  bg-secondary-subtle m-3">
          <h2 className="text-center p-3 mb-3">SharePoint Functionality</h2>
          <div className="row g-4">
            <div className="col-md-3 mb-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-3 mb-3">Document management</h5>
              </div>
              <h6 className="mt-3"><Stop className="ar" />Templates.</h6>
              <h6><Stop className="ar" />Co-authoring.</h6>
              <h6><Stop className="ar" />Compliance management.</h6>
              <h6><Stop className="ar" />Document lifecycle automation.</h6>
              <h6><Stop className="ar" />E-signature software integration.</h6>
            </div>
            <div className="col-md-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-3 mb-3">Collaboration</h5>
              </div>
              <h6 className="mt-3"><Stop className="ar" />Calendar.</h6>
              <h6><Stop className="ar" />Employee self-service.</h6>
              <h6><Stop className="ar" />Communication tools </h6>
              <h6><Stop className="ar" />Organization chart and employee profiles.</h6>
              <h6><Stop className="ar" />Quick links to other corporate systems and information</h6>
            </div>
            <div className="col-md-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-3 mb-3">Human resources management</h5>
              </div>
              <h6 className="mt-3"><Stop className="ar" />Payroll management.</h6>
              <h6><Stop className="ar" />Employee request management.</h6>
              <h6><Stop className="ar" />Employee performance tracking.</h6>
              <h6><Stop className="ar" />Time and attendance management.</h6>
              <h6><Stop className="ar" />Employee information management.</h6>
            </div>
            <div className="col-md-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-3 mb-3">Learning management</h5>
              </div>
              <h6 className="mt-3"><Stop className="ar" />Learning assessment.</h6>
              <h6><Stop className="ar" />Competency management.</h6>
              <h6><Stop className="ar" />Certification management.</h6>
              <h6><Stop className="ar" />Assigning and scheduling trainings.</h6>
              <h6><Stop className="ar" />Learning materials creation and storage.</h6>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
export default SharePoint;