import React from "react";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Stop } from "@mui/icons-material";
// import { NavLink } from "react-router-dom";

const Elearning = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/elearning" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Elearning" />
                    <title> Elearning | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />
            
            <div className="Company">
                <div className="company-boxA">
                    <div className="row g-4 p-lg-4  m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1><b>Elearning Software Development</b></h1>
                            <p>iCloudsoft has been building eLearning solutions that drive digital transformation of corporate learning resulting in human capital ROI growth.
                                iCloudsoft team is ready to streamline your corporate learning with technology or modernize your current eLearning software.Elearning can be delivered through a range of digital platforms, such as learning management systems (LMS), mobile apps, and online video platforms. T</p>
                            <p>Elearning, also known as electronic learning or online learning, refers to the use of digital technologies and the internet to deliver educational content and training programs to learners. Elearning has become increasingly popular in recent years, as more and more people seek flexible and convenient ways to learn new skills and advance their careers.</p>                       </div>
                        <div className="col-md-6  mt-5">
                            <img src={require("../images/software.png")} alt="software" className="img-fluid w-75 " />
                        </div>
                    </div>
                </div>

                <div className="company-box2  bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Area of Expertise</h2>
                    <div className="row g-4">
                        <div className="col-md-3 mb-3">
                            <div className="co  d-flex">
                                <h5 className="ms-3 mt-2">Learning content development & management</h5>
                            </div>
                            <h6 className="mt-3"><Stop className="ar" />Multiple content types</h6>
                            <h6><Stop className="ar" />Built-in content authoring</h6>
                            <h6><Stop className="ar" />Reusable content and templates.</h6>
                            <h6><Stop className="ar" />Content tagging and intelligent search.</h6>
                        </div>
                        <div className="col-md-3">
                            <div className="co  d-flex">
                                <h5 className="ms-3 mt-2">Learning process administration & delivery</h5>
                            </div>
                            <h6 className="mt-3"><Stop className="ar" />Online proctoring</h6>
                            <h6><Stop className="ar" />VR and AR simulators.</h6>
                            <h6><Stop className="ar" />Performance assessment.</h6>
                            <h6><Stop className="ar" />Learning event scheduling </h6>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-3">Analytics & reporting</h5>
                            </div>
                            <h6 className="mt-3"><Stop className="ar" />Learning history.</h6>
                            <h6><Stop className="ar" />Learners’ feedback gathering.</h6>
                            <h6><Stop className="ar" />User regulatory compliance reporting.</h6>
                            <h6><Stop className="ar" />Content usage, user activity, and performance assessment dashboards.</h6>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-3 d-flex">
                                <h5 className="ms-3 mt-1">Integrations</h5>
                            </div>
                            <h6 className="mt-3"><Stop className="ar" />HR software.</h6>
                            <h6><Stop className="ar" />Social media.</h6>
                            <h6><Stop className="ar" />Task management software.</h6>
                            <h6><Stop className="ar" />Content management systems.</h6>
                            <h6><Stop className="ar" />Knowledge management systems.</h6>
                        </div>


                    </div>
                </div>
            </div>

        </>
    )
}
export default Elearning;