import React, { useState } from "react";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const TransportationLogistics = () => {


  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoft.co.in/transportation-and-logistics" />
          <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Transportation" />
          <title> Transportation | Web Development Company In Pune  </title>
        </Helmet>
      </HelmetProvider>

      <Header />

      <div className="Company bg-light">
        <div className="company-boxA">
          <div className="row p-lg-4 g-4 m-2 ">
            <div className="col-md-6 ps-lg-5 mt-5">
              <h1 className="mb-3"><b>Transportation & Logistics</b></h1>
              <p>iCloudsoft has provided tailored IT solutions and dedicated services for the transportation and logistics (T&L) industry, which propel digital transformation across business management and all major T&L activities.</p>
              <p>IT services for transportation and logistics (T&L) focus on consulting, implementation, support and evolution of T&L solutions and aim to help T&L companies streamline and reduce costs of logistics operations, enhance asset efficiency, and improve customer experience.</p>
            </div>
            <div className="col-md-6">
              <img src={require("../images/transport-1.png")} alt="" className="img-fluid w-75 " style={{ marginLeft: "3rem" }} />
            </div>
          </div>
        </div>

        <div className="company-box2  bg-secondary-subtle m-3">
          <h2 className="text-center p-3 mb-3">Area of Expertics</h2>
          <div className="row g-4">
            <div className="col-md-4 mb-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">Logistics service providers (LSPs)</h5>
              </div>
              <p className="p-3">Freight forwarders, third-party and fourth-party logistics service providers.</p>
            </div>
            <div className="col-md-4">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">Carrier companies</h5>
              </div>
              <p className="p-3">Trucking, rail freight, sea freight and air freight companies, including last-mile delivery providers.</p>
            </div>
            <div className="col-md-4">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">Courier express parcel (CEP) companies</h5>
              </div>
              <p className="p-3">CEP services providers operating in B2B and B2C segments.</p>
            </div>
            <div className="col-md-4">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">Logistics marketplaces</h5>
              </div>
              <p className="p-3">Providers of digital platforms that enable direct interaction between multiple T&L providers and buyers of T&L services.</p>
            </div>
            <div className="col-md-4">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">IT product companies</h5>
              </div>
              <p className="p-3">Companies looking to develop software products for the T&L industry.</p>
            </div>
            <div className="col-md-4">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">Enterprises involved in T&L activities</h5>
              </div>
              <p className="p-3">Non-T&L companies that need to manage large T&L teams and in-house fleet.</p>
            </div>


          </div>
        </div>
      </div>
      
    </>
  )
}
export default TransportationLogistics;