import React from "react";
import Header from "./Header";
import { Stop } from "@mui/icons-material";
// import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';



const ProfessionalServices = () => {

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoft.co.in/professional-services" />
          <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,ProfessionalServices" />
          <title> ProfessionalServices | Web Development Company In Pune  </title>
        </Helmet>
      </HelmetProvider>

      <Header />

      <div className="Company bg-light">
        <div className="company-boxA">
          <div className="row p-lg-4 g-4 m-2 ">
            <div className="col-md-6 ps-lg-5 mt-5">
              <h1 className="mb-3"><b>Professional Services</b></h1>
              <p>IT solutions for professional services allow businesses to digitalize service delivery and automate internal operations for increased productivity and a more competitive advantage in their industry. iCloudsoft enables professional services companies to acquire new customers with digital service delivery channels and optimize internal business processes, like project management, employee collaboration, and more.</p>
              <p>Professional services are occupations in the service sector requiring special training in the arts or sciences. Some professional services, such as architects, accountants, engineers, doctors, and lawyers require the practitioner to hold professional degrees or licenses and possess specific skills</p>
            </div>
            <div className="col-md-6">s
              <img src={require("../images/professiona-services.png")} alt="" className="img-fluid w-75 mt-5" tyle={{ marginLeft: "3rem" }} />
            </div>
          </div>
        </div>


        <div className="company-box2 bg-secondary-subtle m-3">
          <h2 className="text-center p-3 mb-3">Our Services</h2>
          <div className="row g-4">
            <div className="col-md-6">
              <div className="co p-2 d-flex" >
                <h4 className="ms-3 mt-2 mb-2" style={{fontSize:"21.6px"}}>Solution consulting, implementation and support for professional services</h4>
              </div>
              <p className="mt-3"><b>We consult on:</b></p>
              <h6><Stop className="ar" />Choosing suitable solutions and their optimal functionality to meet your business needs.</h6>
              <h6><Stop className="ar" />Reducing implementation costs.</h6>
              <p><b>We offer:</b></p>
              <h6><Stop className="ar" />End-to-end solution implementation.</h6>
              <h6><Stop className="ar" />After-launch support.</h6>
            </div>

            <div className="col-md-6">
              <div className="co p-2 d-flex" >
                <h4 className="ms-3 mt-2 mb-2">IT outsourcing for professional services</h4>
              </div>
              <h6 className="mt-3"><Stop className="ar" />Business analysis</h6>
              <h6><Stop className="ar" />Software development.</h6>
              <h6><Stop className="ar" />QA</h6>
              <h6><Stop className="ar" />Data analytics.</h6>
              <h6><Stop className="ar" />IT support.</h6>
              <h6><Stop className="ar" />Managed infrastructure services.</h6>
              <h6 className="pb-3"><Stop className="ar" />Help desk.</h6>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}
export default ProfessionalServices;