// import { Stop, NavigateNext } from "@mui/icons-material";
import React from "react";
// import { NavLink } from "react-router-dom";
import "./Common.css";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const SupplyChain = () => {

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoft.co.in/supply-chain-management" />
          <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,SupplyChain" />
          <title> SupplyChain | Web Development Company In Pune | iCloudsoft Technology </title>
        </Helmet>
      </HelmetProvider>

      <Header />

      <div className="Company bg-light">
        <div className="company-boxA">
          <div className="row g-4 p-lg-4  m-2 ">
            <div className="col-md-6 ps-lg-5 mt-5">
              <h1 className="mb-3"><b>Supply Chain Management</b></h1>
              <p>Documentation automation software reduces the drafting time of highly formalized documents (e.g., financial statements, invoices, government forms, employment contracts) by employing reusable document templates that leverage conditional logic and connected data sources. Documentation automation software integrates with OCR, DMS, CRM software, etc.</p>
              <p>Elearning, also known as electronic learning or online learning, refers to the use of digital technologies and the internet to deliver educational content and training programs to learners. Elearning has become increasingly popular in recent years, as more and more people seek flexible and convenient ways to learn new skills and advance their careers.</p>
            </div>
            <div className="col-md-6">
              <img src={require("../images/Supply-Chain1.png")} alt="Supply-Chain1" className="img-fluid w-75 " />
            </div>
          </div>
        </div>

        <div className="company-box2 bg-secondary-subtle m-3">
          <h2 className="text-center p-3 mb-3">Types of AR apps we develop</h2>
          <div className="row g-4">
            <div className="col-md-6">
              <div className="co p-2 d-flex" >
                <h4 className="ms-3 mt-2 mb-2">Supply chain management consulting</h4>
              </div>
              <p className="p-3">Analyze your supply chain strategy and operations. Analyze your business strategy and how well a supply chain strategy integrates with it.Evaluate how supply chain operations are currently supported with technology. Identify the areas of improvement and map them to possible technology solutions.</p>
            </div>
            <div className="col-md-6">
              <div className="co p-2 d-flex" >
                <h4 className="ms-3 mt-2 mb-2">Supply chain management software implementation and support</h4>
              </div>
              <p className="p-3">Develop a Proof-of-Concept (optional).Implement a custom supply chain management solution OR select and set up off-the-shelf software.Integrate the solution with your existing systems.Migrate necessary data from currently used systems.</p>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}
export default SupplyChain;