import React from "react";
// import { Stop ,NavigateNext} from "@mui/icons-material";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';


// import { NavLink } from "react-router-dom";


const BigData = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/big-data" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,BigData" />
                    <title> BigData | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4 m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>Big Data</b></h1>
                            <p>Big data services help companies maximize data value and achieve business goals with big data analysis. iCloudsoft renders a range of big data services, including consulting, implementation, support, and big  data as a service to help clients benefit from the big data environment.</p>
                            <p> data that contains greater variety, arriving in increasing volumes and with more velocity. This is also known as the three Vs. Put simply, big data is larger, more complex data sets, especially from new data sources.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/big1.png")} alt="big1" className="img-fluid w-75 " style={{ marginLeft: "3rem" }} />
                        </div>
                    </div>
                </div>


                <div className="company-box2 bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">iCloudsoft Big Data Services</h2>
                    <div className="row g-2">
                        <div className="col-md-3 mb-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Big data consulting</h5>
                            </div>
                            <p className="p-3">A proof of concept User adoption strategies.Recommendations on data quality management.Bigdata implementation strategies and detailed roadmaps</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Big data implementation</h5>
                            </div>
                            <p className="p-3">Big data needs analysis. ML models development.Big data solution development Setup of big data governance procedures Big data solution architecture and design.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Big data support</h5>
                            </div>
                            <p className="p-3">Big data cleaning.Big data software updating. Big data backup and recovery. Big data solution health checks.Adding new users and handling permissions.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Big data managed analytics services</h5>
                            </div>
                            <p className="p-3">ML model development and tuning. ML model development and tuning. Big data extraction and management.Predefined and ad hoc reports. Big data solution infrastructure setup and support.</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default BigData;