import React from "react";
// import { Stop, NavigateNext } from "@mui/icons-material";
import Header from "./Header";
// import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Retail = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/retail" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Retail" />
                    <title> Retail | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4 m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b> Retail</b></h1>
                            <p>Retail IT services focus on achieving higher efficiency in business operations and creating truly customer-centric experience for digital and physical sales channels. With of domain experience, our retail IT consultants offer custom software solutions to the entire retail ecosystem.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/rent-1.png")} alt="" className="img-fluid w-50 " style={{ marginLeft: "3rem" }} />
                        </div>
                    </div>
                </div>

                <div className="company-box2  bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Software Solutions We Provide</h2>
                    <div className="row g-2">
                        <div className="col-md-4 mb-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Customer Experience</h5>

                            </div>
                            <p className="p-3">As consumers expect a truly omni-channel experience, we respond to this challenge by helping brands to provide consistent and personalized service – before, during and after a purchase.

                                CRM and marketing automation: developing personalized and lasting relationships with complete, actionable customer data at hand.
                                Loyalty programs: implementing customer retention strategies through СRM-powered management of loyalty schemes across in-store, web and mobile channels.
                                In-store experience: bringing next-gen technologies to your physical outlets, including integration of POS terminals and mobile devices.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Supply Chain</h5>

                            </div>
                            <p className="p-3">We enable real-time visibility into every stage of the supply chain, from manufacturing to checkout.

                                Supply chain management: automating your buying and selling processes that cover inventory management, store operations and financials.
                                Private label product management: managing relationships with manufacturers and monitoring products throughout their lifecycles.
                                Trade promotion management: gaining a complete control of planning, execution and analysis of trade promotions at every outlet.
                                E-collaboration: making remote collaboration possible between buyers, vendors and private label manufacturers.
                                Enterprise mobile apps: powering your staff with role-specific mobile solutions for real-time access to work-critical functionality.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">E-Commerce</h5>

                            </div>
                            <p className="p-3">We deliver scalable solutions that meet consumers’ requirements, facilitate their enjoyable, secure shopping experience and supply operational analytics for marketing and sales.

                                Online shopping cart: enabling secure transaction processing with a broad selection of payment gateways.
                                Integration: connecting your ecommerce module with the entire line-up of enterprise systems (ERP, CRM, back office and more).
                                mCommerce: offering mobile shopping for consumers across a variety of devices.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mb-2 mt-2">Data Analysis</h5>

                            </div>
                            <p className="p-3">We help you make informed decisions based on rich analytics sourced from your IT ecosystem and customer touchpoints.

                                Data warehousing and master data management: putting together a single version of truth by cleansing and integrating your data from multiple distributed sources.
                                OLAP cubes: handling careful combinations of relevant metrics on customer behavior, inventory, operations, finance and more from the standpoints you require.
                                Reporting UI: providing users with interactive reporting dashboards that can be tailored to their particular role.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mb-2 mt-2">Computer Vision</h5>

                            </div>
                            <p className="p-3">iCloudsoft offers the development of custom image analysis solutions to increase the level of customer service in retail:

                                Facial recognition: from recognition of regular customers to provide them with personalized deals to identification of potential criminals.
                                Emotion recognition: assessment of the customer satisfaction level with a smart camera.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mb-2 mt-2">Virtual Reality</h5>

                            </div>
                            <p className="p-3">iCloudsoft brings the cutting-edge technologies of VR to create a unique and convenient shopping experience, such as interactive virtual stores that the customers can browse from any location using popular VR platforms.</p>
                        </div>


                    </div>
                </div>
            </div>


        </>
    )

}
export default Retail;