import React from "react";
import Header from "./Header";
// import { NavLink } from "react-router-dom";
// import { NavigateNext } from "@mui/icons-material";
import { Helmet, HelmetProvider } from 'react-helmet-async';



const ECommerce = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/e-commerce" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,E-Commerce " />
                    <title> E-Commerce | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4  m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>E-Commerce Website Development Services</b></h1>
                            <p>We create memorable websites that sell. With our eCommerce expertise we work on every element of your website that perfectly aligns with your brand voice and volumes. If you are proud of your brand, our eCommerce development expertise will make you proud of your website.</p>
                            <p>We are old mavens at nailing the success of eCommerce development. But delivering a competitive eCommerce website is not the usual website development. A website with fast page loads, flawless checkout, comprehensive conversation tracking, mind-boggling UX/UI, clear calls-to-action, kickass messaging and definitely design that works on every device is fast-to-market. </p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/ecommerce-1.png")} alt="ecommerce-1" className="img-fluid w-100  " />
                        </div>
                    </div>
                </div>

                <div className="company-box2  bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Area Of Expertise</h2>
                    <div className="row g-4">

                        <div className="col-md-6">
                            <div className="co p-2 d-flex" >
                                <h4 className="ms-3 mt-2 mb-2">Shopify Development Services</h4>
                            </div>
                            <p className="p-3">Boldly transform the idea of online business into reality with full-service expert Shopify Development Company. We harness the power of the best themes and rich features of Shopify to offer you a competing online store.
                                In the e-era the value lies with your app and website. The Shopify experts at iCloudsoft are conversant with the trends and most advanced features to develop an eCommerce website with any kind of requirement. Our most advanced Shopify development services can create a new store with rich features, theme and flawless functionality or help you to maintain an already existing store.</p>
                        </div>
                        <div className="col-md-6">
                            <div className="co p-2 d-flex" >
                                <h4 className="ms-3 mt-2 mb-2">WooCommerce Development Services</h4>
                            </div>
                            <p className="p-3">With divergent thinking, strong WordPress fundamentals and proven strategies we transform problems and build eCommerce solutions the “WordPress way”. Our WooCommerce developers are experts in pushing the boundaries of WooCommerce capabilities, you demand it and we build it.
                                Unconventional eCommerce experience from leading WooCommerce Development Company is just a click away.</p>
                        </div>
                        <div className="col-md-6">
                            <div className="co p-2 d-flex" >
                                <h4 className="ms-3 mt-2 mb-2">WordPress Development</h4>
                            </div>
                            <p className="p-3">The creators of trends and followers of the future, our stellar WordPress developers offer custom full-blown WordPress development services following the latest standards in dynamic Web application development.
                                iCloudsoft, the leading WordPress development company elevates your content management business processes.
                                Content management websites don’t have to be impersonal, instead they should invite customers as friends. iCloudsoft fulfils that by developing dynamic WCM websites that are warm with tranquil inviting spaces. Our WordPress developers back it all with their profound technical expertise and industry best practices while delivering varied WordPress customization services, including eCommerce solutions, CMS functionality extensions, advanced web content and customer experience management applications.
                                Elevate your online presence with our advanced WordPress development solutions that spans a range from core publishing website development to enterprise-wide platforms.</p>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="co p-2 d-flex" >
                                <h4 className="ms-3 mt-2 mb-2">Magento Development Services</h4>
                            </div>
                            <p className="p-3">Whether a startup needs a kick with their digital strategies or an established retail store is in search of a new technology and perspective, we are leading Magento Development Company creating digital existence that ensures results.
                                An online store is more valuable than your retail store considering the outreach of customers which it provides 24x7 across the globe. In the age of sophisticated e-tail customers, iCloudsoft breaks the customer attention bar by delivering Magento web development services that are sure shot to make your eCommerce - a success.
                                Our certified Magento developers work from ideas to solutions, emerging beyond the screens to in-store processes for delivering you services that range from multi-store platforms to single-store applications, from extensions development to migration.</p>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
export default ECommerce;