import React from "react";
import Header from "./Header";
import "./Common.css";
import { Helmet, HelmetProvider } from 'react-helmet-async';

// import { NavigateNext, Stop } from "@mui/icons-material";
// import { NavLink } from "react-router-dom";

const Testing = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/testing" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Testing " />
                    <title> Testing | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4  m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>Testing & QA Service</b></h1>
                            <p>iCloudsoft has built testing expertise in healthcare, manufacturing, retail, wholesale, logistics, and other industries. Our goal-driven self-managed testing experts can quickly dive into your project and validate every aspect of your software: functionality, integrations, performance, usability, and security.</p>
                            <p>We take full responsibility for the success of an application. That’s why the ideal team for us is the one that has professional QA testers behind. For those who want to experience positive cooperation with the development team and receive first-rate products, we recommend turning attention to Quality Assurance testing services.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/testing1.png")} alt="" className="img-fluid w-50" style={{ marginLeft: "4rem" }} />
                        </div>
                    </div>
                </div>

                <div className="company-box2 bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Our enterprise software testing offering</h2>
                    <div className="row g-4">
                        <div className="col-md-4 mb-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">ERP</h5>
                            </div>
                            <p className="p-3">iCloudsoft offers end-to-end ERP development and support to help you get a deep visibility into business operations, streamline information flows and optimize business costs.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">CRM</h5>
                            </div>
                            <p className="p-3">iCloudsoft has been working with CRM systems to build effective and rewarding sales, marketing and customer service processes for our clients.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">E-Commerce</h5>
                            </div>
                            <p className="p-3">Our eCommerce expertise we work on every element of your website that perfectly aligns with your brand voice and volumes. If you are proud of your brand, our eCommerce development expertise will make you proud of your website.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Project Management Software</h5>
                            </div>
                            <p className="p-3">Project management (PM) software streamlines administrative decisions on projects and aligns collaboration between project managers, project team members, and other project stakeholders.We can outline the core functionality of a custom project management system.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Supply chain management software</h5>
                            </div>
                            <p className="p-3">iCloudsoft provides companies in industries with consulting and practical assistance on the design and implementation of reliable supply chain management software.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Product Software Development</h5>
                            </div>
                            <p className="p-3">iCloudsoft provides companies in industries with advisory and practical assistance on the design and implementation of reliable financial management software to improve their corporate finance processes.</p>
                        </div>


                    </div>
                </div>


            </div>


        </>
    )
}
export default Testing;