import React from "react";
import "./Dotnet.css";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const Dotnet = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/net" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,DotNET " />
                    <title> DotNET | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4 m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>.NET</b></h1>
                            <p>The .NET Framework is a software development framework developed by Microsoft that provides a runtime environment and a set of libraries and tools for building and running applications on Windows operating systems.The framework includes a variety of programming languages, such as C#, F#, and Visual Basic, and supports a range of application types, including desktop, web, mobile, and gaming applications.</p>
                            <p>.NET software development services cover engineering and evolution of web, mobile, and desktop applications with the use of .NET platform. Mature and impact-focused, iCloudsoft builds robust .NET apps from scratch and modernizes legacy solutions fast and at 20-50% reduced costs.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/net-1.png")} alt="net-1" className="img-fluid w-50 mt-5" style={{ marginLeft: "5rem" }} />
                        </div>
                    </div>
                </div>

                <div className="company-box2 bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Area Of Expertise</h2>
                    <div className="row g-2">
                        <div className="col-md-3 mb-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-3 mb-3">Business analysis </h5>
                            </div>
                            <p className="p-3">Our business analysts handle needs assessment and high-level scope definition of your .NET project, while dedicated PMs from our Project Management Office outline project requirements, tech stack, schedule, and more in the detailed project documentation.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-3 mb-3">UX/UI design</h5>
                            </div>
                            <p className="p-3">The neat UI mockups you approve before the development launch result from our UX research, in-depth story mapping, and usability testing of wireframes and prototypes. Our UX/UI design experts put users top of mind to deliver vivid, intuitive interfaces that ensure high adoption of your .NET software.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-3 mb-3">.NET software development</h5>
                            </div>
                            <p className="p-3">Apart from providing .NET Core, ADO.NET, and ASP.NET development services, iCloudsoft engineers leverage Universal Windows Platform, Windows Presentation Foundation, Xamarin, and a wide range of other tools. We guarantee fast and on-time software delivery by establishing Agile & Lean processes</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-3 mb-3">.NET application integration</h5>
                            </div>
                            <p className="p-3">Our integration architects and developers can establish secure, reliable and fast connections between your .NET applications and other internal/external software solutions.</p>
                        </div>


                    </div>
                </div>

            </div>


        </>
    )
}
export default Dotnet;