import { Stop } from "@mui/icons-material";
import React from "react";
import Header from "./Header";
import "./Java.css";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const Java = () => {

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoft.co.in/java" />
          <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Java " />
          <title> Java | Web Development Company In Pune  </title>
        </Helmet>
      </HelmetProvider>

      <Header />
      
      <div className="Company bg-light">
        <div className="company-boxA">
          <div className="row g-4 p-lg-4  m-2 ">
            <div className="col-md-6 ps-lg-5 mt-5">
              <h1 className="mb-3"><b>Java</b></h1>
              <p>Java development services cover implementation of enterprise apps, software products, and SaaS solutions using secure,
                portable and scalable Java frameworks. iCloudsoft helps clients build sustainable Java-based software fast and cost-effectively</p>
              <p>Java is a high-level, class-based, object-oriented programming language that is designed to have as few implementation dependencies as possible. It is a general-purpose programming language intended to let programmers write once, run anywhere , meaning that compiled Java code can run on all platforms that support Java without the need to recompile</p>
            </div>
            <div className="col-md-6">
              <img src={require("../images/java-2.png")} alt="java-2" className="img-fluid w-50" style={{ marginLeft: "4rem" }} />
            </div>
          </div>
        </div>

        <div className="company-box2 bg-secondary-subtle m-3">
          <h2 className="text-center p-3 mb-3">What We Build in Java</h2>
          <div className="row g-4">
            <div className="col-md-3 mb-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-3 mb-3">Java-based products</h5>
              </div>
              <h6 className="m-3"><Stop className="ar" />Self-service applications (including mobile banking).</h6>
              <h6 className="m-3"><Stop className="ar" />Multiplayer game back end.</h6>
              <h6 className="m-3"><Stop className="ar" />VoIP and messaging applications.</h6>
              <h6 className="m-3"><Stop className="ar" />Video streaming and processing software.</h6>
            </div>
            <div className="col-md-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-3 mb-3">Innovative software in Java</h5>
              </div>
              <h6 className="m-3"><Stop className="ar" />Big data processing and analysis software</h6>
              <h6 className="m-3"><Stop className="ar" />Image analysis software.</h6>
              <h6 className="m-3"><Stop className="ar" />Cybersecurity applications.</h6>
            </div>
            <div className="col-md-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-3 mb-3">Enterprise Java-based applications</h5>
              </div>
              <h6 className="m-3"><Stop className="ar" />Ecommerce websites.</h6>
              <h6 className="m-3"><Stop className="ar" />Industry-specific applications, e.g., EHR, online banking, POS.</h6>
              <h6 className="m-3"><Stop className="ar" />Web portals (vendor, customer, self-service, ecommerce, community portals)</h6>
            </div>
            <div className="col-md-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-3 mb-3">Other software in Java</h5>
              </div>
              <h6 className="m-3"><Stop className="ar" />Private/public APIs.</h6>
            </div>


          </div>
        </div>
      </div>

    </>
  )
}
export default Java;