// import { Stop, NavigateNext } from "@mui/icons-material";
import React from "react";
// import { NavLink } from "react-router-dom";
import Header from "./Header";

import { Helmet, HelmetProvider } from 'react-helmet-async';


const DataAnalysis = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/data-analysis" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,DataAnalysis " />
                    <title> DataAnalysis | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4  m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>Data Analytics Services</b></h1>
                            <p>Data analytics implies building an infrastructure for data aggregation, analysis, and reporting. An experienced provider of data analytics services, iCloudsoft delivers on simple and complex needs with tailored business analytics solutions.</p>
                            <p>Data Analytics is an important part of Artificial Intelligence. It expand the business, helps them to acquire brand value by making a data-driven decision.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/data-1.png")} alt="data-1" className="img-fluid w-75  " />
                        </div>
                    </div>
                </div>

                <div className="company-box2 bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Area Of Expertise</h2>
                    <div className="row g-4">
                        <div className="col-md-4 mb-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mb-2 mt-2">Data integration & data warehousing</h5>
                            </div>
                            <h6 className="mt-3">Extract, transform, load (ETL) or extract, load, transform (ELT) design and implementation.</h6>
                            <h6>Data governance implementation.</h6>
                            <h6 className="mb-3">DataData warehouse and data marts design and implementation.</h6>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mb-2 mt-2">Big Data</h5>
                            </div>
                            <h6 className="mt-3">Big data infrastructure setup and support.</h6>
                            <h6>DataBig data quality and security management.</h6>
                            <h6 className="mb-3">DataBig data capture, analysis and reporting.</h6>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mb-2 mt-2">Data science</h5>

                            </div>
                            <h6 className="mt-3">Data preparation and management.</h6>
                            <h6>Development and tuning of data mining models.</h6>
                            <h6 className="mb-3">Image analysis software development.</h6>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mb-2 mt-2">Self-service BI</h5>

                            </div>
                            <h6 className="mt-3">Business intelligence and data analytics infrastructure design and implementation.</h6>
                            <h6>Ad hoc and scheduled analytics querying and reporting.</h6>
                            <h6 className="mb-3">Natural language user interface.</h6>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mb-2 mt-2">Data visualization</h5>

                            </div>
                            <h6 className="mt-3">Interactive dashboarding.</h6>
                            <h6>Custom and pre-built visuals.</h6>
                            <h6 className="mb-3">Multiple visualization techniques (symbol maps, line charts, bar charts, pie charts, etc.)</h6>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default DataAnalysis;