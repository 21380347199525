// import { Stop } from "@mui/icons-material";
import React from "react";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const DevOps = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/devops" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,DevOps " />
                    <title> DevOps | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4 m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>DevOps As A Service</b></h1>
                            <p>The cloud has become an increasingly popular choice as organizations take advantage of flexibility, availability, pricing, infrastructure & software management, etc. DevOps has followed suit as more organizations move their IT environments to the cloud. Migrating DevOps to the cloud enables developers, testers, and operations people to collaborate, develop and deploy applications faster and more effectively. The natural progression in this evolution is DevOps as a Service.</p>
                            <p>Dev stands for Software Development, and Ops stands for information technology operations. Adopting DevOps aims to reduce the system's development life cycle and provide perpetual delivery with high-quality software. Moreover, it helps to fix business objectives and keep the features and updates frequently in close alignment.It helps build cross-functional operations and supports various tools. It evolves and enhances products at a quickening speed.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/devops1.png")} alt="devops1" className="img-fluid w-75 mt-5" style={{ marginLeft: "3rem" }} />
                        </div>
                    </div>
                </div>




                <div className="company-box2  bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3"> Our Expertise</h2>
                    <div className="row g-4">
                        <div className="col-md-3 mb-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">On-Going Optimization</h5>

                            </div>
                            <p className="p-3">Our approach of continuous improvement (using proven CI/CD processes) minimizes time and cost of development and maximizes your ROI from your investments.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">360 Degree Services</h5>

                            </div>
                            <p className="p-3">From continuous monitoring to automated configuration to infrastructure security, iCloudsoft's expertise across the DevOps toolchain has you covered for any eventuality.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Automated Deployment</h5>
                            </div>
                            <p className="p-3">Engaging with iCloudsoft brings benefits like fast testing and deployment. You will no longer need to schedule releases, we use DevOps tools to automate tests and releases, making your feedback cycle faster and more efficiency.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Dedicated Experts</h5>
                            </div>
                            <p className="p-3">Our DaaS gives you access to experienced DevOps engineers whose expertise ensures your project is on track from start to finish. Access to our team of professionals can also help bring your IT department up to speed on the latest tools and systems.</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default DevOps;