import React from "react";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import "./Common.css";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";

const Home = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoftware.co.in" />
                    <meta name="keywords" content="iCloudsoft Technology is a Best Web Development Company in Pune. We offer Web Design-Development, Software Development, Digital Marketing Services." />
                    <title> Web Development Company In Pune | iCloudsoft Technology </title>
                </Helmet>
            </HelmetProvider>

            <div>
                <Header />
            </div>

            <div className="text-white" style={{ backgroundColor: "#0299C7" }}>
                <div class="text-center  p-3">
                    iCloudsoft is now part of Cloud Software Group, the mission-critical enterprise software company.
                    <NavLink to="https://icloudsoft.in/" target="_blank" aria-label="Learn more - Opens link in a new window" className="text-white" >Learn more</NavLink>
                </div>
            </div>

            <div>
                <div id="carouselExampleAutoplaying" class="carousel slide " data-bs-ride="carousel">
                    <div class="carousel-inner bg-light pb-4">
                        <div class="carousel-item active">
                            <section>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <img className="w-100" src={require("../images/Software-1.png")} alt="Software-1" title="Software-1" />
                                        </div>
                                        <div className="col-lg-6 ps-4 mt-4">
                                            <h2 className=" home-web mb-3  fs-1 mt-lg-5 pt-lg-4"><b>Trustworthy Software<br /> Development</b></h2>
                                            <p>Improving companies presence and helping to give their products or services around the world to get the real business out.</p>

                                            <NavLink exact to="/Software-Development" className="text-decoration-none" > <button type="button" class="btn btn-danger w-50  " > Make your project </button></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="carousel-item ">
                            <section>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <img className="w-100" src={require("../images/home-s2.png")} alt="home-s2" title="home-s2" />
                                        </div>
                                        <div className="col-lg-6  ps-4 mt-4">
                                            <h2 className=" home-web mb-3 fs-1 mt-lg-5 pt-lg-4"><b>Web <br /> Development</b></h2>
                                            <p className="mt-4">A Technology Partner Delivering Fruitful Solutions For The Software Development And Digital Transformation.</p>

                                            <NavLink exact to="/Web-Development" className="text-decoration-none" > <button type="button" class="btn btn-danger w-50  " > Make your project </button></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

            <div className="home-box bg-light text-center d-none d-sm-block">
                <div className="home-box1 ">
                    <div className="hb bg-light text-center w-25 p-2">
                        <h5>24/7</h5>
                        <p>IT SUPPORT <br />AVAILABILITY</p>
                    </div>
                    <div className="hb bg-light text-center w-25 p-2">
                        <h5>10+</h5>
                        <p>YEARS OF DEVELOPMENT <br />EXPERIENCE</p>
                    </div>
                    <div className="hb bg-light text-center w-25 p-2">
                        <h5>700+</h5>
                        <p>HAPPY <br />CLIENTS</p>
                    </div>
                    <div className="hb bg-light text-center w-25 p-1">
                        <h5>16+</h5>
                        <p>Completed <br />Project</p>
                    </div>
                </div>
            </div>

            <div className="container  text-center d-sm-none d-block">
                <div className="row g-4 bg-light pt-5 pb-4">
                    <div className="col-6">
                        <h5>26/7</h5>
                        <p>IT SUPPORT <br />AVAILABILITY</p>
                    </div>
                    <div className="col-6">
                        <h5>10+</h5>
                        <p>YEARS OF DEVELOPMENT <br />EXPERIENCE</p>
                    </div>
                    <div className="col-6">
                        <h5>700+</h5>
                        <p>HAPPY <br />CLIENTS</p>
                    </div>
                    <div className="col-6">
                        <h5>16+</h5>
                        <p>Completed <br />Project</p>
                    </div>
                </div>
            </div>

            <div className="">
                <div className="home-box3 mb-5 me-lg-4">
                    <div className="d-none d-sm-block mb-5 pt-3">
                        <h5 className="text-center mt-5 mb-3">OUR SERVICES & SOLUTIONS</h5>
                        <h1 className="text-center ms-2 mt-4">iCloudsoft is Pune's Most Trusted Software and <br /> Web Development Company</h1>
                    </div>

                    <div className="d-sm-none d-block p-3 text-center mt-4 mb-4">
                        <h5 className="text-center mb-3">OUR SERVICES & SOLUTIONS</h5>
                        <h1 className="text-center  " style={{ fontSize: "17px", lineHeight: "27px" }} >iCloudsoft is Pune's Most Trusted Software and Web Development Company</h1>
                    </div>

                    <div className="home-block">
                        <div className="hb2 ">
                            <img src={require("../images/Software-Development.png")} alt="Software-Development" title="Software-Development" className="mb-3 w-25" />
                            <NavLink eaxct to="/Software-Development" className="text-decoration-none" style={{ color: "black" }}><h3 className="mb-3">Software Development</h3></NavLink>
                            <p>Software Development is the practice of developing computer software. The entire process from conception to completion can be very challenging and require a lot of knowledge.</p>
                        </div>
                        <div className="hb2">
                            <img src={require("../images/web-development.png")} alt="web-development" title="web-development" className="mb-3 w-25" />
                            <NavLink eaxct to="/Web-Development" className="text-decoration-none" style={{ color: "black" }}> <h3 className="mb-3">Web Development</h3></NavLink>
                            <p> We have assisted hundreds of companies to achieve their goals, drive businesses growth, and create happy customers.</p>
                        </div>
                        <div className="hb2">
                            <img src={require("../images/E-Commerce.png")} alt="E-Commerce" title="E-Commerce" className="mb-3 p-2 w-25" />
                            <NavLink eaxct to="/E-Commerce" className="text-decoration-none" style={{ color: "black" }}><h3 className="mb-3">E-Commerce</h3></NavLink>
                            <p>We develop Ecommerce websites for your business, they can sell physical goods that are shipped to customers, or digital products that are downloaded directly.</p>
                        </div>
                        <div className="hb2">
                            <img src={require("../images/Application-Services.png")} alt="Application-Services" title="Application-Services" className="mb-3 w-25" />
                            <NavLink eaxct to="/Application-Services" className="text-decoration-none" style={{ color: "black" }}><h3 className="mb-3">Application Services</h3></NavLink>
                            <p>Services that provide secure and efficient storage and retrieval of data used by an application.</p>
                        </div>
                        <div className="hb2">
                            <img src={require("../images/UX-UI-Design.png")} alt="UX-UI-Design" title="UX-UI-Design" className="mb-3 w-25" />
                            <NavLink eaxct to="/UX/UI-Design" className="text-decoration-none" style={{ color: "black", textDecoration: "none" }}> <h3 className="mb-3">UX/UI Design</h3></NavLink>
                            <p>We bring power to our design with a human-centered design process by not just keeping the users in mind but even by involving the users in the process.</p>
                        </div>
                        <div className="hb2">
                            <img src={require("../images/Data-Analystics-1.png")} alt="Data-Analystics-1" title="Data-Analystics-1" className="mb-3 w-25" />
                            <NavLink eaxct to="/Data-Analysis" className="text-decoration-none" style={{ color: "black" }}> <h3 className="mb-3  text-decoration-none">Data Analystics</h3></NavLink>
                            <p>Data Analytics is an important part of Artificial Intelligence. It expand the business, helps them to acquire brand value by making a data-driven decision.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="company-boxA">
                <h2 className="text-center fs-1 pt-5 mb-3"><b>SOLUTIONS </b></h2>
                <div className="row g-4 d-flex justify-content-around p-lg-4  m-2 ">
                    <div className="col-md-6 ps-lg-5 mt-2">
                        <h5 className="mb-3 mt-lg-5" style={{ lineHeight: "27px" }}><b>Boost your online sales and provide your customers with a seamless customer experience </b></h5>
                        <p className="mb-lg-4 mt-lg-3">Morever, your business needs to Center on significant / uppermore things than manully taking orders and doing the boring tasks can be automated how.</p>
                        <p className="mb-4 mt-lg-3">As a leading software development company, iCloudsoft assists clients of all sizes and industries in implementing technology solutions with our one-stop solution, CRM, Marketing and Advertising, Human Resources, E-Learning , and  iCloud solutions with diverse service models.</p>
                        <p className="mb-4 mt-lg-3">iCloudsoft Technology provides high-quality software and web development services to clients. For over 10 years, we have perfected the art of software testing, as well as quality assurance services.</p>

                        <NavLink exact to="/CRM" className="text-decoration-none" > <button type="button" class="btn btn-danger LearnMore  " >Learn More   </button></NavLink>
                    </div>
                    <div className="col-md-6">
                        <img src={require('../images/solution.png')} alt="solution" title="solution" className="img-fluid " />
                    </div>
                </div>
            </div>

            <div className="Home-boxA bg-light">
                <h2 className="text-center fs-1 pt-5"><b>TECHNOLOGY</b></h2>
                <div className="row g-4 p-lg-4  m-2">
                    <div className="col-md-6">
                        <img className="w-100" src={require('../images/technology.png')} alt="technology" title="technology ,technology."></img>
                    </div>
                    <div className="col-md-6 ps-lg-5 mt-5">
                        <h5 className="mb-3" style={{ lineHeight: "27px" }}><b> Build your own custom website and appliction with our in-house team of experts</b></h5>
                        <p className="mb-4 mt-3">We have enabled business to scale a with attractive speedily and functional apps that result in improved connectivity and integrated user experience.</p>
                        <p className="mb-4 mt-3">Our masters bring you wide-ranging expertise in languages and platforms for the top Software and Web  Development solutions that work wide and deep in all sector needed.
                            Our diverse knowledge & skills includes up-to-date technology, platforms, and languages to ensure each qualified software and web development project is built.</p>

                        <NavLink exact to="/Java" className="text-decoration-none" > <button type="button" class="btn btn-danger LearnMore  " >Learn More   </button></NavLink>
                    </div>
                </div>
            </div>

            <div class="home-globe">
                <div class="home-globe1 mb-5">
                    <h2 className="fs-1 ms-lg-2 ps-3">What Makes Us Special</h2>
                    <p className="mt-3">iCloudsoft Technology is an independent company dedicated to delivering<br /> the industry’s most effective Software and Web development,<br /> ensuring high client satisfaction, delivering results and investing profits in client<br /> and employee success. We lead with creativity and think<br /> strategically, we build an execution process and give it our best work. <br />Investing in software or technology that can automate tasks.</p>
                </div>
            </div>

            <Footer />

        </>
    )
}
export default Home;