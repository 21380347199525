import { Star } from "@mui/icons-material";
import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.css";

const Footer = () => {

  return (
    <>

      <footer>
        <div className="footer-bg">
          <div class="container-fluid ">
            <div class="row g-4 row-cols-1 row-cols-lg-5 row-cols-md-3 pt-4 pb-4">
              <div class="col ">
                <div class="p-lg-2 logo">
                  <p><img src={require("../images/iCloud-logo.png")} alt="iCloud-logo" title="iCloud-logo" className="" /></p>
                </div>
              </div>

              <div class="col">
                <div class="pt-lg-3">
                  <i className="fa-solid ms-5 ps-lg-5  fa-location-dot fs-2 mb-2"></i>
                  <p> Office No 601, 6th Floor, Lotus Building, Opp Joshi Railway Museum, Kothrud, Pune 411038</p>
                </div>
              </div>

              <div class="col">
                <div class="pt-lg-3">
                  <h4 className='fs-4 ms-5 h1FontFamily'>Call Us</h4>
                  <NavLink className='text-decoration-none text-white SolutionDropDownFontFamily' to='tel:9527468898, 8766779267'> <h6 className='mt-2 fs-5 ps-lg-4 pe-lg-4'>+91 9527468898 <br></br> +91 8766779267 </h6></NavLink>
                </div>
              </div>

              <div class="col">
                <div class="pt-lg-3">
                  <i className="fa-solid ms-lg-5  fa-envelope fs-2 mb-2"></i>
                  <h6><NavLink to="mailto:info@icloudsoft.co.in" style={{ color: "white" }}>info@icloudsoft.co.in</NavLink></h6>
                </div>
              </div>

              <div class="col">
                <div class="pt-lg-3">
                  <NavLink to="http://wa.me/9527468898" aria-label="whatsapp" rel="noopener" target="_blank" ><i class="fa-brands fa-whatsapp fs-2 text-white p-2"></i></NavLink>
                  <NavLink to="https://www.facebook.com/icloudsofttechnology" aria-label="facebook" rel="noopener" target="_blank" > <i className="fa-brands fa-facebook fs-2 text-white p-2"></i></NavLink>
                  <NavLink href="https://www.linkedin.com/company/icloudsoft/" target="_blank" aria-label="linkedin" rel="noopener" ><i className="fa-brands fa-linkedin fs-2 text-white p-2"></i></NavLink>
                  <br />
                  <p>
                    <Star className="mt-3" /> <Star className="mt-3" /><Star className="mt-3" /><Star className="mt-3" /><Star className="mt-3" /><br />
                    17 Reviews
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="hr bg-danger  mb-3" ></div>

          <div className="footer-box d-flex">
            <NavLink exact to="/" style={{ textDecoration: "none", color: "white" }}><h6 className="m-2">Home</h6></NavLink>
            <NavLink exact to="/solutions/crm" style={{ textDecoration: "none", color: "white" }}><h6 className="m-2">Solutions</h6></NavLink>
            <NavLink exact to="/java" style={{ textDecoration: "none", color: "white" }}><h6 className="m-2">Technologies</h6></NavLink>
            <NavLink exact to="/software-development" style={{ textDecoration: "none", color: "white" }}><h6 className="m-2">Services</h6></NavLink>
            <NavLink exact to="/Company" style={{ textDecoration: "none", color: "white" }}><h6 className="m-2">Company</h6></NavLink>
          </div>

          <div className="footer-box mt-2">
            <p className="m-2">Copyright © iCloudsoft Technology 2023. All Rights Reserved.</p>
          </div>

        </div>
      </footer>

    </>
  )
}
export default Footer;