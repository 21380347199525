import React from "react";
import {  NavLink } from "react-router-dom";
// import { NavigateNext } from "@mui/icons-material";

import Header from "./Header";

const EnterpriseMobility =()=>{

    return(
        <>
        <Header/> 
        <div className="info">
            <div class="text-left ms-5 p-2">
            <NavLink exact to="/" style={{textDecoration:"none" ,color:"grey"}}>Home</NavLink>   Enterprise Mobility 
            </div>
           </div>
           <div className="container-fluid">
            <div className="row dot1">
                <div className=" col dot-box bg">
                    <h1 className="mt-5">Mobile App Development </h1>
                    <p>Our mobile app development services are capable to make startups experience the unicorn club perks and turn enterprise companies from conventional to extraordinary. Whether you need to build an app from start, or need a full-proof mission for a complex product, or need extra app development, muscle for your company, our app developers are excellent and they deliver the results you look for.</p>
                </div>
                <div className="col dot-box bg">
                    <img src={require("../images/UXUI.png")} alt="UXUI"  className="pe-5"/>
                </div>
            </div>
            <div className="ux-box bg-light p-5">
                <h2 className="mb-4">We're Driven To Make Your Big Ideas Work On Small Screen. That,s How We Are Built</h2>
                <p className="mb-4">Magnify the users reach with a modern app as that will put your brand into the hands of your users irrelevant of the device they work on. iCloudsoft works on design and development of mobile apps that validates everything from online presence to ROI. We blend modern trends, our technical expertise and usability of users to deliver an app that rules with QUALITY. We don’t invest our hours to build an app that just works, but we work to make your app a SUCCESS.</p>
                <p className="mb-4">We are a mobile app development company that works with you in each step, from ideation to launch. We ensure our mobile app design reaches to the heart of users and fulfills today’s need. Whether it is a native or cross-platform app our mobile app developers design an app that differentiates and stays worthy of your brand.</p>
            </div>
            <div className="row ux1">
            <div className="col-md-5 dot-box bg">
                    <img src={require("../images/Android-App.png")} alt="Android-App" className="p-5 w-75"/>
                </div>
                <div className="col-md-6 dot-box bg">
                    <h1>Android App Development Services </h1>
                    <p>With thousands of Android apps in the market, you need a professional Android app development company to launch your Android app in the form of MVP or full fledged product. By adding the element of WOW, we develop apps that people love to see again and again.We take you beyond the bounds of excellence and our Android app development services accept nothing less.</p>
                </div>
                
            </div>
            <div className="ux-box1 bg-light p-5">
                <h6>Our iot Excellence</h6>
                <h2>Trusted IoT App Development Company to Build your Mobile App</h2>
                <div className="row">
                    <div className="col-md-6 mt-5 ">
                        <h6 >We Provide</h6>
                        <h5>IoT App Development Services</h5>
                    </div>
                    <div className="col-md-6 mt-5">
                        <h5>We offer future-ready, sophisticated and business focused IoT development services powered by smooth user experience and engaging features.</h5>
                    </div>
                </div>
            </div>
            <div className="row ux1">
                <div className=" col dot-box bg">
                    <h1>iPad App Development Services </h1>
                    <p>As a leading iOS app development company, we get into the nitty-gritty of different approaches from design to usability, performance to content to deliver for custom iPad applications.Our consultations are and we are always ready to hear about your project.</p>
                </div>
                <div className="col dot-box bg">
                    <img src={require("../images/iPad-App.png")} alt="" className="p-5 w-75" />
                </div>
            </div>
            <div className="ux-box1 bg-light p-5">
                <h6>Our iPad Excellence</h6>
                <h2>We Set Beautiful Standards for Mature iPad Applications</h2>
                <div className="row">
                    <div className="col-md-6 mt-5 ">
                        <h6 >We Provide</h6>
                        <h5>iPad App Development Services</h5>
                    </div>
                    <div className="col-md-6 mt-5">
                        <h5><b>Next-Gen</b> iPad Development With</h5>
                        <h5><b>Professional Excellence</b></h5>
                    </div>
                </div>
            </div>
            <div className="row ux1">
            <div className="col-md-6 dot-box bg">
                    <img src={require("../images/Iphone-App.png")} alt="" className="p-5 w-75" />
                </div>
                <div className=" col-md-6 dot-box bg">
                    <h1>iPhone App Development Services </h1>
                    <p>iCloudsoft stands high as an iPhone App Development Company taking bolds steps from designing pixel perfect designs to intuitive backends, to fill each dimension of the modern iOS app development realm.For the quality that Apple devices have, our iPhone App Development Services are bound to set the bar high with a product that is bold, intuitive, timely and innovative.</p>
                </div>
                
            </div>
            <div className="ux-box1 bg-light p-5">
                <h6>Our iPhone Excellence</h6>
                <h2>A Potent iPhone App Development Company Delivering Polished App</h2>
                <div className="row">
                    <div className="col-md-6 mt-5 ">
                        <h6 >We Provide</h6>
                        <h5>iPhone App Development Services</h5>
                    </div>
                    <div className="col-md-6 mt-5">
                        <h5><b>Next-Gen</b> iPhone App Development With</h5>
                        <h5><b>Professional Excellence</b></h5>
                    </div>
                </div>
            </div>
            <div className="row ux1">
                <div className=" col-md-6 dot-box bg">
                    <h1>OTT Platform Development Services </h1>
                    <p>iCloudsoft is a leading OTT app development company to deliver standout streaming video and media content experience across multiple device platforms.</p>
                </div>
                <div className="col-md-6 dot-box bg">
                    <img src={require("../images/OTT.png")} alt="" className="p-5 w-75" />
                </div>
            </div>
            <div className="ux-box1 bg-light p-5">
                <h6>Our OTT Platform Development Excellence</h6>
                <h2>Top OTT App Development Company</h2>
                <div className="row">
                    <div className="col-md-6 mt-5 ">
                        <h6 >We Provide</h6>
                        <h5>OTT Platform Development Services</h5>
                    </div>
                    <div className="col-md-6 mt-5">
                        <h5>We provide future ready OTT platform development by leveraging our experience and experience with live streaming solutions.</h5>
                    </div>
                </div>
            </div>
         </div>      
        
        </>
    )
}
export default EnterpriseMobility;
