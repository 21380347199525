import React from "react";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const WebDesign = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/ux-ui-design" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Java " />
                    <title> Java | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4 m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>UI/UX Design Services</b></h1>
                            <p>Java development services cover implementation of enterprise apps, software products, and SaaS solutions using secure,
                                portable and scalable Java frameworks. iCloudsoft helps clients build sustainable Java-based software fast and cost-effectively</p>
                            <h6>Goals Blended With Colors – We Propel Brands To A Height With A New Look</h6>
                            <p>We no longer live in the age of same ho-hum websites that persistently use the same primary color colors, exactly two fonts and retina-burning hyper-links.</p>
                            <p>In today’s era, we understand what “PRETTY” websites are and how they unlock the pretty large doors of businesses and users. So, we occupied the center of old history and impeded the heinous UI/UX to craft powerful applications with each pixel speaking the goal and simplicity of the company, leading to world’s most innovative companies.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/ui-ux.png")} alt="ui-ux" className="img-fluid w-75" style={{ marginLeft: "4rem" }} />
                        </div>
                    </div>
                </div>

                <div className="company-box2 bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Area Of Expertise</h2>
                    <div className="row g-4">
                        <div className="col-md-6 mb-3">
                            <div className="co p-2 d-flex" >
                                <h4 className="ms-3 mt-2 mb-2">Design Prototyping Services</h4>
                            </div>
                            <p className="p-3">For any idea the inception of development is always rooted in risk and uncertainty. We are armed with strong prototyping principles and processes that will push you with confidence for your next big leap. Let’s kick-start with prototyping to boost your returns with strategies proven to take off from the beginning.Software development is wheels within wheels process that needs better understanding, communication and continuous experiments. It can turn out into a costly, time-killing and aggravating endeavor if you develop five wrong versions of a website to understand that the sixth version is ideal. We know how annoying this pain can be. So, with our prototyping design services we help you validate how your web platform or mobile application will turn into an asset for users and how your software will be a delight for users.</p>
                        </div>
                        <div className="col-md-6">
                            <div className="co p-2 d-flex" >
                                <h4 className="ms-3 mt-2 mb-2">Mobile App Design Services</h4>
                            </div>
                            <p className="p-3">Number 1 reason for the success of any mobile app is its ability to keep users engaged. This job of mobile app designer. Our team of creative mobile app designers guarantees a mobile app design that inspires and brings great engagement Mobile app customers don’t just simply “point-click-buy” with your apps. As a business you need to inspire them with your brand values and create experience they start loving. We work on a design process that is creative, user-centric, approved and methodical to meet the mobile app design goals. Our design compliments the user mindsets and strengthens the connection with the brand.</p>
                        </div>
                        <div className="col-md-6">
                            <div className="co p-2 d-flex" >
                                <h4 className="ms-3 mt-2 mb-2">PSD to HTML Conversion Service</h4>
                            </div>
                            <p className="p-3">Probably you have found a UI that compliments your user’s instincts and reinforces a potential connection with brand value. We have the best team of frontend development team who implement their best PSD to HTML practices to transform this ideal UI into cross-browser compatible W3C, HTML5/CSS3 standard file.Without an appealing website that people love to view, what you have is just a page floating in the seas of web. With a professional website having quality HTML code, your brand gains a value. Being a leading PSD to HTML Company, we transform your visualized ideas, vision and stories into lines of code. With our lean approach, we work to completely understand your goals and obstacles and say goodbye to wasteful design practices that keep you away from your design goals. PSD to HTML web development is W3C validated with complete quality check.</p>
                        </div>
                        <div className="col-md-6">
                            <div className="co p-2 d-flex" >
                                <h4 className="ms-3 mt-2 mb-2">Responsive Website Design Services</h4>
                            </div>
                            <p className="p-3">People who grew up as digital natives with access to the web and smartphones from their childhood must be considered while designing and developing any website or mobile app. Understanding this, we need to focus on Responsive web design. Our design experts helpWe craft our design with users in mind and with the vision that users love. With our responsive web design services we let you skip the expense on unwanted design services for each platform and focus on attracting users on every platform with one design. Our design skills allow users to experience the most advanced design we have envisioned and earn high ROI with it.</p>
                        </div>


                    </div>
                </div>

            </div>
        </>
    )
}
export default WebDesign;