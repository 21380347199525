import { Stop } from "@mui/icons-material";
import React from "react";
// import { NavLink } from "react-router-dom";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Manufacturing = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/manufacturing" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Manufacturing" />
                    <title> Manufacturing | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4 m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1><b> Manufacturing</b></h1>
                            <p>iCloudsoft offers robust solutions and related services to support and modernize your enterprise’s operations and ensure increased efficiency of operations and optimized business costs, all without IT budget overruns.</p>
                            <p>iCloudsoft offers robust solutions and related services to support and modernize your enterprise’s operations and ensure increased efficiency of operations and optimized business costs, all without IT budget overruns.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/website-1.png")} alt="website-1" className="img-fluid w-50" style={{ marginLeft: "4rem" }} />
                        </div>
                    </div>
                </div>


                <div className="company-box2   bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3"> Manufacturing</h2>
                    <div className="row g-4">
                        <div className="col-md-4 mb-3">
                            <div className="co p-2 d-flex ">
                                <h5 className="ms-3 mt-2 mb-2  ">Multi-tier incident resolution pipeline:</h5>
                            </div>
                            <h6 className="mt-3"><Stop className="ar" /> L1. A user support team.</h6>
                            <h6><Stop className="ar" /> L2. A technical support team.</h6>
                            <h6 className="pb-2"><Stop className="ar" /> L3. A team of software engineers.</h6>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex ">
                                <h5 className="ms-3 mt-2 mb-2  ">IT infrastructure and operating</h5>
                            </div>
                            <h6 className="mt-3"><Stop className="ar" /> Network maps.</h6>
                            <h6><Stop className="ar" /> Configuration management database.</h6>
                            <h6><Stop className="ar" /> Infrastructure improvement plan.</h6>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex ">
                                <h5 className="ms-3 mt-2 mb-2  ">Self-service training materials for users</h5>
                            </div>
                            <h6 className="mt-3"><Stop className="ar" /> Knowledge base articles.</h6>
                            <h6><Stop className="ar" /> FAQs.</h6>
                            <h6 className="pb-2"><Stop className="ar" /> User manuals.</h6>                        </div>
                        <div className="col-md-4">
                            <div className="co  p-2 d-flex ">
                                <h5 className="ms-3 mt-2 mb-2 " >User satisfaction and adoption improvements:</h5>
                            </div>
                            <h6 className="mt-3"><Stop className="ar" /> UX testing/monitoring.</h6>
                            <h6><Stop className="ar" />  and user experience studies with CSAT improvement plans based on their results.</h6>                        </div>
                        <div className="col-md-4">
                            <div className="co  p-2 d-flex ">
                                <h5 className="ms-3 mt-2 mb-2 " >Regulatory compliance assessments</h5>
                            </div>
                            <h6 className="mt-3"><Stop className="ar" /> IT environment benchmarking against PCI DSS, HIPAA, etc.</h6>
                            <h6 className="pb-3"><Stop className="ar" /> Compliance gap mitigation plans.</h6>                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex ">
                                <h5 className="ms-3 mt-2 mb-2  ">Regular and transparent reporting:</h5>
                            </div>
                            <h6 className="mt-3"><Stop className="ar" /> Service level reports.</h6>
                            <h6><Stop className="ar" /> Maintenance reports.</h6>
                            <h6><Stop className="ar" /> Health check reports.</h6>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Manufacturing;