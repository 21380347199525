import React from "react";
import Header from "./Header";
import "./Big-data.css";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import { Stop ,NavigateNext } from "@mui/icons-material";
// import { NavLink } from "react-router-dom";

const VR = () => {

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoft.co.in/virtual-reality" />
          <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Virtual-Reality" />
          <title> Virtual-Reality | Web Development Company In Pune  </title>
        </Helmet>
      </HelmetProvider>

      <Header />

      <div className="Company bg-light">
        <div className="company-boxA">
          <div className="row p-lg-4 g-4 m-2 ">
            <div className="col-md-6 ps-lg-5 mt-5">
              <h1 className="mb-3"><b>Virtual Reality </b></h1>
              <p>Virtual Reality (VR) development services focus on the implementation of software that allows users interact with simulated environments. iCloudsoft delivers competitive VR apps with high and fast user adoption.</p>
              <p>Virtual Reality (VR) is a computer-generated environment with scenes and objects that appear to be real, making the user feel they are immersed in their surroundings. This environment is perceived through a device known as a Virtual Reality headset or helmet.</p>
            </div>
            <div className="col-md-6">
              <img src={require("../images/VR.png")} alt="VR" className="img-fluid w-50 " style={{ marginLeft: "3rem" }} />
            </div>
          </div>
        </div>

        <div className="company-box2 bg-secondary-subtle m-3">
          <h2 className="text-center p-3 mb-3" style={{lineHeight:"35px"}}>Our Virtual Reality Development Services</h2>
          <div className="row g-2">
            <div className="col-md-3 mb-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">Architecture design</h5>
              </div>
              <p className="p-3">We plan out an individual software architecture to ensure that your VR application will have:High performance​. Easy configurability.Fast scalability.</p>
            </div>
            <div className="col-md-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">3D modeling</h5>
              </div>
              <p className="p-3">When creating VR content, we apply such immersion-improving elements as:Highly realistic visuals. Kinesthetic attributes.Engaging animations.</p>
            </div>
            <div className="col-md-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">VR software integration</h5>
              </div>
              <p className="p-3">We integrate VR software not only with VR hardware but also with any software applications including:CMS,LMS,EHR</p>
            </div>
            <div className="col-md-3">
              <div className="co  p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">Ouality assurance</h5>
              </div>
              <p className="p-3">To guarantee the quality of your VR application, our testing engineers:Run unit tests. Provide detailed test documentation.Combine manual testing with automated tests.</p>
            </div>


          </div>
        </div>
      </div>


    </>
  )
}
export default VR;