import React from "react";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const Salesforce = () => {

   return (
      <>

         <HelmetProvider>
            <Helmet>
               <meta charset="utf-8" />
               <link rel="canonical" href="https://www.icloudsoft.co.in/salesforce" />
               <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,SalesForce " />
               <title> SalesForce | Web Development Company In Pune  </title>
            </Helmet>
         </HelmetProvider>

         <Header />

         <div className="Company bg-light">
            <div className="company-boxA">
               <div className="row p-lg-4 g-4 m-2 ">
                  <div className="col-md-6 ps-lg-5 mt-5">
                     <h1 className="mb-3"><b>Salesforce</b></h1>
                     <p>Salesforce professional services cover Salesforce consulting, implementation, support and other services aimed to maximize Salesforce potential for business.iCloudsoft provides a full set of Salesforce professional services to help companies improve sales, marketing and customer service processes.</p>
                     <p>Salesforce is a cloud-based customer relationship management (CRM) platform that is used by businesses to manage their interactions with customers and prospects. It provides a range of tools and features to help businesses streamline their sales, marketing, and customer service processes.</p>
                  </div>
                  <div className="col-md-6">
                     <img src={require("../images/salesforce1.png")} alt="salesforce1" className="img-fluid w-75 " style={{ marginLeft: "3rem" }} />
                  </div>
               </div>
            </div>

            <div className="company-box2  bg-secondary-subtle m-3">
               <h2 className="text-center p-3 mb-3">Our Salesforce Service</h2>
               <div className="row g-2">
                  <div className="col-md-4 mb-4">
                     <div className="co p-2 d-flex">
                        <h5 className="ms-3 mt-3 mb-3">Salesforce consulting</h5>
                     </div>
                     <p className="p-3">Rely on our skilled consultants to solve your specific CRM challenges – from low sales productivity and deficient alignment between departments to CRM performance issues. Our experts will train your employees to fulfill the potential of Salesforce.</p>
                  </div>
                  <div className="col-md-4">
                     <div className="co p-2 d-flex">
                        <h5 className="ms-3 mt-3 mb-3">Salesforce implementation</h5>
                     </div>
                     <p className="p-3">Our team is ready to perform Salesforce implementation for you to get a reliable solution. From implementation consulting to after-launch support, we work on the solution that will efficiently address your business needs.</p>
                  </div>
                  <div className="col-md-4">
                     <div className="co p-2 d-flex">
                        <h5 className="ms-3 mt-3 mb-3">Salesforce customization</h5>
                     </div>
                     <p className="p-3">Get a solution designed to fit the requirements of your industry and business. Unlock all capabilities of the Salesforce platform with fine-tuned modules for sales and marketing automation, lead and opportunity management, performance management, reporting and more.</p>
                  </div>
                  <div className="col-md-4">
                     <div className="co p-2 d-flex">
                        <h5 className="ms-3 mt-3 mb-3">Salesforce application development</h5>
                     </div>
                     <p className="p-3">Get a custom Salesforce application to go far beyond default functionality in improving your sales, marketing and customer service processes, or an AppExchange app to generate revenue from it, or an app to integrate your software product with Salesforce.</p>
                  </div>
                  <div className="col-md-4">
                     <div className="co p-2 d-flex">
                        <h5 className="ms-3 mt-3 mb-3">Salesforce support and maintenance</h5>
                     </div>
                     <p className="p-3">Ensure long-term stability of your Salesforce. Just choose a convenient package to anticipate and promptly resolve performance issues, minimize system downtimes, and improve user adoption.</p>
                  </div>
                  <div className="col-md-4">
                     <div className="co  p-2 d-flex">
                        <h5 className="ms-3 mt-3 mb-3">Salesforce upgrade</h5>
                     </div>
                     <p className="p-3">As long as your business grows, extend the platform’s functionality, get custom add-ons and incorporate technical advances into your Salesforce to stay ahead of the competition.</p>
                  </div>


               </div>
            </div>
         </div>

      </>
   )
}
export default Salesforce;