import React from "react";
import Header from "./Header";
import "./Mobile.css";
import { Helmet, HelmetProvider } from 'react-helmet-async';



const Mobile = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/mobile" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Mobile" />
                    <title> Mobile | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4 m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>Mobile App Development Services</b></h1>
                            <p>iCloudsoft is the company behind Rakuten Viber (1.17B users) for Android, Approved banking apps, and other mobile solutions that power large corporations.Developing a new mobile app for Android or iOS? Anticipate the challenges of mobile application development and tap into the cloud to enhance the user experience.</p>
                            <p>Mobile application development is the process to making software for smartphones and digital assistants, most commonly for Android and iOS. The software can be preinstalled on the device, downloaded from a mobile app store or accessed through a mobile web browser. The programming and markup languages used for this kind of software development include Java, C# and HTML5.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/phone1.png")} alt="phone1" className="img-fluid w-75 " style={{ marginLeft: "3rem" }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="company-box2 bg-secondary-subtle m-3">
                <h2 className="text-center p-3 mb-3">Area Of Expertise</h2>
                <div className="row g-2">
                    <div className="col-md-3 mb-3">
                        <div className="co p-2 d-flex">
                            <h5 className="ms-3 mt-2 mb-2">Mobile App Development</h5>
                        </div>
                        <p className="p-3">Having completed 350+ projects, our developers create and deploy fast, stable & high-performing mobile apps.</p>
                    </div>
                    <div className="col-md-3">
                        <div className="co p-2 d-flex">
                            <h5 className="ms-3 mt-2 mb-2">Back End Development</h5>
                        </div>
                        <p className="p-3">Our back-end developers build robust and secure mobile back ends to ensure smooth and quick data sync and transfer.</p>
                    </div>
                    <div className="col-md-3">
                        <div className="co p-2 d-flex">
                            <h5 className="ms-3 mt-2 mb-2">Mobile UX/UI Design</h5>
                        </div>
                        <p className="p-3">iCloudsoft designers create slick and intuitive interfaces that ensure conversion, engagement, and easy adoption.</p>
                    </div>
                    <div className="col-md-3">
                        <div className="co p-2 d-flex">
                            <h5 className="ms-3 mt-2 mb-2">Mobile App QA & Testing</h5>
                        </div>
                        <p className="p-3">Our ISTQB-certified test engineers perform functional, performance, security, UX, and accessibility testing.</p>
                    </div>

                </div>
            </div>




        </>
    )
}
export default Mobile;