import React from "react";
import Header from "./Header";
import "./Common.css";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import { Stop, NavigateNext } from "@mui/icons-material";
// import { NavLink } from "react-router-dom";

const MarketingAdvertising = () => {

    return (
        <>


            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/marketing-advertising" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Marketing-Advertising" />
                    <title> Marketing-Advertising | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row g-4 p-lg-4  m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b> Marketing & Advertising</b></h1>
                            <p>Custom marketing software development is the service for all companies aiming to enhance their marketing initiatives. We offer in marketing software development to help enterprises improve efficiency of their marketing plans and enable them with proper software.</p>
                            <p>Marketing refers to the broader process of promoting and selling products or services to customers. It involves a range of activities, such as market research, product development, branding, and customer engagement. The goal of marketing is to understand customer needs and preferences, and to develop and promote products or services that meet those needs.</p>
                        </div>
                        <div className="col-md-6">

                            <img src={require("../images/advertising-transparent.png")} alt="" className="img-fluid w-50 " style={{ marginLeft: "3rem" }} />
                        </div>
                    </div>
                </div>
                
                <div className="company-box2 bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Software That Drives Markets</h2>
                    <div className="row g-4">
                        <div className="col-md-4 mb-3">
                            <div className="co  p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Trade promotion management software</h5>
                            </div>
                            <p className="p-3">Trade promotion management (TMP) applications enable you to achieve growth for your brand by:Controlling how the commitments to retailers are executed Planning promotion campaigns and forecasting sales volume</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Advertising planning</h5>
                            </div>
                            <p className="p-3">Analyze and plan ad campaigns across multiple media channels such as TV, Internet, radio, printed press and out-of-home commercials. Combine all the media types in one plan to find the optimal advertising strategy tailored to your needs. Enjoy comprehensive visualized reports induced from sophisticated mathematical models on a single platform.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co  p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Social marketing solutions</h5>
                            </div>
                            <p className="p-3">We develop marketing software to help you turn social networks into a customer acquisition tool:Social marketing applications .Campaign applications integrated with social networks.Social analytics to track user activity and prepare analytical reports</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co  p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Loyalty software</h5>
                            </div>
                            <p className="p-3">Ensure more loyal relationships with your clients by tracking and analyzing what makes them satisfied:Mobile loyalty applications: personalize promotions and provide relevant product information, both in store and at home Analytics and reporting: analyze promotions impact and loyalty program ROI, track trends in customer behavior and forecast sales Customer account management: boost prospect interest through personalized offers and track the purchase history, promotion response and more</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co  p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Marketing data analysis</h5>
                            </div>
                            <p className="p-3">Make smarter marketing decisions thanks to:Marketing campaign analysis: tracking key campaign metrics and monitoring overall performance.Consumer survey analysis: collecting and processing survey data to uncover the real drivers of consumer behavior.Sales analysis: processing and unifying data to estimate growth potential as well as optimize sales and marketing activities</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co  p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Real-time bidding in online advertisement</h5>
                            </div>
                            <p className="p-3">Leverage cost-efficient and optimized ad campaigns with the help of RTB systems or its key components:Analytics.Supply-side platforms (SSPs).Demand-side platforms (DSPs).Data management platforms (DMPs)</p>
                        </div>


                    </div>
                </div>
            </div>



        </>
    )
}
export default MarketingAdvertising;