import React from "react";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Cplus = () => {

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoft.co.in/c++" />
          <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,C++" />
          <title> C++ | Web Development Company In Pune  </title>
        </Helmet>
      </HelmetProvider>

      <Header />

      <div className="Company bg-light">
        <div className="company-boxA">
          <div className="row p-lg-4 g-4 m-2 ">
            <div className="col-md-6 ps-lg-5 mt-5">
              <h1 className="mb-3"><b>C++</b></h1>
              <p>C++ is used in developing browsers, operating systems, and applications, as well as in-game programming, software engineering, data structures, etc.The C++ language has two main components: a direct mapping of hardware features provided primarily by the C subset, and zero-overhead abstractions based on those mappings. </p>
              <p>C++ development services cover implementation of scalable and portable resource-intensive software.
                iCloudsoft creates sustainable libraries, native and cross-platform apps, incl. image analysis, virtual reality,
                IoT and 3D modeling solutions, fast and cost-effectively.</p>
            </div>
            <div className="col-md-6">
              <img src={require("../images/c++.png")} alt="c++" className="img-fluid w-50 mt-3 " style={{ marginLeft: "3rem" }} />
            </div>
          </div>
        </div>



        <div className="container p-4 bg-secondary-subtle text-center d-none d-sm-block">
          <h2 className="mb-4">What Our Java Development Services Cover</h2>
          <div className=" testing ">
            <div className="">
              <img src={require("../images/Image-analysis.png")} alt="Image-analysis" className="w-25 mb-3" />
              <h5>Image Analysis</h5>
            </div>
            <div className="">
              <img src={require("../images/Virtual-Reality.png")} alt="Virtual-Reality" className="w-25 mb-3" />
              <h5 className="mb-3">Virtual Reality</h5>
            </div>
            <div className="">
              <img src={require("../images/Information-Security.png")} alt="Information-Security" className="w-25 mb-3" />
              <h5>Information Security</h5>
            </div>
          </div>
        </div>

        <div className="container p-4 bg-secondary-subtle text-center d-sm-none d-block">
          <h2 className="mb-5 mt-4" style={{lineHeight:"35px"}}>What Our Java Development Services Cover</h2>
          <div className=" ">
            <div className="mb-5">
              <img src={require("../images/Image-analysis.png")} alt="Image-analysis" className="w-25 mb-3" />
              <h5>Image Analysis</h5>
            </div>
            <div className="mb-5">
              <img src={require("../images/Virtual-Reality.png")} alt="Virtual-Reality" className="w-25 mb-3" />
              <h5 className="mb-3">Virtual Reality</h5>
            </div>
            <div className="mb-5">
              <img src={require("../images/Information-Security.png")} alt="Information-Security" className="w-25 mb-3" />
              <h5>Information Security</h5>
            </div>
          </div>
        </div>


      </div>

    </>
  )
}

export default Cplus;