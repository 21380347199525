import { Stop } from "@mui/icons-material";
import React from "react";
// import { NavLink } from "react-router-dom";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const AssetManagement = () => {

    return (
        <>


            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/asset-management" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,AssetManagement" />
                    <title> AssetManagement | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company  bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4  m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1><b>Asset Management</b></h1>
                            <p>Enterprise asset management (EAM) consulting serves to digitalize asset management for maximized ROA. iCloudsoft designs, develops, implements, and supports EAM solutions for tech-driven asset planning and optimization.</p>
                            <p>Asset management is the day-to-day running of a wealth portfolio. It is usually headed by an investment manager. The management of assets involves building a portfolio of investments.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/asset-1.png")} alt="asset-1" className="img-fluid w-75  " />
                        </div>
                    </div>
                </div>

                <div className="company-box2 bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Asset Management Solutions</h2>
                    <div className="row g-4">
                        <div className="col-md-4 mb-3">
                            <div className="co mb-2 p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Asset tracking and monitoring</h5>
                            </div>

                            <h6><Stop className="ar" />Up to 50% reduced asset downtime</h6>
                            <h6><Stop className="ar" />Up to 24% asset productivity increase.</h6>
                            <p>due to optimized asset usage, minimized asset loss and rationalized asset expenditure.</p>
                        </div>

                        <div className="col-md-4">
                            <div className="co p-2 mb-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Asset maintenance management</h5>
                            </div>

                            <h6><Stop className="ar" />Up to 25% reduced maintenance costs</h6>
                            <h6><Stop className="ar" />Up to 70% fewer asset breakdowns.</h6>
                            <h6><Stop className="ar" />Up to 12% fewer scheduled repairs</h6>
                            <p>due to optimized asset maintenance and real-time monitoring of asset operation.</p>
                        </div>

                        <div className="col-md-4">
                            <div className="co p-2 mb-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Asset planning and optimization</h5>
                            </div>

                            <h6><Stop className="ar" />Up to 21% OEE increase.</h6>
                            <h6><Stop className="ar" />Up to 5% reduced capital investment</h6>
                            <p>due to optimized asset utilization scenarios and asset investment planning.</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default AssetManagement;