import React from "react";
// import { NavigateNext } from "@mui/icons-material";
import Header from "./Header";
// import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const AugmentedReality = () => {

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoft.co.in/augmented-reality" />
          <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Augmented-Reality " />
          <title> Augmented-Reality | Web Development Company In Pune  </title>
        </Helmet>
      </HelmetProvider>

      <Header />

      <div className="Company bg-light">
        <div className="company-boxA">
          <div className="row p-lg-4 g-4 m-2 ">
            <div className="col-md-6 ps-lg-5 mt-5">
              <h1 className="mb-3"><b>Augmented Reality</b></h1>
              <p>iCloudsoft creates marker-based and markerless AR software for the goals of education, shopping, marketing, remote collaboration, and more. AR is typically experienced through a mobile device, wearable device, or through special glasses that overlay digital information on top of the real world.</p>
              <p>Augmented Reality (AR) is a technology that superimposes computer-generated content such as images, video, or audio onto the real world in real-time, creating a composite view. Unlike Virtual Reality (VR), which replaces the real world entirely with a simulated environment, AR enhances the existing environment by adding digital elements to it.</p>
            </div>
            <div className="col-md-6">
              <img src={require("../images/AR.png")} alt="AR" className="img-fluid w-50 pt-4" style={{ marginLeft: "3rem" }} />
            </div>
          </div>
        </div>

        <div className="company-box2 bg-secondary-subtle m-3">
          <h2 className="text-center p-3 mb-3" style={{lineHeight:"35px"}} >Types of AR apps we develop</h2>
          <div className="row">
            <div className="col-md-6">
              <div className="co p-2 d-flex">
                <h4 className="ms-3 mt-2 mb-2">Marker-based</h4>
              </div>
              <p className="p-3">Such apps recognize a specific, custom marker – a pictogram or a QR code – and then substitute it with pre-set data on the device screen. There can be infinite numbers of both codes and assigned augmentations. Marker-based apps work best when augmentations are tied to a specific object, for instance, a human anatomy model for medical education or a particular art piece in a gallery.</p>
            </div>
            <div className="col-md-6">
              <div className="co p-2 d-flex">
                <h4 className="ms-3 mt-2 mb-2">Markerless (location-based)</h4>

              </div>
              <p className="p-3">To realistically position AR model without using any markers, apps use GPS, built-in sensors, as well as complex computer vision algorithms. This type is used when augmentations are supposed to adapt to various locations and, for instance, accurately place a furniture piece in a room, show navigation arrows in a tourist spot or point at the best bargain on a product shelf.</p>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}
export default AugmentedReality;