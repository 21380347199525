import React from "react";
import Header from "./Header";
import { Stop} from "@mui/icons-material";
// import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const OilGas = () => {

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoft.co.in/oil-gas" />
          <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Oil-Gas" />
          <title> Oil-Gas | Web Development Company In Pune  </title>
        </Helmet>
      </HelmetProvider>

      <Header />

      <div className="Company bg-light">
        <div className="company-boxA ">
          <div className="row p-lg-4 g-4 m-2 ">
            <div className="col-md-6 ps-lg-5 mt-5">
              <h1 className="mb-3"><b>Oil and Gas</b></h1>
              <p>iCloudsoft offers IT solutions for the oil and gas industry by employing cloud, IIoT, big data, advanced analysis, virtual reality (VR), and augmented reality (AR). We will be happy to provide you with a tailored IT solution for improved oil and gas exploration and production processes and data-driven decision-making.</p>
              <p>IT solutions for oil and gas help the industry embrace digital transformation for improved drilling quality and efficiency, optimized reservoir management, enhanced oil recovery, reduced operational costs, and more.</p>
            </div>
            <div className="col-md-6">
              <img src={require("../images/oil-g1.png")} alt="" className="img-fluid w-50 " style={{ marginLeft: "4rem" }} />
            </div>
          </div>
        </div>

        <div className="company-box2 bg-secondary-subtle m-3">
          <h2 className="text-center p-3 mb-3">Area of Expertics</h2>
          <div className="row g-4">
            <div className="col-md-4 mb-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">Advanced operational analytics</h5>
              </div>
              <h6 className="mt-3"><Stop className="ar" />Data ingestion and storage</h6>
              <h6><Stop className="ar" />Supply chain status analytics.</h6>
              <h6><Stop className="ar" />Operational cost analytics.</h6>
              <h6><Stop className="ar" />Capital project execution analytics.</h6>
              <h6 className="pb-2"><Stop className="ar" />Exploration progress analytics.</h6>
            </div>
            <div className="col-md-4">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">Procurement/Supplier management</h5>
              </div>
              <h6 className="mt-3"><Stop className="ar" />Supplier assessment and management.</h6>
              <h6><Stop className="ar" />Supplier risk management.</h6>
              <h6><Stop className="ar" />Inventory management.</h6>
              <h6><Stop className="ar" />Supplier portals.</h6>
              <h6><Stop className="ar" />Purchase order automation.</h6>
            </div>
            <div className="col-md-4">
              <div className="co  p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">Capital project management</h5>
              </div>
              <h6 className="mt-3"><Stop className="ar" />4D/5D building information modeling (BIM), VR.</h6>
              <h6><Stop className="ar" />Planning and scheduling..</h6>
              <h6><Stop className="ar" />Quality assurance.</h6>
              <h6><Stop className="ar" />Project cost management.</h6>
              <h6><Stop className="ar" />Project risk management.</h6>
            </div>
          </div>
        </div>
      </div>
      
    </>
  )
}
export default OilGas;