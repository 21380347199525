import { Stop } from "@mui/icons-material";
import React from "react";
// import { NavLink } from "react-router-dom";
import Header from "./Header";

const DocumentManagement = () => {

    return (
        <>
            <Header />

            <div className="Company  bg-light">
                <div className="company-boxA">
                    <div className="row g-4 p-lg-4  m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b> Document Management</b></h1>
                            <p>Documentation automation software reduces the drafting time of highly formalized documents (e.g., financial statements, invoices, government forms, employment contracts) by employing reusable document templates that leverage conditional logic and connected data sources. Documentation automation software integrates with OCR, DMS, CRM software, etc.</p>
                            <p>Elearning, also known as electronic learning or online learning, refers to the use of digital technologies and the internet to deliver educational content and training programs to learners. Elearning has become increasingly popular in recent years, as more and more people seek flexible and convenient ways to learn new skills and advance their careers.</p>
                        </div>

                        <div className="col-md-6">
                            <img src={require("../images/Document-1.png")} alt="Document-1" className="img-fluid w-50 " style={{ marginLeft: "3rem" }} />
                        </div>
                    </div>
                </div>

                <div className="company-box2  bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Area of Expertise</h2>
                    <div className="row g-4">
                        <div className="col-md-3 mb-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Template creation and management</h5>
                            </div>
                            <h6 className="mt-3"><Stop className="ar" />Template approval workflow.</h6>
                            <h6><Stop className="ar" />Centralized storage of intelligent templates.</h6>
                            <h6><Stop className="ar" />Templates search by titles, full text and metadata.</h6>
                            <h6><Stop className="ar" />A template editor for creating intelligent templates consisting of dynamic fields.</h6>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2" style={{fontSize:"18.2px"}}>Document generation and management</h5>
                            </div>
                            <h6 className="mt-3"><Stop className="ar" />Built-in e-signature.</h6>
                            <h6><Stop className="ar" />Alerts and notifications </h6>
                            <h6><Stop className="ar" />Document assembly workflow.</h6>
                            <h6><Stop className="ar" />Batch document generation</h6>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Document output and distribution</h5>
                            </div>
                            <h6 className="mt-3"><Stop className="ar" />Recruiting.</h6>
                            <h6><Stop className="ar" />Offline access to documents..</h6>
                            <h6><Stop className="ar" />Document output in different languages..</h6>
                            <h6><Stop className="ar" />Automated distribution of created documents via email.</h6>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Document output and distribution</h5>
                            </div>
                            <h6 className="mt-3"><Stop className="ar" />HR software.</h6>
                            <h6><Stop className="ar" />Multiple output formats</h6>
                            <h6><Stop className="ar" />Offline access to documents.</h6>
                            <h6><Stop className="ar" />Document output in different languages.</h6>
                            <h6><Stop className="ar" />Automated distribution of created documents via email.</h6>
                        </div>


                    </div>
                </div>
            </div>

        </>
    )
}
export default DocumentManagement;