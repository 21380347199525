import React from "react";
import { NavLink } from "react-router-dom";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const Company = () => {

    return (

        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/company" />
                    <meta name="keywords" content="About iCloudsoft Technology Web Development Company in Pune,commitment to excellence,leadership,10 years of consonant reviews" />
                    <title> About Us | Our Team  | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>



            <Header />

            <div className="Company-A bg-light">
                <div className="container d-none d-sm-block">
                    <div className="row g-4">
                        <div className="Company-boxx p-4" >
                            <h2 className="fs-1 title text-center mt-4" style={{ fontSize: "55px" }}><b>Welcome to iCloudsoft</b></h2>
                            <div className="text-infoC">
                                <p className="text-center mt-3 ">We'll  always happy to see you</p> </div>
                            <div className="d-flex justify-content-center">
                                <NavLink exact to="/Contact-Us" className="text-decoration-none" > <button type="button" class="btn btn-danger w-100 m-2" > Work with Us  </button></NavLink>
                            </div>
                        </div>
                        <div className="cimg ">
                            <div className="d-flex justify-content-center">
                                <img src={require('../images/about-img.png')} alt="about-img" className="img-fluid w-50" />
                            </div>
                            <div className="mt-5">
                                <h2 className=" title text-center fs-1" style={{ fontSize: "50px" }}><b> Our History</b></h2>
                                <p className="text-center  p-2 mt-3" style={{ fontSize: "15px" }}>Incorporated in 2013, iCloudsoft brings 10+ years of business experience in delivering e-commerce <br /> solutions, web development, software development, cloud solution, digital solutions and IT service to a wide <br />range of companies from entrepreneurs to large business owner which are significantly widen <br />to meet business and technology needs of today’s digital environment.</p>
                            </div>
                            <div>
                                <div className="company-M ">
                                    <div className="row p-4">
                                        <div className="col-md-6 mt-5  ">
                                            <h5 className="ms-5" ><b>Mission</b></h5>

                                            <p className="mb-3 ms-5">As an accurate, intelligent IT Company, iCloudsoft Technology is
                                                dedicated to serving innovative, reliable and accessible Software
                                                and Web Development with working with the next generation for
                                                make the world best place.</p>
                                            <h5 className="mb-3 ms-5"><b>Vision</b></h5>
                                            <p className="mb-3 ms-5"> Our vision at iCloudsoft Technology is to become no #1 Software and Web Development Company.</p>

                                        </div>
                                        <div className="col-md-6 ">
                                            <img src={require("../images/mission.png")} alt="mission" className="img-fluid w-100  " />
                                        </div>
                                    </div>
                                </div>
                                <div className="Swipper">

                                </div>
                            </div>
                            <div className="core-values">
                                <div className="home-box3 mb-5 mt-5">
                                    <h2 className="fs-1 title text-center " style={{ fontSize: "55px" }}><b>Core Values</b></h2>
                                    <div className="home-block ">
                                        <div className="hb2">
                                            <img src={require("../images/never-give.png")} alt="never-give" className=" mb-3 w-50 mt-3" style={{ marginLeft: "4rem" }} />

                                            <h6 className="mt-3   text-center">Do not give up</h6>
                                        </div>
                                        <div className="hb2">
                                            <img src={require("../images/humble.png")} alt="humble" className="mb-3 w-50  mt-3" style={{ marginLeft: "3rem" }} />

                                            <h6 className="mt-2  text-center">Be honest and respectful</h6>
                                        </div>

                                        <div className="hb2">
                                            <img src={require("../images/rocket.png")} alt="rocket" className="mb-3 w-50  mt-3" style={{ marginLeft: "3.5rem" }} />

                                            <h6 className="mt-2 text-center"> Always accept with a powerful entrepreneurship</h6>
                                        </div>
                                        <div className="hb2">
                                            <img src={require("../images/cooperate.png")} alt="cooperate" className="   w-75  " style={{ marginLeft: "3rem" }} />

                                            <h6 className="  text-center ">Co-ordinate with the other member freely</h6>
                                        </div>
                                        <div className="hb2">
                                            <img src={require("../images/larn.png")} alt="larn" className="mb-3 w-75  mt-3" style={{ marginLeft: "3rem" }} />

                                            <h6 className="mt-4  text-center"> Create an opportunity, you first need to know yourself on a deep level</h6>
                                        </div>
                                        <div className="hb2">
                                            <img src={require("../images/hand.png")} alt="hand" className="mb-3 w-50  mt-3" style={{ marginLeft: "4rem" }} />

                                            <h6 className="mt-4  text-center"> Open! Big as Life</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row " >
                                <div className="Company-CEO ">

                                    <div className="ceo " style={{ paddingLeft: "60px", paddingRight: "60px" }}>
                                        <h2 className="fs-1 title text-center mb-3" style={{ fontSize: "55px" }}><b>Message from CEO</b></h2>
                                        <p>iCloudsoft Technology is a  Software Development Company in Pune offering a comprehensive portfolio of products and services. We provide business consultancy and optimal solutions through our cloud based products.</p>
                                        <p>Our value proposition includes reduced total cost of ownership, fast and quality computing, high scalability, reduced security risks and superior performance. We help our clients achieve their goals by transforming the shortcomings into business advantages.</p>
                                        <p>In addition to these goals, iCloudsoft Technology also wants to make sure its customers get the best experience possible when using our products and services. We want to make sure every customer gets exactly what they need from us and nothing more so they can fully enjoy their experience with us and be able to take advantage of all of our amazing product offerings!</p>
                                    </div>
                                </div>
                                <div className="Company-CEO mt-4">

                                    <div className="ceo  " style={{ paddingLeft: "60px", paddingRight: "60px" }}>
                                        <h2 className="fs-1 title  text-center  mb-3" style={{ fontSize: "55px" }}><b>Leadership Team</b></h2>
                                        <p>Welcome to the iCloudsoft Technology Leadership Team. Our leadership team
                                            is dedicated to driving our company&#39;s success through innovation, collaboration, and
                                            a commitment to excellence. Our team is comprised of talented individuals with
                                            diverse backgrounds and experiences, all of whom share a passion for our
                                            company&#39;s mission and vision.</p>
                                        <p>At iCloudsoft Technology, we believe that strong leadership is essential for driving
                                            growth and success. Our leadership team brings a wealth of experience and
                                            expertise to the table, and is committed to leading our company towards a bright
                                            future.</p>
                                        <p>Each member of our leadership team is responsible for a specific area of the
                                            business, and works collaboratively with other team members to ensure that our
                                            company is operating at peak performance. Together, we are focused on achieving
                                            our goals and delivering exceptional results to our customers.</p>
                                        <p>Our leadership team is dedicated to upholding our company&#39;s core values and we strive to lead by example in everything we do. We are
                                            committed to creating a positive and supportive work environment for all employees,
                                            and believe that strong leadership is essential for driving employee engagement and
                                            job satisfaction.</p>
                                        <p>Thank you for taking the time to learn more about our leadership team. We are
                                            excited about the future of our company, and look forward to working with all of our
                                            stakeholders to achieve great things.</p>
                                    </div>
                                </div>
                            </div>
                            <section>
                                <div className="container">
                                    <div className="row ">
                                        <h2 className="fs-1 title text-center mt-5 p-3" style={{ fontSize: "40px" }}><b>Choose Us as a Trustworthy Partner<br /> For Your Growth </b></h2>

                                        <div className="col-md-6  d-flex border-end border-2 border-dark">
                                            <div>
                                                <h5 className=""><b>95% Customer Satisfaction</b></h5>
                                                <p className="text-center w-75">We work on the latest technologies and frameworks to give the user- friendly, scalable, secure solutions that meet any business need.</p>
                                            </div>
                                            <div><svg className="" xmlns="http://www.w3.org/2000/svg" width="100" height="50" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 1 16">
                                                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                            </svg>
                                            </div>
                                        </div>
                                        <div className="col-md-6   ">

                                        </div>
                                        <div className="col-md-6 d-flex border-end border-2 border-dark">

                                        </div>
                                        <div className="col-md-6 d-flex  ">
                                            <div > <svg className="" xmlns="http://www.w3.org/2000/svg" width="100" height="50" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 30 16 ">
                                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                            </svg></div>
                                            <div>
                                                <h5 className="text-left  "><b>Flexible Engagement Models</b></h5>
                                                <p className="text-left  w-75">We provide the flexibility to our clients to choose from recruitment and engagement models that fit of your budget</p></div>
                                        </div>
                                        <div className="col-md-6  d-flex border-end border-2 border-dark ">
                                            <div>
                                                <h5 ><b>Competitive pricing & on time delivery</b></h5>
                                                <p className="text-right w-75">We deliver an ideal mix of cost- effective solutions with incredible quality to guarantee the lowest prices in our segment and go to market quickly</p>
                                            </div>
                                            <div><svg className="" xmlns="http://www.w3.org/2000/svg" width="100" height="50" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 1 16">
                                                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                            </svg></div>
                                        </div>
                                        <div className="col-md-6   ">

                                        </div>
                                        <div className="col-md-6 d-flex border-end border-2 border-dark ">

                                        </div>
                                        <div className="col-md-6 d-flex  ">
                                            <div><svg className="" xmlns="http://www.w3.org/2000/svg" width="100" height="50" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 30 16 ">
                                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                            </svg></div>
                                            <div className="">
                                                <h5 className="text-left "><b>Varied Experience Across A Range Of Industries</b></h5>
                                                <p className="text-center  w-75">icloudsoft has a team of dedicated developers who are skilled in various industries and good at English & Japanese offering high - end business solutions in various frameworks and technologies</p></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                <div className="container d-sm-none d-block ">
                    <div className="row g-4">
                        <div className="Company-boxx p-4" >
                            <h2 className="fs-1 title text-center mt-4" style={{ fontSize: "55px" }}><b>Welcome to iCloudsoft</b></h2>
                            <div className="text-infoC">
                                <p className="text-center mt-3 ">We'll  always happy to see you</p> </div>
                            <div className="d-flex justify-content-center">
                                <NavLink exact to="/Contact-Us" className="text-decoration-none" > <button type="button" class="btn btn-danger w-100 m-2" > Work with Us  </button></NavLink>
                            </div>
                        </div>
                        <div className="cimg ">
                            <div className="d-flex justify-content-center">
                                <img src={require('../images/about-img.png')} alt="about-img" className="img-fluid w-50" />
                            </div>
                            <div className="mt-5">
                                <h2 className=" title text-center fs-1" style={{ fontSize: "50px" }}><b> Our History</b></h2>
                                <p className="text-center  p-2 mt-3" style={{ fontSize: "15.9px" }}>Incorporated in 2013, iCloudsoft brings 10+ years of business experience in delivering e-commerce  solutions, web development, software development, cloud solution, digital solutions and IT service to a wide range of companies from entrepreneurs to large business owner which are significantly widen to meet business and technology needs of today’s digital environment.</p>
                            </div>

                            <div>
                                <div className="company-M ">
                                    <div className="row g-4 p-3">
                                        <div className="col-md-6 mt-5  ">
                                            <h5 className="mb-2" ><b>Mission</b></h5>

                                            <p className="mb-5 ">As an accurate, intelligent IT Company, iCloudsoft Technology is
                                                dedicated to serving innovative, reliable and accessible Software
                                                and Web Development with working with the next generation for
                                                make the world best place.</p>
                                            <h5 className="mb-3"><b>Vision</b></h5>
                                            <p className="mb-3"> Our vision at iCloudsoft Technology is to become no #1 Software and Web Development Company.</p>

                                        </div>
                                        <div className="col-md-6 ">
                                            <img src={require("../images/mission.png")} alt="mission" className="img-fluid w-100  " />
                                        </div>
                                    </div>
                                </div>
                                <div className="Swipper">

                                </div>
                            </div>

                            <div className="core-values">
                                <div className="home-box3 mb-5 mt-5">
                                    <h2 className="fs-1 title text-center " style={{ fontSize: "55px" }}><b>Core Values</b></h2>
                                    <div className="home-block ">
                                        <div className="hb2">
                                            <img src={require("../images/never-give.png")} alt="never-give" className=" mb-3 w-50 mt-3" style={{ marginLeft: "4rem" }} />

                                            <h6 className="mt-3   text-center">Do not give up</h6>
                                        </div>
                                        <div className="hb2">
                                            <img src={require("../images/humble.png")} alt="humble" className="mb-3 w-50  mt-3" style={{ marginLeft: "3rem" }} />

                                            <h6 className="mt-2  text-center">Be honest and respectful</h6>
                                        </div>

                                        <div className="hb2">
                                            <img src={require("../images/rocket.png")} alt="rocket" className="mb-3 w-50  mt-3" style={{ marginLeft: "3.5rem" }} />

                                            <h6 className="mt-2 text-center"> Always accept with a powerful entrepreneurship</h6>
                                        </div>
                                        <div className="hb2">
                                            <img src={require("../images/cooperate.png")} alt="cooperate" className="   w-75  " style={{ marginLeft: "3rem" }} />

                                            <h6 className="  text-center ">Co-ordinate with the other member freely</h6>
                                        </div>
                                        <div className="hb2">
                                            <img src={require("../images/larn.png")} alt="larn" className="mb-3 w-75  mt-3" style={{ marginLeft: "3rem" }} />

                                            <h6 className="mt-4  text-center"> Create an opportunity, you first need to know yourself on a deep level</h6>
                                        </div>
                                        <div className="hb2">
                                            <img src={require("../images/hand.png")} alt="hand" className="mb-3 w-50  mt-3" style={{ marginLeft: "4rem" }} />

                                            <h6 className="mt-4  text-center"> Open! Big as Life</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row " >
                                <div className="Company-CEO ">

                                    <div className="ceo p-3" >
                                        <h2 className="fs-1 title text-center mb-4" style={{ fontSize: "55px" }}><b>Message from CEO</b></h2>
                                        <p>iCloudsoft Technology is a  Software Development Company in Pune offering a comprehensive portfolio of products and services. We provide business consultancy and optimal solutions through our cloud based products.</p>
                                        <p>Our value proposition includes reduced total cost of ownership, fast and quality computing, high scalability, reduced security risks and superior performance. We help our clients achieve their goals by transforming the shortcomings into business advantages.</p>
                                        <p>In addition to these goals, iCloudsoft Technology also wants to make sure its customers get the best experience possible when using our products and services. We want to make sure every customer gets exactly what they need from us and nothing more so they can fully enjoy their experience with us and be able to take advantage of all of our amazing product offerings!</p>
                                    </div>
                                </div>

                                <div className="Company-CEO mt-4">

                                    <div className="ceo  p-3">
                                        <h2 className="fs-1 title  text-center  mb-4" style={{ fontSize: "55px" }}><b>Leadership Team</b></h2>
                                        <p>Welcome to the iCloudsoft Technology Leadership Team. Our leadership team
                                            is dedicated to driving our company&#39;s success through innovation, collaboration, and
                                            a commitment to excellence. Our team is comprised of talented individuals with
                                            diverse backgrounds and experiences, all of whom share a passion for our
                                            company&#39;s mission and vision.</p>
                                        <p>At iCloudsoft Technology, we believe that strong leadership is essential for driving
                                            growth and success. Our leadership team brings a wealth of experience and
                                            expertise to the table, and is committed to leading our company towards a bright
                                            future.</p>
                                        <p>Each member of our leadership team is responsible for a specific area of the
                                            business, and works collaboratively with other team members to ensure that our
                                            company is operating at peak performance. Together, we are focused on achieving
                                            our goals and delivering exceptional results to our customers.</p>
                                        <p>Our leadership team is dedicated to upholding our company&#39;s core values and we strive to lead by example in everything we do. We are
                                            committed to creating a positive and supportive work environment for all employees,
                                            and believe that strong leadership is essential for driving employee engagement and
                                            job satisfaction.</p>
                                        <p>Thank you for taking the time to learn more about our leadership team. We are
                                            excited about the future of our company, and look forward to working with all of our
                                            stakeholders to achieve great things.</p>
                                    </div>
                                </div>
                            </div>

                            <section>
                                <div className="container">
                                    <div className="row g-4">
                                        <h2 className=" title text-center mt-4 mb-3 p-2" style={{ fontSize: "25px" }}><b>Choose Us as a Trustworthy Partner For Your Growth </b></h2>

                                        <div className="col-md-6  d-flex ">
                                            <div>
                                                <h5 className="text-center mb-3"><b>95% Customer Satisfaction</b></h5>
                                                <p className="text-center w-100">We work on the latest technologies and frameworks to give the user- friendly, scalable, secure solutions that meet any business need.</p>
                                            </div>
                                        </div>

                                        <div className="col-md-6 d-flex  ">
                                            <div>
                                                <h5 className="text-center mb-3  "><b>Flexible Engagement Models</b></h5>
                                                <p className="text-center  w-100">We provide the flexibility to our clients to choose from recruitment and engagement models that fit of your budget</p></div>
                                        </div>

                                        <div className="col-md-6  d-flex  ">
                                            <div>
                                                <h5 className="text-center mb-3"><b>Competitive pricing & on time delivery</b></h5>
                                                <p className="text-center  w-100">We deliver an ideal mix of cost- effective solutions with incredible quality to guarantee the lowest prices in our segment and go to market quickly</p>
                                            </div>
                                        </div>

                                        <div className="col-md-6 d-flex  ">
                                            <div className="">
                                                <h5 className="text-center mb-3"><b>Varied Experience Across A Range Of Industries</b></h5>
                                                <p className="text-center  w-100">icloudsoft has a team of dedicated developers who are skilled in various industries and good at English & Japanese offering high - end business solutions in various frameworks and technologies</p></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                <section className="bg-dark">
                    <div className="container d-none d-sm-block">
                        <div className="d-flex justify-content-center align-items-center flex-column mt-5 w-100">
                            <h2 className="fs-1 title text-center mt-5  " style={{ fontSize: "40px", color: "white" }}><b>Let's talk about Your Plan <br />to turn it into an awesome digital product</b></h2>
                            <div className="d-flex justify-content-center pt-3" >
                                <NavLink exact to="/Contact-Us" className="text-decoration-none" > <button type="button" class="btn btn-danger w-100 m-3 " > Start your Project </button></NavLink>
                            </div>
                        </div>
                    </div>

                    <div className="container d-sm-none d-block">
                        <div className="d-flex justify-content-center align-items-center flex-column mt-5 w-100 p-2">
                            <h2 className=" title text-center mt-5  " style={{ fontSize: "25px", color: "white",lineHeight:"33px" }}><b>Let's talk about Your Plan to turn it into an awesome digital product</b></h2>
                            <div className="d-flex justify-content-center pt-3" >
                                <NavLink exact to="/Contact-Us" className="text-decoration-none" > <button type="button" class="btn btn-danger w-100 m-2 " > Start your Project </button></NavLink>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <div className="row bg-light m-2 p-4">
                    <div className="col-md-6 company-box pe-5">
                        <p>iCloudsoft Technology is a  Software Development Company in Pune offering a comprehensive portfolio of products and services. We provide business consultancy and optimal solutions through our cloud based products.</p>
                        <p>Our value proposition includes reduced total cost of ownership, fast and quality computing, high scalability, reduced security risks and superior performance. We help our clients achieve their goals by transforming the shortcomings into business advantages.</p>
                        <p>In addition to these goals, iCloudsoft Technology also wants to make sure its customers get the best experience possible when using our products and services. We want to make sure every customer gets exactly what they need from us and nothing more so they can fully enjoy their experience with us and be able to take advantage of all of our amazing product offerings!</p>
                    </div>
                    <div className="col-md-6 company-box pe-5">
                        <p>Our line of products and services effectively address the business requirements and equip the enterprises to compete successfully in the global market. iCloudsoft Technology also offers its customers expert consultation, setup and deployment, training and support services on Website Development and Software Development.</p>
                        <p> We are always looking for ways to help our clients simplify the way they do business and at the same time reach high levels of success. Businesses no longer have to worry about key concerns such as data security and server maintenance, empowering them to move forward efficiently and with confidence. iCloudsoft Technology is a leading total IT Infrastructure Solution and Services Provider.</p>
                        <p>We're dedicated to helping people like you succeed in their business growth, because we believe in your potential and because we know that if you put your mind to it, anything is possible.</p>
                    </div>
                </div>

                <div className="company-box1 bg-light">
                    <h2 className="text-center pt-3">MISSION AND VISION</h2>
                    <div className="row p-4  m-2">
                        <div className="col-md-6">
                            <img src={require('../images/Aboutus-1.png')} alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-6 ps-5 mt-5">
                            <h5 className="mb-3"><b>Mission</b></h5>
                            <p className="mb-3">To become the world's best complete information technology business solution company.</p>
                            <h5 className="mb-3"><b>Vision</b></h5>
                            <p className="mb-3"> We help our customers to become & to be part of technology world!</p>
                            <p className="mb-3">Create opportunities for intellectual people.</p>
                        </div>
                    </div>
                </div>
                <div className="company-box2 m-3">
                    <h2 className="text-center p-3">OUR VALUES</h2>
                    <div className="row">
                        <div className="col-md-3">
                            <div className=" co p-4">
                                <h5>REALIZE</h5>
                            </div>
                            <p className="p-3">Realize your role and your potential. Be passionate, contribute into technologies and domain expertise. Make your positive impact on the company and the team. Take responsibility and bring value with your work. Your success is the company’s success as well.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-4">
                                <h5>IMPROVE</h5>
                            </div>
                            <p className="p-3">Improve yourself, your team and the company. Be persistent and open-minded in striving for personal growth. Think out of the box. Drive excellence and new ideas for your team, iCloudsoft and projects of our clients.</p>

                        </div>
                        <div className="col-md-3">
                            <div className="co p-4">
                                <h5>CARE</h5>
                            </div>
                            <p className="p-3">Care – be the one, who cares. Care about the results and pleasant atmosphere at the company. Understand the clients, their needs and priorities. Respect others’ time and work. Build trust for the long-run.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-4">
                                <h5>BE SHARP</h5>
                            </div>
                            <p className="p-3">Be sharp – be fast, flexible and curious. Be fearless about challenges and adaptive to changes. Come up with efficient and clever decisions. Support the spirit of sharpness in your iCloudsoft team.</p>
                        </div>
                    </div>
                </div> */}

            </div>

        </>
    )
}
export default Company;