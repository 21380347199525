import React, { useState } from "react";
import Header from "./Header";
// import { NavigateNext, Stop } from "@mui/icons-material";
// import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';



const ApplicationServices = () => {
    const [change, setChange] = useState("a");
    console.log(change);

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/application-services" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Application-Services " />
                    <title> Application-Services | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4  m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>Apllication Services</b></h1>
                            <p>Application services include app development, support, cloud migration, modernization, integration, security management, and more. In iCloudsoft brings all-around services to turn your enterprise applications into max profit.
                                We use modern technologies and approaches. We eagerly utilize and constantly develop our competencies in IoT, big data, machine learning, AI, image analysis, AR, DevOps/Continuous Delivery (CD), and don’t shun proven and long-trusted techs
                            </p>
                            <p>We know how to deal with complex enterprise IT landscapes that have evolved for decades.we’ve been accumulating the experience from multiple projects and learned the tricks to tame the systems of various nature and architectural patterns.
                                We are flexible in terms of cooperation. We are ready to cooperate with third-party vendors and together deliver business value to the customers. If the customer’s needs and priorities change, we can adjust the responsibility scope on the go and adapt service timelines to the new context.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/appliction.png")} alt="appliction" className="img-fluid w-75" style={{ marginLeft: "4rem" }} />
                        </div>
                    </div>
                </div>
                <div className="company-box2  bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Area Of Expertise</h2>
                    <div className="row g-4">
                        <div className="col-md-4 mb-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Application Development</h5>

                            </div>
                            <p className="p-3">We design and deliver web, mobile and cloud business applications of different complexity and for various needs. In the process, we pay special attention to their flexibility, security, speed and integration potential.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Application Management</h5>

                            </div>
                            <p className="p-3">We can take responsibility for the management of your applications ensuring its increased business flexibility and optimized IT costs.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Application modernization</h5>

                            </div>
                            <p className="p-3">Our team implements modernization of legacy applications to increase their efficiency and incorporate them better into a modern enterprise IT strategy.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Application integration</h5>

                            </div>
                            <p className="p-3">We can transform multiple independent applications of your IT environment into one coherently working system allowing for its increased efficiency and user convenience.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Application security services</h5>

                            </div>
                            <p className="p-3">We investigate security flaws of your application employing white box (with code audit) testing or black box testing (without access to application code) and provide a report with recommendations on overcoming found vulnerabilities.</p>
                        </div>

                    </div>
                </div>


            </div>

        </>
    )
}
export default ApplicationServices;