import React from "react";
import Header from "./Header";
// import { NavigateNext } from "@mui/icons-material";
// import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const WebPortals = () => {

    return (
        <>


            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/web-portals" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,WebPortals" />
                    <title> WebPortals | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4  m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>Web Portals</b></h1>
                            <p>A web portal is a secure web-based platform that gives access to varied functionality and content via an easy-to-navigate interface. A web portal development company with expertise in full-stack web techs, iCloudsoft can help you create a web portal of any type to cover your specific needs in engaging with the audience, be it your customers, partners, or employees.</p>
                            <p>Web portal development is an end-to-end service to plan and implement a secure and user-friendly digital space to serve your customers, partners, and employees. Web portals enable self-service functions for target users and give access to services, products, information, and communication tools.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/web-p1.png")} alt="web-p1" className="img-fluid w-50  mt-5 " style={{ marginLeft: "3rem" }} />
                        </div>
                    </div>
                </div>
                <div className="company-box2 bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Web Portals</h2>
                    <div className="row g-4">
                        <div className="col-md-4 mb-3">
                            <div className="co mb-3 p-2 d-flex">
                                <h5 className="ms-3 mb-2 mt-2">Self-service portals</h5>
                            </div>
                            <p>We aggregate information on your company’s products or services and allow registered users to request the information, activate / de-activate.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co mb-3 p-2 d-flex">
                                <h5 className="ms-3 mb-2 mt-2">Customer portals</h5>
                            </div>
                            <p>We create a digital space where your customers can access your products and place online orders, subscribe for services, submit maintenance requests.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co mb-3 p-2 d-flex">
                                <h5 className="ms-3 mb-2 mt-2">Employee portals</h5>
                            </div>
                            <p>We implement a secure platform for your employees to submit and manage requests for HR, IT, and facility-related services, access structured corporate data, collaborate.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co mb-3 p-2 d-flex">
                                <h5 className="ms-3 mb-2 mt-2">Community portals</h5>
                            </div>
                            <p>We connect people with similar interests and encourage them to acquire new knowledge and share their experience via forums, blogs, chats, and other communication means.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co mb-3 p-2 d-flex">
                                <h5 className="ms-3 mb-2 mt-2">Ecommerce portals</h5>
                            </div>
                            <p>We create intuitive buying experience for business buyers and individual consumers, and can integrate an ecommerce portal into an omnichannel retail ecosystem.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 mb-3 d-flex">
                                <h5 className="ms-3 mb-2 mt-2">eLearning portals</h5>
                            </div>
                            <p>We create portals for commercial training providers and corporate training programs. eLearning portal is a website that offers learners interaction.</p>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default WebPortals;