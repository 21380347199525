import { Stop } from "@mui/icons-material";
import React from "react";
// import { NavLink } from "react-router-dom";
import Header from "./Header";


const Python = () => {

  return (
    <>
      <Header />

      <div className="Company bg-light">
        <div className="company-boxA">
          <div className="row p-lg-4 g-4 m-2 ">
            <div className="col-md-6 ps-lg-5 mt-5">
              <h1 className="mb-3"><b>Python</b></h1>
              <p>Python is a computer programming language often used to build websites and software, automate tasks, and conduct data analysis. Python is a general-purpose language, meaning it can be used to create a variety of different programs and isn't specialized for any specific problems.</p>
              <p>iCloudsoft offers the services of Python developers and data scientists who ensure first-time-right code, on-time delivery, and high productivity. Our flexible services stretch from filling specific Python skill gaps to providing self-managed Python development teams.</p>
            </div>
            <div className="col-md-6">
              <img src={require("../images/pythan-1.png")} alt="" className="img-fluid w-50" style={{ marginLeft: "3rem" }} />
            </div>
          </div>
        </div>

        <div className="company-box2 bg-secondary-subtle m-3">
          <h2 className="text-center p-3 mb-3">Types of Applications Our Developers Build</h2>
          <div className="row g-4">
            <div className="col-md-3 mb-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-3 mb-3">Back-end programming</h5>
              </div>
              <h6 className="m-3"><Stop className="ar" />APIs</h6>
              <h6 className="m-3"><Stop className="ar" />Database interactions.</h6>
              <h6 className="m-3"><Stop className="ar" />Data-intensive web applications.</h6>
            </div>
            <div className="col-md-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-3 mb-3">Data analysis</h5>

              </div>
              <h6 className="m-3"><Stop className="ar" />Custom-made statistical models.</h6>
              <h6 className="m-3"><Stop className="ar" />Dashboards and reporting solutions.</h6>
            </div>
            <div className="col-md-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-3 mb-3">IoT development</h5>

              </div>
              <h6 className="m-3"><Stop className="ar" />Data warehouse design and engineering.</h6>
              <h6 className="m-3"><Stop className="ar" />Data analytics implementation.</h6>
              <h6 className="m-3"><Stop className="ar" />Development of control apps.</h6>
            </div>
            <div className="col-md-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-3 mb-3">Test Automation</h5>
              </div>
              <h6 className="m-3"><Stop className="ar" />Web apps, mobile apps, desktop apps.</h6>
              <h6 className="m-3"><Stop className="ar" />SaaS, mobile and desktop software products.</h6>
            </div>


          </div>
        </div>
      </div>

    </>
  )
}
export default Python;