import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Home from './Components/Home';
import Routing from './Components/Routing';
import ScrollToTop from './ScrollToTop';


const App =()=>{
    return(
          <BrowserRouter>
           <Routing/>
           <ScrollToTop/>
          </BrowserRouter>
        
    )
}
export default App;