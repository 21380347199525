import React from "react";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';



const ComputerVision = () => {

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoft.co.in/computer-vision" />
          <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,ComputerVision " />
          <title> ComputerVision | Web Development Company In Pune  </title>
        </Helmet>
      </HelmetProvider>


      <Header />

      <div className="Company bg-light">
        <div className="company-boxA">
          <div className="row p-lg-4 g-4  m-2 ">
            <div className="col-md-6 ps-lg-5 mt-5">
              <h1 className="mb-3"><b>Computer Vision </b></h1>
              <p>Image analysis (IA) is the identification of attributes within an image via digital image processing techniques to make the IA process more accurate and cost-effective.iCloudsoft helps both product companies and non-IT enterprises gain a competitive advantage by developing IA software.</p>
              <p>Image analysis or imagery analysis is the extraction of meaningful information from images; mainly from digital images by means of digital image processing techniques. Image analysis tasks can be as simple as reading bar coded tags or as sophisticated as identifying a person from their face.</p>
            </div>
            <div className="col-md-6">
              <img src={require("../images/computer.png")} alt="computer" className="img-fluid w-50 " style={{ marginLeft: "3rem" }} />
            </div>
          </div>
        </div>


        <div className="company-box2 bg-secondary-subtle m-3">
          <h2 className="text-center p-3 mb-3" style={{lineHeight:'34px'}} >iCloudsoft Approaches to Build Image Analysis Software</h2>
          <div className="row">

            <div className="col-md-6">
              <div className="co p-2 d-flex" >
                <h4 className="ms-3 mt-2 mb-2">Rule-based approach</h4>
              </div>
              <p className="p-3">The rule-based approach is a traditional method of programming computers that involves writing a set of rules or logical statements to solve a particular problem. In this approach, the programmer defines a set of if-then statements or rules that guide the computer's behavior in various situations. These rules are based on domain-specific knowledge and are typically created manually by subject matter experts.</p>
            </div>
            <div className="col-md-6">
              <div className="co p-2 d-flex">
                <h4 className="ms-3 mt-2 mb-2">Machine learning approach</h4>
              </div>
              <p className="p-3">Machine learning (ML) is a subset of artificial intelligence (AI) that involves training algorithms to learn patterns in data and make predictions or decisions without being explicitly programmed. There are various approaches to machine learning, including supervised learning, unsupervised learning, semi-supervised learning, and reinforcement learning.</p>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}
export default ComputerVision;