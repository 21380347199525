import React from "react";
// import { NavigateNext} from "@mui/icons-material";
import Header from "./Header";
// import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const Microsoft = () => {

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoft.co.in/microsoft" />
          <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Microsoft " />
          <title> Microsoft | Web Development Company In Pune  </title>
        </Helmet>
      </HelmetProvider>


      <Header />

      <div className="Company bg-light">
        <div className="company-boxA">
          <div className="row p-lg-4 g-4 m-2 ">
            <div className="col-md-6 ps-lg-5 mt-5">
              <h1 className="mb-3"><b>Microsoft</b></h1>
              <p>Microsoft Consulting Services (MCS) is a division of Microsoft Corporation that provides consulting services to businesses and organizations worldwide. MCS offers a range of services to help customers adopt, integrate, and optimize Microsoft technologies and solutions, including cloud computing, artificial intelligence, data analytics, and digital transformation.</p>
              <p>MCS offers a wide range of consulting services, including assessment and planning, implementation and migration, customization and integration, training and support, and managed services. The company's consultants work with customers to develop customized solutions that meet their unique business needs and goals.</p>
            </div>
            <div className="col-md-6">
              <img src={require("../images/Microsoft1.png")} alt="Microsoft1" className="img-fluid w-50 mt-5 " style={{ marginLeft: "3rem" }} />
            </div>
          </div>
        </div>

        <div className="company-box2  bg-secondary-subtle m-3">
          <h2 className="text-center p-3 mb-3">Our Microsoft Service</h2>
          <div className="row g-2">
            <div className="col-md-3 mb-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-2 mt-2 mb-2" >Digital transformation powered by Microsoft techs</h5>
              </div>
              <p className="p-3">iCloudsoft helps companies unfold holistic digital transformation and achieve the highest ROI from the initiatives based on Microsoft techs.</p>
            </div>
            <div className="col-md-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-2 mt-2 mb-2" >Big data solutions and platforms on Azure</h5>
              </div>
              <p className="p-3">iCloudsoft builds and supports organization-wide big data platforms and dedicated big-data-powered applications.</p>
            </div>
            <div className="col-md-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-2 mt-2 mb-2" >Data management & analytics with Azure and Power BI</h5>
              </div>
              <p className="p-3">Run highly efficient and modern data centers. Create and deploy BI solutions, incl. reporting, dashboards and visualization.</p>
            </div>
            <div className="col-md-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-2 mt-2 mb-2" >Cloud-native apps on Azure</h5>
              </div>
              <p className="p-3">End-to-end development of web and mobile apps using Azure cloud services. We take the full responsibility for turning your ideas.</p>
            </div>


          </div>
        </div>
      </div>

    </>
  )
}
export default Microsoft;