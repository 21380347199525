import React from "react";
import { Route, Routes } from "react-router-dom";
import ALML from "./Al-ML";
import ApplicationServices from "./Application-Services";
import ArtificialIntelligence from "./Artificial-Intelligence";
import AssetManagement from "./AssetManagement";
import AugmentedReality from "./AugmentedReality";
import Banking from "./Banking";
import BigData from "./Big-data";
import Blockchain from "./Blockchain";
import Cplus from "./C++";
import CMS from "./CMS";
import Company from "./Company";
import ComputerVision from "./ComputerVision";
import Contact from "./Contact";
import CRM from "./CRM";
import DataScience from "./Data-science";
import DataAnalysis from "./DataAnalysis";
import DevOps from "./DevOps";
import DocumentManagement from "./Document-management";
import Dotnet from "./Dotnet";
import ECommerce from "./E-Commerce";
import Elearning from "./Elearning";
import EnterpriseMobility from "./Enterprise-Mobility";
import ERP from "./ERP";
import FinancialManagement from "./FinancialManagement";
import Footer from "./Footer";
import Frontend from "./Frontend";
import GameDevelopment from "./Game-development";
import HealthCare from "./HealthCare";
import Home from "./Home";
import HumanResources from "./Human-Resources";
import IOT from "./IOT";
import Java from "./Java";
import Javascript from "./Javascript";
import Manufacturing from "./Manufacturing";
import MarketingAdvertising from "./Marketing-Advertising";
import Microsoft from "./Microsoft";
import MicrosoftServices from "./Microsoft.-services";
import Mobile from "./Mobile";
import Node from "./Node";
import OilGas from "./Oil-Gas";
import OperationsManagement from "./OperationsManagement";
import PHP from "./PHP";
import PowerApps from "./PowerApps";
import PowerBI from "./PowerBi";
import ProfessionalServices from "./ProfessionalServices";
import ProjectManagement from "./ProjectManagement";
import Python from "./Python";
import Reactjs from "./Reactjs";
import Retail from "./Retail";
import Salesforce from "./Salesforce";
import SharePoint from "./SharePoint";
import SoftwareDevelopment from "./Software-Development";
import SupplyChain from "./SupplyChain";
import Testing from "./Testing";
import TransportationLogistics from "./Transportation";
import Trending from "./Trending";
import WebDesign from "./UX-UI-Design";
import VR from "./Virtual-Reality";
import WebDevelopment from "./Web-Development";
import WebPortals from "./WebPortals";
import Blog from "./Blog";
import Blog1 from "./Blog1";

const Routing = () => {

    return (
        <>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/net" element={<Dotnet />} />
                <Route exact path="/java" element={<Java />} />
                <Route exact path="/python" element={<Python />} />
                <Route exact path="/javascript" element={<Javascript />} />
                <Route exact path="/c++" element={<Cplus />} />
                <Route exact path="/nodejs" element={<Node />} />
                <Route exact path="/mobile-app-development" element={<Mobile />} />
                <Route exact path="/iot" element={<IOT />} />
                <Route exact path="/big-data" element={<BigData />} />
                <Route exact path="/data-science" element={<DataScience />} />
                <Route exact path="/react" element={<Reactjs />} />
                <Route exact path="/artificialintelligence" element={<ArtificialIntelligence />} />
                <Route exact path="/virtual-reality" element={<VR />} />
                <Route exact path="/blockchain" element={<Blockchain />} />
                <Route exact path="/augmented-reality" element={<AugmentedReality />} />
                <Route exact path="/computer-vision" element={<ComputerVision />} />
                <Route exact path="/microsoft" element={<Microsoft />} />
                <Route exact path="/power-apps" element={<PowerApps />} />
                <Route exact path="/power-bi" element={<PowerBI />} />
                <Route exact path="/sharepoint-office-365" element={<SharePoint />} />
                <Route exact path="/salesforce" element={<Salesforce />} />
                <Route exact path="/solutions/crm" element={<CRM />} />
                <Route exact path="/software-development" element={<SoftwareDevelopment />} />
                <Route exact path="/marketing-advertising" element={<MarketingAdvertising />} />
                <Route exact path="/human-resources" element={<HumanResources />} />
                <Route exact path="/elearning" element={<Elearning />} />
                <Route exact path="/document-management" element={<DocumentManagement />} />
                <Route exact path="/supply-chain-management" element={<SupplyChain />} />
                <Route exact path="/erp" element={<ERP />} />
                <Route exact path="/operations-management" element={<OperationsManagement />} />
                <Route exact path="/financial-management" element={<FinancialManagement />} />
                <Route exact path="/asset-management" element={<AssetManagement />} />
                <Route exact path="/project-management" element={<ProjectManagement />} />
                <Route exact path="/data-analysis" element={<DataAnalysis />} />
                <Route exact path="/web-portals" element={<WebPortals />} />
                <Route exact path="/cms" element={<CMS />} />
                <Route exact path="/healthcare" element={<HealthCare />} />
                <Route exact path="/banking" element={<Banking />} />
                <Route exact path="/manufacturing" element={<Manufacturing />} />
                <Route exact path="/professional-services" element={<ProfessionalServices />} />
                <Route exact path="/retail" element={<Retail />} />
                <Route exact path="/transportation-and-logistics" element={<TransportationLogistics />} />
                <Route exact path="/oil-gas" element={<OilGas />} />
                <Route exact path="/testing-qa" element={<Testing />} />
                <Route exact path="/application-services" element={<ApplicationServices />} />
                <Route exact path="/ux-ui-design" element={<WebDesign />} />
                <Route exact path="/php" element={<PHP />} />
                <Route exact path="/company" element={<Company />} />
                <Route exact path="/web-development" element={<WebDevelopment />} />
                <Route exact path="/enterprise-mobility" element={<EnterpriseMobility />} />
                <Route exact path="/e-commerce" element={<ECommerce />} />
                <Route exact path="/microsoft-services" element={<MicrosoftServices />} />
                <Route exact path="/frontend" element={<Frontend />} />
                <Route exact path="/trending" element={<Trending />} />
                <Route exact path="/gamedevelopment" element={<GameDevelopment />} />
                <Route exact path="/al-ml" element={<ALML />} />
                <Route exact path="/devops" element={<DevOps />} />
                <Route exact path="/contact-us" element={<Contact />} />
                <Route exact path="/Blog" element={<Blog />} />
                <Route exact path="/blog1" element={<Blog1 />} />

                <Route to path="*" element={<Error />} />

                <Route to path="/404" element={<Error />} />
                <Route to path="/500" element={<Error />} />

            </Routes>

            {/* <footer>
                <Footer />
            </footer> */}

        </>
    )
}
export default Routing;