// import { Stop, NavigateNext } from "@mui/icons-material";
import React from "react";
// import { NavLink } from "react-router-dom";
import Header from "./Header";

const IOT = () => {

    return (
        <>
            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4  m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>Internet of Things Services</b></h1>
                            <p>At iCloudsoft, we drive value-centered IoT solutions and build multi-level data pipelines for that: from edge computing to cloud data processing and data science.iCloudsoft is a globally acclaimed IoT app development company in India capable of developing unique IoT apps stretching the connected ecosystem of gadgets with unique user experience and seamless performance.</p>
                            <p>IoT presents a powerful mechanism to connect various objects with the internet and take advantage of smart technology. It will allow devices to connect and transfer the information, which can be used for a variety of purposes. IoT is changing the way businesses work. IoT data can be connected with advanced analytics and help businesses to get valuable insights to make their operations smoother.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/internet.png")} alt="" className="img-fluid w-75 " />
                        </div>
                    </div>
                </div>
               
                <div className="company-box2 bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Area Of Expertise</h2>
                    <div className="row g-4">
                        <div className="col-md-4 mb-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">IoT Development Consultancy</h5>
                            </div>
                            <p className="p-3">At iCloudsoft, we offer internet of things development consulting service by our skilled IoT app experts.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">IoT App Development</h5>
                            </div>
                            <p className="p-3">Our IoT mobile app programmers & developers will help you to get the right IoT development solution. Here, we deliver flexible strategies, blended with high-security to manage new and different IoT devices.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Backend & API Development</h5>
                            </div>
                            <p className="p-3">To develop a successful IoT app backend team must be required. Our team of expert internet of things programmers and coders give full backend and API development solutions for our valuable client.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">App Development for IoT Devices</h5>
                            </div>
                            <p className="p-3">iCloudsoft team helps you to make a specific app for various devices, helping you to reach your business goal and make the most of IoT development technology.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">IoT Gateway Development</h5>
                            </div>
                            <p className="p-3">Our team is always ready for IoT development related requirements, therefore, we build IoT gateway for your app.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">IoT Implementation & Support</h5>
                            </div>
                            <p className="p-3">By providing internet of things app development services we also provide complete support & maintenance services to our prestigious clients.</p>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}
export default IOT;