import React from "react";
// import { Stop ,NavigateNext } from "@mui/icons-material";
import Header from "./Header";
// import { NavLink } from "react-router-dom";

const Javascript = () => {

  return (
    <>
      <Header />

      <div className="Company bg-light">
        <div className="company-boxA">
          <div className="row p-lg-4 g-4 m-2 ">
            <div className="col-md-6 ps-lg-5 mt-5">
              <h1 className="mb-3"><b>Javascript</b></h1>
              <p>JavaScript is a scripting language that enables you to create dynamically updating content, control multimedia, animate images, and pretty much everything else.JavaScript, often abbreviated as JS, is a programming language that is one of the core technologies of the World Wide Web, alongside HTML and CSS.</p>
              <p>JavaScript is a client-side development language that helps create dynamic website content. Powered by Node.js, JavaScript serves as a fast server-side language for the entire application to be coded in JavaScript.</p>
            </div>
            <div className="col-md-6">
              <img src={require("../images/java-script.png")} alt="java-script" className="img-fluid w-50" style={{ marginLeft: "3rem" }} />
            </div>
          </div>
        </div>

        <div className="container p-4 bg-secondary-subtle text-center d-none d-sm-block">
          <h2 className="mb-4">Development Areas We Use JavaScript in</h2>
          <div className=" testing ">
            <div className="">
              <img src={require("../images/web-application.png")} alt="web-application" className="w-25 mb-3" />
              <h5>Web apps of any scale</h5>
            </div>

            <div className="">
              <img src={require("../images/progressive-web-apps.png")} alt="progressive-web-apps" className="w-25 mb-3" />
              <h5 className="mb-3">Progressive Web Apps</h5>
            </div>

            <div className="">
              <img src={require("../images/custom-api.png")} alt="custom-api" className="w-25 mb-3" />
              <h5>Custom APIs</h5>
            </div>
          </div>
        </div>

        <div className="container p-4 bg-secondary-subtle text-center d-sm-none d-block">
          <h2 className="mb-5 mt-4">Development Areas We Use JavaScript in</h2>
          <div className="  ">
            <div className="mb-5">
              <img src={require("../images/web-application.png")} alt="web-application" className="w-25 mb-3" />
              <h5>Web apps of any scale</h5>
            </div>

            <div className="mb-5">
              <img src={require("../images/progressive-web-apps.png")} alt="progressive-web-apps" className="w-25 mb-3" />
              <h5 className="mb-3">Progressive Web Apps</h5>
            </div>

            <div className="mb-5">
              <img src={require("../images/custom-api.png")} alt="custom-api" className="w-25 mb-3" />
              <h5>Custom APIs</h5>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
export default Javascript;