import React from "react";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';

// import { NavigateNext, Stop } from "@mui/icons-material";
// import { NavLink } from "react-router-dom";



const Blockchain = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/blockchain" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,BlockChain " />
                    <title> BlockChain | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4 m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>Blockchain Development</b></h1>
                            <p>In simple words, the blockchain is a unique distributed record that stores data and verifies its integrity. By using a different set of cryptography based technologies, Blockchain assures that transaction continued into the blockchain database which is stable.</p>
                            <p>Blockchain technology manages the every currency transactions. But Blockchain is not limited to just currency but enlarges to any domain where anything of value is transacted, be it contracts, personal information, health records, business data and much more.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/blockchain1.png")} alt="blockchain1" className="img-fluid w-75" style={{ marginLeft: "4rem" }} />
                        </div>
                    </div>
                </div>

                <div className="company-box2 bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Process We Follow</h2>
                    <div className="row g-4">
                        <div className="col-md-3 mb-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2"> Requirement Gathering</h5>

                            </div>
                            <p className="p-3">We follow the first and foremost priority of gathering requirements, resources, and information to begin our project.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2"> UI/UX Design</h5>

                            </div>
                            <p className="p-3">We create catchy and charming designs with the latest tools of designing to make it a best user-friendly experience.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Prototype</h5>

                            </div>
                            <p className="p-3">After designing, you will get your prototype, which will be sent ahead for the development process for the product.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2"> Development</h5>

                            </div>
                            <p className="p-3">Development of mobile application/web/blockchain started using latest tools and technologies with transparency.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2"> Quality Assurance</h5>

                            </div>
                            <p className="p-3">iCloudsoft values quality and provides 100% bug free application with no compromisation in it.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2"> Deployment</h5>

                            </div>
                            <p className="p-3">After trial and following all processes, your app is ready to launch on the App store or Play Store.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2"> Support & Maintenance</h5>

                            </div>
                            <p className="p-3">Our company offers you all support and the team is always ready to answer every query after deployment.</p>
                        </div>

                    </div>
                </div>

            </div>





        </>
    )
}
export default Blockchain;
