import React from "react";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const CRM = () => {

    return (
        <>


            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/crm" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Customer Relationship Management" />
                    <title> CRM | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />
            
            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row g-4 p-lg-4  m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>CRM Services</b></h1>
                            <p>CRM stands for Customer Relationship Management, which refers to a set of strategies, technologies, and practices that organizations use to manage and analyze interactions with customers and prospects. The goal of CRM is to improve customer retention and drive sales growth by better understanding and responding to customer needs and preferences.</p>
                            <p>CRM typically involves the use of software tools and platforms that help businesses organize customer data and automate key business processes, such as sales forecasting, lead management, customer service, and marketing automation. These tools enable businesses to collect, analyze, and leverage customer data to improve customer satisfaction and drive revenue growth.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/CRM-2.png")} alt="CRM-2" className="img-fluid w-75" />
                        </div>
                    </div>
                </div>
                
                <div className="company-box2 bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Area Of Expertise</h2>
                    <div className="row g-4">
                        <div className="col-md-4 mb-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">CRM consulting</h5>
                            </div>
                            <p className="p-3">CRM strategy consulting: design of marketing, sales and customer service digital tools that will help your team build positive relations with customers.CRM project consulting: a strategy for your CRM implementation, revamp, migration or consolidation.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">CRM implementation</h5>
                            </div>
                            <p className="p-3">End-to-end CRM implementation, leveraging the capabilities of market-leading CRM platforms.We cover the entire project scope, including requirements engineering, CRM configuration and customization, and its further inbound and outbound integrations.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Custom CRM development</h5>
                            </div>
                            <p className="p-3">CRM development from scratch: designing custom workflows, building functional modules and connecting them into a coherent CRM system.Development of custom apps and add-ons for platform-based CRM</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">CRM migration</h5>
                            </div>
                            <p className="p-3">We analyze the bottlenecks you see in your current CRM and plan the migration strategy to achieve higher process efficiency, better usability, and lower ownership cost.We take care of an end-to-end migration process from building a new CRM to accurately transferring your historical data.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">CRM testing</h5>
                            </div>
                            <p className="p-3">Includes functional, integration, performance, usability, and security testing.Our testing services are aimed at providing you with insights on your CRM functioning and identifying the ways to better your existing solution.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">CRM support and evolution</h5>
                            </div>
                            <p className="p-3">Administration support, including daily administration, data administration, 24/7 CRM system monitoring, monthly system health checks and user help desk.Development support, including troubleshooting and CRM system evolution.</p>
                        </div>


                    </div>
                </div>
            </div>

        </>
    )
}
export default CRM;