import React from "react";
import Header from "./Header";
// import { NavigateNext, Stop } from "@mui/icons-material";
// import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';



const SoftwareDevelopment = () => {

   return (
      <>

         <HelmetProvider>
            <Helmet>
               <meta charset="utf-8" />
               <link rel="canonical" href="https://www.icloudsoft.co.in/software-development" />
               <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,SoftDevelopment " />
               <title> SoftDevelopment | Web Development Company In Pune  </title>
            </Helmet>
         </HelmetProvider>

         <Header />

         <div className="Company bg-light">
            <div className="company-boxA">
               <div className="row p-lg-4 g-4  m-2 ">
                  <div className="col-md-6 ps-lg-5 mt-lg-4 pt-3">
                     <h1 className="mb-3"><b>Software Development</b></h1>
                     <p>Software development is the practice of developing computer software. The entire process from conception to completion can be very challenging and require a lot of knowledge. Software development requires the skill and knowledge of many areas in business including software engineering, customer relations, project management, graphics design and much more.</p>
                     <p>If you want to transform your Business process with our standard Customized applications modernizations,
                        you will be able to streamline your business process and applications to ensure efficiency and be more agile with the power of the New IT.
                        Automate your business process to let down into the existing software market to fill the gap between the audience and you.
                     </p>
                  </div>
                  <div className="col-md-6 mt-5" >
                     <img src={require("../images/software1.png")} alt="software1" className="img-fluid w-50" style={{ marginLeft: "4rem" }} />
                  </div>
               </div>
            </div>


            <div className="company-box2 bg-secondary-subtle m-3">
               <h2 className="text-center p-3 mb-3">Area Of Expertise</h2>
               <div className="row g-4">
                  <div className="col-md-3 mb-3">
                     <div className="co p-2 d-flex">
                        <h5 className="ms-3 mt-2 mb-2">Application Assessments</h5>
                     </div>
                     <p className="p-3">iCloudsoft is the best software provider that provides application assessment to have a complete understanding of the legacy system.</p>
                  </div>
                  <div className="col-md-3">
                     <div className="co p-2 d-flex">
                        <h5 className="ms-3 mt-2 mb-2">Digital Transformation</h5>
                     </div>
                     <p className="p-3">iCloudsoft strive for digital innovations that propel game-changing business transformations. By combining next-gen technologies with human ingenuity.</p>
                  </div>
                  <div className="col-md-3">
                     <div className="co p-2 d-flex">
                        <h5 className="ms-3 mt-2 mb-2">Re-Platform Service</h5>
                     </div>
                     <p className="p-3">The re-platform services help move the applications to the newer technology platform, which is less expensive and efficient.</p>
                  </div>
                  <div className="col-md-3">
                     <div className="co p-2 d-flex">
                        <h5 className="ms-3 mt-2 mb-2">Enterprise Software Development</h5>
                     </div>
                     <p className="p-3">iCloudsoft offer you enterprise software development services that help support the business's infrastructure so that can boost the face of the enterprise.</p>
                  </div>
                  <div className="col-md-3">
                     <div className="co p-2 d-flex">
                        <h5 className="ms-3 mt-2 mb-2">Custom Software Development</h5>
                     </div>
                     <p className="p-3">iCloudsoft build Custom Software development which gives superior performance and advanced functionality for the software solutions. We build software that meets your business requirements and growth.</p>
                  </div>
                  <div className="col-md-3">
                     <div className="co p-2 d-flex">
                        <h5 className="ms-3 mt-2 mb-2">Product Software Development</h5>
                     </div>
                     <p className="p-3">iCloudsoft make the perfect product-based software development to highlight the product efficiently in the eyes of the audience and give a platform to the business in the market. We make secure, solid, robust, and productive software.</p>
                  </div>
                  <div className="col-md-3">
                     <div className="co p-2 d-flex">
                        <h5 className="ms-3 mt-2 mb-2">Migration & Integration</h5>
                     </div>
                     <p className="p-3">iCloudsoft make the perfect product-based software development to highlight the product efficiently in the eyes of the audience and give a platform to the business in the market. We make secure, solid, robust, and productive software.</p>
                  </div>
                  <div className="col-md-3">
                     <div className="co p-2 d-flex">
                        <h5 className="ms-3 mt-2 mb-2">Business Automation</h5>
                     </div>
                     <p className="p-3">iCloudsoft make software for business automation which gives you help in maintaining the productivity of the business. Our team makes sure to have the software that gives you an increase in productivity and process.</p>
                  </div>
               </div>
            </div>
         </div>
         
      </>
   )
}
export default SoftwareDevelopment;