import React from "react";
import Header from "./Header";
import './Data-science.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';


const DataScience = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/data-science" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,DataScience" />
                    <title> DataScience | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4 m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>Data Science</b></h1>
                            <p>Data science services help companies run experiments on their data in search of business insights. iCloudsoft renders data science consulting leveraging Machine Learning, Artificial Intelligence, and Deep Learning technologies to meet our clients’ most deliberate analytics needs.</p>
                            <p>Data science is the study of data to extract meaningful insights for business. It is a multidisciplinary approach that combines principles and practices from the fields of mathematics, statistics, artificial intelligence, and computer engineering to analyze large amounts of data.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/data1.png")} alt="data1" className="img-fluid w-50 " style={{ marginLeft: "3rem" }} />
                        </div>
                    </div>
                </div>

                <div className="company-box2 bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Our Data Science Services Include</h2>
                    <div className="row g-2">
                        <div className="col-md-3 mb-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Business needs analysis</h5>
                            </div>
                            <p className="p-3"> Outlining business objectives to meet with data science.Defining issues with the existing data science solution (if any).Deciding on data science deliverables.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Data preparation</h5>
                            </div>
                            <p className="p-3">Determining data source for data science.Data collection, transformation and cleansing.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Machine learning (ML) Model design</h5>
                            </div>
                            <p className="p-3">Choice of the optimal data science techniques and methods.Defining the criteria for the future ML model(s) evaluation. ML model development, training, testing and deployme</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Delivering data science output</h5>
                            </div>
                            <p className="p-3">Custom ML-driven app for self-service use (optional).ML model integration into other applications (optional).Data science insights ready for business use in the form of reports and dashboards.</p>
                        </div>


                    </div>
                </div>
            </div>

        </>
    )
}
export default DataScience;