import React from "react";
import Header from "./Header";
import { Stop } from "@mui/icons-material";
// import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const CMS = () => {

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoft.co.in/cms" />
          <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,CMS" />
          <title> CMS | Web Development Company In Pune  </title>
        </Helmet>
      </HelmetProvider>

      <Header />

      <div className="Company bg-light">
        <div className="company-boxA">
          <div className="row p-lg-4 g-4  m-2 ">
            <div className="col-md-6 ps-lg-5 mt-5">
              <h1 className="mb-3"><b>CMS Development</b></h1>
              <p>iCloudsoft competently delivers custom CMS solutions that combine responsive UI, rich functionality, strong data security, and high scalability.Content management system (CMS) development is needed to create intuitive, customizable, scalable, and SEO-oriented solutions for handling your corporate content. </p>
              <p>Content management system (CMS) development is needed to create intuitive, customizable, scalabl CMS development by iCloudsoft helps companies ensure prompt and cost-effective web content management and encourage website lead generation.</p>
            </div>
            <div className="col-md-6">
              <img src={require("../images/CMS-1.png")} alt="CMS-1" className="img-fluid w-75  " />
            </div>
          </div>
        </div>

        <div className="company-box2 bg-secondary-subtle m-3">
          <h2 className="text-center p-3 mb-3">CMS Features</h2>
          <div className="row g-4">
            <div className="col-md-3 mb-3">
              <div className="co  p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">Content creation and storage</h5>

              </div>
              <h6 className="mt-3"><Stop className="ar" /> WYSIWYG editor and content templates.</h6>
              <h6><Stop className="ar" />Content hierarchy taxonomy.</h6>
              <h6><Stop className="ar" />Indexing and full-text search.</h6>
              <h6><Stop className="ar" />AI-powered auto-tagging and metadata creation.</h6>
            </div>
            <div className="col-md-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">CMS administration</h5>

              </div>
              <h6 className="mt-3"><Stop className="ar" />Content governance.</h6>
              <h6><Stop className="ar" />Reporting and analytics (e.g., content usage, user behavior).</h6>
              <h6><Stop className="ar" />Integrated help desk.</h6>
              <h6><Stop className="ar" />Alerts and notifications (e.g., about content publishing, modification).</h6>                        </div>
            <div className="col-md-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">Content publishing promotion</h5>


              </div>
              <h6 className="mt-3"><Stop className="ar" />Built-in SEO tools and SEO-friendly URLs.</h6>
              <h6><Stop className="ar" />Customizable content approval and publishing workflows, including non-linear, multistep processes.</h6>
              <h6><Stop className="ar" />AI-driven content personalization (smart suggestions).</h6>
              <h6 className="pb-3"><Stop className="ar" />Multisite and multilanguage support.</h6>
            </div>
            <div className="col-md-3">
              <div className="co  p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">Security and compliance</h5>

              </div>
              <h6 className="mt-3"><Stop className="ar" />Access control and multi-factor user authentication.</h6>
              <h6><Stop className="ar" />Role-based permissions.</h6>
              <h6><Stop className="ar" />Versioning and audit trail.</h6>
              <h6><Stop className="ar" />Content retention and deletion policies.</h6>
              <h6><Stop className="ar" />Technical compliance (e.g., WCAG 2).</h6>                            </div>



          </div>
        </div>
      </div>

    </>
  )
}
export default CMS;