import React from "react";
import Header from "./Header";
// import { Stop, NavigateNext } from "@mui/icons-material";
// import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Banking = () => {

    return (
        <>


            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/banking" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Banking" />
                    <title> Banking | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4 m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1><b>Financial Software Development</b></h1>
                            <p>Financial software development services are aimed to help companies from banking, financial services, and insurance industries increase operational efficiency, improve customer engagement and retention, and leverage new, more profitable business models.</p>
                            <p>iCloudsoft provides full-cycle IT consulting and software development services to help BFSI companies digitally transform their business operations and unlock new opportunities for growth. We guide our clients through the whole financial software implementation journey and give the highest priority to sensitive data security and achieving regulatory compliance.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/banking-2.png")} alt="banking-2" className="img-fluid w-75  " />
                        </div>
                    </div>
                </div>

                <div className="bg-light bg-secondary-subtle">
                    <div class="container jb">
                        <div class="big-box ">
                            <div className="card p-3 ms-3 data-b ">
                                <img src={require("../images/Banking-App.png")} alt="Banking-App" className="w-25 mb-3" />
                                <h4 className=""><b>Banking App</b></h4>
                            </div>

                            <div className="card p-3 ms-3 data-b">
                                <img src={require("../images/Payment-App.png")} alt="" className="w-25 mb-3" />
                                <h4 className="mb-2"><b>Payment App</b></h4>
                            </div>

                            <div className="card p-3 ms-3 data-b">
                                <img src={require("../images/Money-Transfer-App.png")} alt="" className="w-25 mb-3" />
                                < h4 className="mb-2"><b>Money Transfer App </b></h4>
                            </div>

                            <div className="card p-3 ms-3 data-b">
                                <img src={require("../images/Insurance-App.png")} alt="" className="w-25 mb-3" />
                                <h4 className="mb-2"><b>Insurance App</b></h4>
                            </div>

                            <div className="card p-3 ms-3 data-b">
                                <img src={require("../images/Investment-App.png")} alt="" className="w-25 mb-3" />
                                <h4 className="mb-2"><b>Investment App</b></h4>
                            </div>

                            <div className="card p-3 ms-3 data-b">
                                <img src={require("../images/Personal-Finance-App.png")} alt="" className="w-25 mb-3" />
                                <h4 className="mb-2"><b>Personal Finance App</b></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Banking;