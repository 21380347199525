import React from "react";
import { NavLink } from "react-router-dom";
import Header from "./Header";
// import { NavigateNext } from "@mui/icons-material";

const Trending =()=>{

    return(
        <>
        <Header/>
        <div className="info">
            <div class="text-left ms-5 p-2">
            <NavLink exact to="/" style={{textDecoration:"none" ,color:"grey"}}>Home</NavLink> <br></br>  Trending 
            </div>
           </div>
           <div className="container-fluid">
           <div className="ux-box  p-5 ux1">      
                <h1 className="mt-5">Augmented Reality(AR) Development Company </h1>
                <p>iCloudsoft is a front runner name in the field of Augmented Reality (AR) app development. We are a leading Augmented Reality development company in India with a track record of shaping the success story of several leading AR apps.</p>
                <p>As one of the leading Augmented Reality Development Services, we provide you cutting edge AR development services and help you infuse living reality with digital reality to deliver exceptional AR app user experience to the users. Main highlights of our AR development expertise in CMARIX are robust cloud support thanks to AWS, Google Cloud and MS-Azure, exceptionally creative AR app ideas and a robust portfolio of AR apps for different business niches.</p>
        </div>
            <div className="row bg-light ">
                 <div className=" col-md-6 dot-box bg">
                    <h1>virtual reality (VR) Development Company </h1>
                    <p>iClousoft is a leading virtual reality development company in India to provide unique and immersive VR experience powered by sophisticated features, unique VR implementation and lean design.</p>
                    <p>As one of the front runners among virtual reality development services, we build unique and high performance VR apps with state of the art features and immersive user experience. We create VR apps that easily blurs the gap between everyday reality and digital experience and ensure easy engagement. We help our clients with exceptionally brilliant creative VR app ideas that instantly find traction and engagement.</p>
                    </div>
                    <div className="col-md-6 dot-box bg">
                    <img src={require("../images/Virtual-Reality.png")} alt="Virtual-Reality"  className="p-4 w-75"/>
                </div>
            </div>
       
          </div>  
        </>
    )
}
export default Trending;