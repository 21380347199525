import React from "react";
import Header from "./Header";
import "./Common.css";
import { Stop } from "@mui/icons-material";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const HumanResources = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/human-resources" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Human-Resource" />
                    <title> Human-Resource | Web Development Company In Pune | iCloudsoft Technology </title>
                </Helmet>
            </HelmetProvider>


            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row g-4 p-lg-4  m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b> Human Resources</b></h1>
                            <p>iCloudsoft provides technical product management consulting, product development, UX and UI design, QA, maintenance and support services to create powerful HR systems that help you increase competitiveness in the SaaS HR software market and reduce product development costs and risks.</p>
                            <p>Human resources (HR) refers to the department within an organization that is responsible for managing and developing the human capital of the organization. This includes a wide range of functions, such as recruiting and hiring employees, training and development, performance management, compensation and benefits, and employee relations.</p>  </div>
                        <div className="col-md-6">
                            <img src={require("../images/Human.png")} alt="Human" className="img-fluid w-50 " style={{ marginLeft: "3rem" }} />
                        </div>
                    </div>
                </div>

                <div className="company-box2 bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Area of Expertics</h2>
                    <div className="row g-4">
                        <div className="col-md-3 mb-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">HR administrative tasks</h5>
                            </div>
                            <h6 className="mt-3"><Stop className="ar" />Employee database.</h6>
                            <h6><Stop className="ar" />Employee profiles.</h6>
                            <h6><Stop className="ar" />Payroll management.</h6>
                            <h6><Stop className="ar" />Employee benefits.</h6>
                            <h6><Stop className="ar" />Reporting and analytics.</h6>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">HR service management</h5>
                            </div>
                            <h6 className="mt-3"><Stop className="ar" />Organizational chart.</h6>
                            <h6><Stop className="ar" />Employee handbook.</h6>
                            <h6><Stop className="ar" />Employee knowledge base.</h6>
                            <h6><Stop className="ar" />Employee self-service portal.</h6>
                            <h6><Stop className="ar" />Corporate policies and procedures.</h6>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Talent  management</h5>
                            </div>
                            <h6 className="mt-3"><Stop className="ar" />Recruiting.</h6>
                            <h6><Stop className="ar" />Workforce planning.</h6>
                            <h6><Stop className="ar" />Internal job transfer.</h6>
                            <h6><Stop className="ar" />Performance management.</h6>
                            <h6><Stop className="ar" />Competency management.</h6>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Labor management</h5>
                            </div>
                            <h6 className="mt-3"><Stop className="ar" />Worktime tracking.</h6>
                            <h6><Stop className="ar" />Absence management.</h6>
                            <h6><Stop className="ar" />Employee scheduling.</h6>
                            <h6><Stop className="ar" />Tasks and activities tracking.</h6>
                            <h6><Stop className="ar" />Time and attendance evaluation.</h6>
                        </div>


                    </div>
                </div>
            </div>

        </>
    )
}
export default HumanResources;