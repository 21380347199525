import React from "react";
import Header from "./Header";
// import { Stop, NavigateNext } from "@mui/icons-material";
// import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const HealthCare = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/healthcare" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,HealthCare" />
                    <title> HealthCare | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4 m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b> HealthCare</b></h1>
                            <p>Healthcare IT services cover care delivery digitalization, IT modernization, cloud migration, IT support, etc. In healthcare, iCloudsoft offers full-cycle IT services for medical organizations to leverage tech-enabled healthcare without expanding an in-house IT team.</p>
                            <p>Health care, or healthcare, is the improvement of health via the prevention, diagnosis, treatment, amelioration or cure of disease, illness, injury, and other physical and mental impairments in people.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/helth-1.png")} alt="helth-1" className="img-fluid w-75  " />
                        </div>
                    </div>
                </div>


                <div className="company-box2  bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Healthcare IT Service</h2>
                    <div className="row g-4">
                        <div className="col-md-4 mb-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2 ">Healthcare IT support</h5>
                            </div>
                            <p className="p-3"> Proactive monitoring and evolution of healthcare IT infrastructure.
                                Performance management, enhancement, optimization, and evolution of medical applications.
                                Cybersecurity and compliance testing of IT infrastructure and its components, applications (security audit, compliance testing, vulnerability assessment, penetration testing).</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2 ">Managed IT services</h5>
                            </div>
                            <p className="p-3"> L1, L2, L3 help desk for healthcare IT applications and infrastructure.
                                Monitoring of hospital applications (e.g., EHR, HIE), networks, and IoMT infrastructures.
                                Healthcare IT infrastructure administration and maintenance.
                                Maintaining IT infrastructure security and compliance (e.g., with HIPAA).</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co  p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2 ">IT consulting and assessment</h5>
                            </div>
                            <p className="p-3"> Assessment of the current state of the IT environment.
                                Pinpointing vulnerabilities in the IT environment and unmet needs and designing tech solutions to cover them.
                                Feasibility study to define IT environment optimization steps, care digitization projects with optima</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2 ">IT modernization</h5>
                            </div>
                            <p className="p-3"> Healthcare applications integration (EHR, medical imaging software, practice management software, etc.).
                                Modernization of legacy healthcare applications (recoding, reengineering, containerization, etc.).
                                Consolidation of disparate healthcare organization and patient data.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2 " style={{fontSize:"17.7px"}}>Healthcare data analytics and performance management</h5>
                            </div>
                            <p className="p-3"> Patient outcomes analytics (to identify trends in patient outcomes, adjust treatment plans, etc.).
                                Analytics of patient-generated health data (e.g., glucose, body temperature, weight, blood pressure).
                                Performance evaluation and efficiency analysis of internal processes (e.g., equipment utilization, bed allocation).</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2 ">Digital health implementation</h5>
                            </div>
                            <p className="p-3">Software idea conceptualization based on healthcare organization’s objectives and needs analysis.
                                Feature list elaboration, software planning.
                                Development of digital health solutions
                                Software support and evolution.</p>
                        </div>


                    </div>
                </div>

            </div>
        </>
    )
}
export default HealthCare;