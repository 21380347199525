import React from "react";
import Header from "./Header";
import './Data-science.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';


const ArtificialIntelligence = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/artificialintelligence" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Artificial-Intelligence " />
                    <title> Artificial-Intelligence | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4 m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>Artificial Intelligence</b></h1>
                            <p>Artificial intelligence (AI) consulting helps companies automate and optimize business processes and make accurate forecasts with AI-powered software. iCloudsoft guides clients through AI implementation and delivers end-to-end AI solutions for non-IT enterprises and software product companies.</p>
                            <p >Artificial intelligence is a field of science concerned with building computers and machines that can reason, learn, and act in such a way that would normally require human intelligence or that involves data whose scale exceeds what humans can analyze.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/Ai-1.png")} alt="Ai-1" className="img-fluid w-50 " style={{ marginLeft: "3rem" }} />
                        </div>
                    </div>
                </div>


                <div className="company-box2 bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">AI Consulting Service Scope by iCloudsoft</h2>
                    <div className="row g-2">
                        <div className="col-md-3 mb-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Business  analysis</h5>
                            </div>
                            <p className="p-3">Analysis of the existing AI environment (if any).Definition and analysis of business needs to be pursued with an AI solution.Analysis of the existing data management practices established within the company, the currently used technologies and tools.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">AI solution quality assurance</h5>
                            </div>
                            <p className="p-3">End-to-end AI system testing.Evaluating the quality of AI solution output according to pre-defined KPIs.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Data preparation management</h5>
                            </div>
                            <p className="p-3">Exploratory analysis of the existing data sources (data type, volume, etc.).Data gathering and cleansing (standardizing, replacing missing and deviating variables, anonymizing sensitive data) for training (re-training) ML models.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">AI solution development</h5>
                            </div>
                            <p className="p-3">ML testing and evaluation.Deploying the ML models.ML models exploration and refinement.</p>
                        </div>


                    </div>
                </div>
            </div>

        </>
    )
}
export default ArtificialIntelligence;