import React, { useState } from "react";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import "./Contact.css";
import Footer from "./Footer"

const Contact = () => {

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/contact-us" />
                    <meta name="keywords" content="We are here to help, HEAD OFFICE, PUNE" />
                    <title> Contact Us | iCloudsoft Technology | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Contact">
                <div className="contact-box d-none d-sm-block">
                    <div className="banner-contact mt-lg-4">
                        <h2 className="mb-3"><b>Let's create something great together</b></h2>
                        <div className="info-text">
                            Questions, Queries, Bugs, Feedback,<br /> Reviews ----- We are here for it all. We look forward to hearing from you 😀
                        </div>
                    </div>
                </div>

                <div className="contact-box d-sm-none d-block">
                    <div className="banner-contact p-2">
                        <h2 className="mt-4 mb-4" style={{ lineHeight: "45px", fontSize: "36px" }} >Let's create something great together</h2>
                        <div className="info-text p-2">
                            Questions, Queries, Bugs, Feedback, Reviews ----- We are here for it all. We look forward to hearing from you 😀
                        </div>
                    </div>
                </div>

                <div className="Contact-box1 mt-lg-5 mb-lg-5">
                    <div className="banner-contact1 ">
                        <div className="icon">
                            <img src={require("../images/email.png")} alt="email" className="w-50" /></div>
                        <p>EMAIL</p>
                        <h6>info@icloudsoft.co.in</h6>
                    </div>
                    <div className="banner-contact1">
                        <div className="icon">
                            <img src={require("../images/addressing.png")} alt="addressing" className="w-50" /></div>
                        <p>ADDRESS</p>
                        <h6>Office No 601, 6th Floor, Lotus Building, Opp Joshi Railway Museum, Kothrud, Pune 411038
                        </h6>
                    </div>
                    <div className="banner-contact1">
                        <div className="icon">
                            <img src={require("../images/phone.png")} alt="phone" className="w-50" /></div>
                        <p>PHONE NUMBER</p>
                        <h6>+91 9527468898</h6>
                        <h6>+91 8766779267</h6>
                    </div>

                </div>

                <section>
                    <div className="container d-none d-sm-block">
                        <div className="row">
                            <div className="col-lg-6">
                                <form action="" class="needs-validation" novalidate>
                                    <div class="row">
                                        <h1 class=" text-center mt-3 p-3" ><b>Talk with our team </b></h1>
                                        <div class="col-xl-12">
                                            <label For="fname ">Full Name</label>
                                            <input type="text" name="" id="fname" class="form-control" required />
                                            <div class="invalid-feedback">
                                                Please Enter Your Full Name
                                            </div>
                                        </div>

                                        <div class="col-xl-6">
                                            <label For="fname">Number</label>
                                            <input type="number" name="" id="number" class="form-control" required />
                                            <div class="invalid-feedback">
                                                Please Enter Your number
                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                            <label For="email">Email</label>
                                            <input type="email" name="" id="email" class="form-control" required />
                                            <div class="invalid-feedback">
                                                Please Enter Your Email
                                            </div>
                                        </div>
                                        <div className="contact-f1"> <textarea type="Message" className="input-f textarea-field" placeholder="Your message" /></div>
                                        <input type="submit" value="Submit" class="btn btn-primary" />
                                    </div>
                                </form>

                            </div>
                            <div className="col-lg-6 p-4">
                                <img src={require("../images/contact2.png")} alt="contact2" className="w-100" />
                            </div>
                        </div>
                    </div>

                    <div className="container d-sm-none d-block">
                        <div className="row g-4">
                            <div className="">
                                <form action="" class="needs-validation " novalidate>
                                    <div class="row">
                                        <h1 class=" text-center mt-3 mb-4" ><b>Talk with our team </b></h1>
                                        <div class="col-xl-12 mb-3">
                                            <label For="fname " className="mb-2">Full Name</label>
                                            <input type="text" name="" id="fname" class="form-control" required />
                                            <div class="invalid-feedback">
                                                Please Enter Your Full Name
                                            </div>
                                        </div>

                                        <div class="col-xl-12 mb-3">
                                            <label For="fname" className="mb-2">Number</label>
                                            <input type="number" name="" id="number" class="form-control" required />
                                            <div class="invalid-feedback">
                                                Please Enter Your number
                                            </div>
                                        </div>

                                        <div class="col-xl-12 mb-3">
                                            <label For="email" className="mb-2">Email</label>
                                            <input type="email" name="" id="email" class="form-control" required />
                                            <div class="invalid-feedback">
                                                Please Enter Your Email
                                            </div>
                                        </div>

                                        <div className="contact-f1">
                                            <textarea type="Message" className="input-f textarea-field" placeholder="Your message" />
                                        </div>
                                        <input type="submit" value="Submit" class="btn btn-primary" />
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-6">
                                <img src={require("../images/contact2.png")} alt="contact2" className="w-100" />
                            </div>
                        </div>
                    </div>
                </section>

                <div className="home-box2 bg-light d-none d-sm-block">
                    <h2 className="pt-4 text-center"> OUR OFFICES</h2>
                    <div className="home-block">
                        <div className="hb6">
                            <h3 className="mb-3">Kothrud, Pune </h3>
                            <p>iCloudsoft Technology
                                Office No 601, 6th Floor,
                                Lotus Building, Opp Joshi Railway
                                Museum, Kothrud, Pune 411038</p>
                        </div>
                        <div className="hb6">
                            <h3 className="mb-3">Bavdhan, Pune</h3>
                            <p>iCloudsoft Technology , Plot No 70, Pashan Link Road , Bavdhan, Pune, Maharashtra 411021</p>
                        </div>
                        <div className="hb6">
                            <h3 className="mb-3">Dharashiv</h3>
                            <p>iCloudsoft Technology, Near Z.P.
                                School, Khandeshwarswadi,
                                Paranda , Dharashiv,413505
                            </p>
                        </div>


                    </div>
                </div>

                <div className="home-box2 bg-light d-sm-none d-block">
                    <h2 className="pt-3 text-center pb-3"> OUR OFFICES</h2>
                    <div className="">
                        <div className="hb6">
                            <h3 className="mb-3">Kothrud, Pune </h3>
                            <p>iCloudsoft Technology
                                Office No 601, 6th Floor,
                                Lotus Building, Opp Joshi Railway
                                Museum, Kothrud, Pune 411038</p>
                        </div>
                        <div className="hb6">
                            <h3 className="mb-3">Bavdhan, Pune</h3>
                            <p>iCloudsoft Technology , Plot No 70, Pashan Link Road , Bavdhan, Pune, Maharashtra 411021</p>
                        </div>
                        <div className="hb6">
                            <h3 className="mb-3">Dharashiv</h3>
                            <p>iCloudsoft Technology, Near Z.P.
                                School ,Khandeshwarswadi,
                                Paranda , Dharashiv,413505
                            </p>
                        </div>


                    </div>
                </div>
            </div>

        </>
    )
}
export default Contact;