import React from "react";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const PowerApps = () => {


  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoft.co.in/power-apps" />
          <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,PowerApps " />
          <title> PowerApps | Web Development Company In Pune  </title>
        </Helmet>
      </HelmetProvider>

      <Header />

      <div className="Company bg-light">
        <div className="company-boxA">
          <div className="row p-lg-4 g-4 m-2 ">
            <div className="col-md-6 ps-lg-5 mt-5">
              <h1 className="mb-3"><b>Power Apps</b></h1>
              <p>Microsoft Power Apps is a suite of cloud-based software tools that allows users to create custom business applications without the need for extensive coding or development experience. With Power Apps, users can build custom applications that can be used across different devices, including desktops, mobile phones, and tablets.</p>
              <p>Power Apps provides a drag-and-drop interface for creating custom business applications using pre-built templates, connectors, and data sources. Users can connect to a wide range of data sources, including Microsoft Dynamics 365, Microsoft Excel, SharePoint, and others, to create powerful and data-driven applications.</p>
            </div>
            <div className="col-md-6">
              <img src={require("../images/Power.png")} alt="" className="img-fluid w-50 " style={{ marginLeft: "3rem" }} />
            </div>
          </div>
        </div>

        <div className="company-box2 bg-secondary-subtle m-3">
          <h2 className="text-center p-3 mb-3">Our Microsoft Service</h2>
          <div className="row">
            <div className="col-md-3 mb-3">
              <div className="co  d-flex">
                <h5 className="ms-3 mt-3 mb-3">App creation</h5>
              </div>
              <p className="p-3">Power Apps capabilities by creating custom code components for canvas and model-driven apps to enhance user experience in terms of forms, views, dashboards, and more.</p>
            </div>
            <div className="col-md-3">
              <div className="co  d-flex">
                <h5 className="ms-3 mt-3 mb-3">App management</h5>
              </div>
              <p className="p-3">App monitoring. Checking apps during building or published apps for issues like bugs, slow performance, and more.</p>
            </div>
            <div className="col-md-3">
              <div className="co  d-flex">
                <h5 className="ms-3 mt-3 mb-3">Data & integrations management</h5>
              </div>
              <p className="p-3">custom connectors to integrate business applications created in Power Apps with Microsoft products (SharePoint, Office 365, OneDrive, Excel Online, Dynamics 365), third-party platforms</p>
            </div>
            <div className="col-md-3">
              <div className="co d-flex">
                <h5 className="ms-3 mt-3 mb-3">Data security & compliance</h5>
              </div>
              <p className="p-3">Multi-factor authentication. Several data protection layers that can consist, for example, of a password and a code from an email or a text message.</p>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
export default PowerApps;