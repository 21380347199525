import React from "react";
// import { NavigateNext } from "@mui/icons-material";
import Header from "./Header";
// import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const Node = () => {

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoft.co.in/node" />
          <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,Node" />
          <title> Node | Web Development Company In Pune  </title>
        </Helmet>
      </HelmetProvider>

      <Header />

      <div className="Company bg-light">
        <div className="company-boxA">
          <div className="row p-lg-4 g-4 m-2 ">
            <div className="col-md-6 ps-lg-5 mt-5">
              <h1 className="mb-3"><b>Node Js</b></h1>
              <p>Node.js is a JavaScript runtime used for server-side development of software that can efficiently operate in a real-time, data-intensive environment. iCloudsoft developers use Node.js to deliver fast, including healthcare, retail, and financial services.</p>
              <p>Node.js is an open-source and cross-platform JavaScript runtime environment. It is a popular tool for almost any kind of project!Node.js runs the V8 JavaScript engine, the core of Google Chrome, outside of the browser. This allows Node.js to be very performant.</p>
            </div>
            <div className="col-md-6">
              <img src={require("../images/node.png")} alt="node" className="img-fluid w-50" style={{ marginLeft: "3rem" }} />
            </div>
          </div>
        </div>

        <div className="container p-4 bg-secondary-subtle text-center d-none d-sm-block">
          <h2 className="mb-4">What We Develop with Node.js</h2>
          <div className=" testing ">
            <div className="">
              <img src={require("../images/Web-Mobile-App.png")} alt="Web-Mobile-App" className="w-25 mb-3" />
              <h5>Web & Mobile Apps</h5>
            </div>
            <div className="">
              <img src={require("../images/Web-Server.png")} alt="Web-Server" className="w-25 mb-3" />
              <h5 className="mb-3">Web Server</h5>
            </div>
            <div className="">
              <img src={require("../images/custom-api.png")} alt="" className="w-25 mb-3" />
              <h5>Custom APIs</h5>
            </div>
          </div>
        </div>

        <div className="container p-4 bg-secondary-subtle text-center d-sm-none d-block">
          <h2 className="mb-5 mt-5" style={{lineHeight:"35px"}}>What We Develop with Node.js</h2>
          <div className="">
            <div className="mb-5">
              <img src={require("../images/Web-Mobile-App.png")} alt="Web-Mobile-App" className="w-25 mb-3" />
              <h5>Web & Mobile Apps</h5>
            </div>
            <div className="mb-5">
              <img src={require("../images/Web-Server.png")} alt="Web-Server" className="w-25 mb-3" />
              <h5 className="mb-3">Web Server</h5>
            </div>
            <div className="mb-5">
              <img src={require("../images/custom-api.png")} alt="" className="w-25 mb-3" />
              <h5>Custom APIs</h5>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
export default Node;