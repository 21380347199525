import React from "react";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const PowerBI = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/power-bi" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,PowerBI " />
                    <title> PowerBI | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>


            <Header />

            <div className="Company bg-light">
                <div className="company-boxA ">
                    <div className="row p-lg-4 g-4 m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>Microsoft Power BI</b></h1>
                            <p>Power BI consulting services involve assistance with implementing and customizing a Power BI platform, as well as promptly upgrading the existing Power BI solution. As a result, businesses can translate their raw data into accurate analytics insights with rich visuals and compelling dashboards.</p>
                            <p>iCloudsoft helps companies implement, improve, and support Power BI solutions to allow them to deliver timely and quality analytics insights across the company. We also assist with making the existing Power BI platform stay relevant to a company’s changing analytics needs.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/powerbi.png")} alt="" className="img-fluid w-50 mt-5 " style={{ marginLeft: "3rem" }} />
                        </div>
                    </div>
                </div>

                <div className="company-box2  bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Power Apps Use Cases</h2>
                    <div className="row g-2">
                        <div className="col-md-3 mb-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-3 mb-3">Power BI Implementation Consulting</h5>
                            </div>
                            <p className="p-3">We help you find out whether Power BI is capable of solving your analytics problems and get started with Power BI by performing:Power BI feasibility check.Business needs elicitation and analysis.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-3 mb-3" style={{fontSize:"19px"}}>Power BI Consulting + Implementation</h5>
                            </div>
                            <p className="p-3">In the shortest time possible, we deliver Power BI solutions with:Data quality management.Power BI feasibility assessment.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-3 mb-3">Power BI Improvement Consulting</h5>
                            </div>
                            <p className="p-3">If your current Power BI solution doesn’t allow meeting your analytics objectives, our Power BI consultants will:Analyze your business needs.Examine and assess the existing solution.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-3 mb-3">Power BI Consulting + Support</h5>
                            </div>
                            <p className="p-3">To ensure stable work and continuous evolution of your Power BI solution, we provide:Daily Power BI administration.Data management procedures</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default PowerBI;