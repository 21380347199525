import React from "react";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import { Stop, NavigateNext } from "@mui/icons-material";
// import { NavLink } from "react-router-dom";

const FinancialManagement = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/financialmanagement" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,FinancialManagement" />
                    <title> FinancialManagement | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company  bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4  m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>Financial Management</b></h1>
                            <p>iCloudsoft provides companies in industries with advisory and practical assistance on the design and implementation of reliable financial management software to improve their corporate finance processes.</p>
                            <p>Digital financial management consulting services aim to help companies drive improvements across corporate financial management processes and target ambitious business goals with the help of robust finance software and advanced technologies, such as cloud, AI and ML, big data, blockchain.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/finance-m.png")} alt="finance-m" className="img-fluid w-75  " />
                        </div>
                    </div>
                </div>

                <div className="company-box2  bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Financial Management Solutions</h2>
                    <div className="row g-4">
                        <div className="col-md-4 mb-3">
                            <div className="co  p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Financial process automation software</h5>
                            </div>
                            <p className="p-3">Eliminating manual efforts across tasks involved in bookkeeping, invoicing, payroll accounting, financial planning and analysis, revenue management, tax management, investment and financial risk management, and more.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co  p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Automated accounting software</h5>
                            </div>
                            <p className="p-3">Increasing accuracy across multi-entity bookkeeping, invoicing, AP and AR, expense management, multi-location inventory accounting, financial data reconciliation, country-specific financial reporting, and more.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co  p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Enterprise accounting software</h5>
                            </div>
                            <p className="p-3">General ledger capabilities, the management of payables, receivables, taxes and payroll, asset and inventory accounting, automated invoicing and reconciliation, and more.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co  p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Financial modeling software</h5>
                            </div>
                            <p className="p-3">Streamlining financial planning and analysis, improving decision-making on strategic activities.Creating and managing financial models</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co  p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Revenue management software</h5>
                            </div>
                            <p className="p-3">Real-time revenue tracking, advanced revenue analysis, automated revenue recognition according to the up-to-date accounting standards.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Invoice processing automation software</h5>
                            </div>
                            <p className="p-3">End-to-end invoice processing – from invoice data capture and validation to invoice routing for payment and posting to the general ledger.</p>
                        </div>


                    </div>
                </div>
            </div>

        </>
    )
}
export default FinancialManagement;