// import { NavigateNext } from "@mui/icons-material";
import React from "react";
// import { NavLink } from "react-router-dom";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const OperationsManagement = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/operations-management" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,OperationsManagement" />
                    <title> OperationsManagement | Web Development Company In Pune | iCloudsoft Technology </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row g-4 p-lg-4  m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b> Operations Management</b></h1>
                            <p>Operations management (OM) is the administration of business practices to create the highest level of efficiency possible within an organization. It is concerned with converting materials and labor into goods and services as efficiently as possible to maximize the profit of an organization.</p>
                            <p>Simply put, operations management is the administration of business activities to accomplish goals, achieve higher productivity, and maximize profitability.Operations management is an area of management concerned with designing and controlling the process of production and redesigning business operations in the production of goods or services.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/operation-1.png")} alt="" className="img-fluid w-100 mt-5 " />
                        </div>
                    </div>
                </div>


                <div className="company-box2 bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3"> Service </h2>
                    <div className="row g-4">
                        <div className="col-md-6">
                            <div className="co p-2 d-flex" >
                                <h4 className="ms-3 mt-2 mb-2">Operations management software consulting</h4>
                            </div>
                            <p className="p-3">Analyze your business needs and related operations workflows (by using fitting business analysis methodologies and interviewing your subject-matter experts).Research how operations are currently supported by technology: review applications in use, their architecture, functionality, and integrations between them to identify gaps.</p>
                        </div>
                        <div className="col-md-6">
                            <div className="co p-2 d-flex" >
                                <h4 className="ms-3 mt-2 mb-2">Operations management software implementation</h4>
                            </div>
                            <p className="p-3">Develop a Proof-of-Concept (optional).Implement a custom operations management solution OR customize off-the-shelf software.Integrate the solution or its components with each other and with your existing systems.Integrate and install hardware. Run end-to-end system testing .</p>

                        </div>

                    </div>
                </div>
            </div>


        </>
    )
}
export default OperationsManagement;