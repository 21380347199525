// import { NavigateNext } from "@mui/icons-material";
import React from "react";
// import { NavLink } from "react-router-dom";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const WebDevelopment = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/web-development" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,WebDevelopment " />
                    <title> WebDevelopment | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4  m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>Web Development</b></h1>
                            <p>Web development is the work involved in developing a website for the Internet (World Wide Web) or an intranet (a private network). Web development can range from developing a simple single static page of plain text to complex web applications, electronic businesses, and social network services.</p>
                            <p>iCloudsoft is website development company offering custom web application development services to startups, SMBs, and large enterprises.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/website1.png")} alt="website1" className="img-fluid w-50" style={{ marginLeft: "4rem" }} />
                        </div>
                    </div>
                </div>
                <div className="company-box2 bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Area Of Expertise</h2>
                    <div className="row g-2">
                        <div className="col-md-4 mb-3">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Enterprise Mobility</h5>
                            </div>
                            <p className="p-3">Our mobile app development services are capable to make startups experience the unicorn club perks and turn enterprise companies from conventional to extraordinary.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">E-Commerce</h5>
                            </div>
                            <p className="p-3">We create memorable websites that sell. With our eCommerce expertise we work on every element of your website that perfectly aligns with your brand voice and volumes.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Microsoft</h5>
                            </div>
                            <p className="p-3">In Microsoft and core .NET knowledge we can frame a growth standard for your business with very less technical investment.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">UX/UI Design</h5>
                            </div>
                            <p className="p-3">We bring power to our design with a human-centered design process by not just keeping the users in mind but even by involving the users in the process.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Frontend</h5>
                            </div>
                            <p className="p-3">Any Frontend Development Company can scrape together a few lines of code. We take you far beyond this. By maximizing the user experience, by developing design pieces into tangible pieces</p>
                        </div>
                        <div className="col-md-4">
                            <div className="co p-2 d-flex">
                                <h5 className="ms-3 mt-2 mb-2">Trending</h5>
                            </div>
                            <p className="p-3">iCloudsoft is a front runner name in the field of Augmented Reality (AR) app development and virtual reality (VR) development.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default WebDevelopment;