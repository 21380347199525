import React from "react";
import Header from "./Header";
import { Stop } from "@mui/icons-material";
// import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const ProjectManagement = () => {

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="canonical" href="https://www.icloudsoft.co.in/project-management" />
          <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,ProjectManagement" />
          <title> ProjectManagement | Web Development Company In Pune  </title>
        </Helmet>
      </HelmetProvider>

      <Header />

      <div className="Company bg-light">
        <div className="company-boxA">
          <div className="row p-lg-4 g-4  m-2 ">
            <div className="col-md-6 ps-lg-5 mt-5">
              <h1 className="mb-3"><b>Project Management</b></h1>
              <p>Project management is the application of processes, methods, skills, knowledge and experience to achieve specific project objectives according to the project acceptance criteria within agreed parameters. Project management has final deliverables that are constrained to a finite timescale and budget</p>
              <p>Project management is the process of leading the work of a team to achieve all project goals within the given constraints. This information is usually described in project documentation, created at the beginning of the development process.</p>
            </div>
            <div className="col-md-6">
              <img src={require("../images/project-1.png")} alt="" className="img-fluid w-50 " style={{ marginLeft: "3rem" }} />
            </div>
          </div>
        </div>

        <div className="company-box2  bg-secondary-subtle m-3">
          <h2 className="text-center p-3 mb-3">Project Management Solutions</h2>
          <div className="row g-4">
            <div className="col-md-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">Project planning</h5>
              </div>
              <h6 className="mt-3"><Stop className="ar" />Project initiation and approval workflows.</h6>
              <h6><Stop className="ar" />Setting project goals, milestones, KPIs, and deadlines.</h6>
              <h6><Stop className="ar" />Planning and scheduling project activities.</h6>
              <h6 className="pb-3"><Stop className="ar" />Checking the availability and costs of the required human, financial, and physical resources.</h6>
            </div>
            <div className="col-md-3">
              <div className="co  p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">Project monitoring</h5>
              </div>
              <h6 className="mt-3"><Stop className="ar" />Tracking the completion status of each project task and consumption of project resources.</h6>
              <h6><Stop className="ar" />Monitoring the overall project progress.</h6>
              <h6><Stop className="ar" />Updating project tasks in real time.</h6>
              <h6><Stop className="ar" />Automated notifications about task updates to corresponding team members.</h6>                        </div>
            <div className="col-md-3">
              <div className="co  p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">Human resource management</h5>
              </div>
              <h6 className="mt-3"><Stop className="ar" />A project team’s lineup planning and assigning employees to the project roles.</h6>
              <h6><Stop className="ar" />Scheduling high-demand professionals to ensure their availability for the upcoming projects.</h6>
              <h6><Stop className="ar" />Monitoring the project team’s workload and performance.</h6>
              <h6 ><Stop className="ar" />Checking the progress of each team member.</h6>                        </div>
            <div className="col-md-3">
              <div className="co p-2 d-flex">
                <h5 className="ms-3 mt-2 mb-2">Financial resource management</h5>
              </div>
              <h6 className="mt-3"><Stop className="ar" />Project budget estimation and planning with regard to the planned labor and non-labor costs.</h6>
              <h6><Stop className="ar" />Requesting/scheduling the needed financial resources and project budget approval.</h6>
              <h6 className="pb-4"><Stop className="ar" />Setting budget groups for the project and modelling scenarios of budget allocation into them.</h6>                        </div>


          </div>
        </div>
      </div>

    </>
  )
}
export default ProjectManagement;