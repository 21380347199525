import React from "react";
import Header from "./Header";
import "./Blog.css";
import { NavLink } from "react-router-dom";

const Blog = () => {

    return (

        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../images/bl1.jpg")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", borderBottom: "2px solid white" }}>
                    <div>
                        <Header />
                    </div>
                    <div className="container " style={{ height: "95vh" }}>
                        <div className="row  d-flex justify-content-center align-itmes-center  text-white " >
                        </div>
                    </div>
                </div >

            </section>

            <section>
                <div className="container-fluid">
                    <div className="container p-lg-5">
                        <div className="row d-flex justify-content-center ">
                            <div className='pt-2 text-center widthRESOPNSIVE  p-lg-4'  >
                                <NavLink to="https://www.instagram.com/icloud_soft/" aria-label="facebook" rel="noopener" target="_blank" ><img className='' style={{ height: "4.1rem" }} src={require("../images/insta1.png")} alt="" /></NavLink>
                                <h4 className="SolutionDropDownFontFamily pb-2 pt-2" style={{ color: "#BA1A28", fontWeight: "800", fontSize: "30px" }} > INSTAGRAM </h4>
                                <p className="SolutionDropDownFontFamily" style={{ fontSize: "18px", color: "#143d50" }}  > First, have a definite, clear practical ideal; a goal, an objective. Second, have the necessary means to achieve your ends; wisdom, money, materials, and methods. Third, adjust all your means to that end...</p>
                                <div className="">
                                    <h6 className="" style={{ color: "#BA1A28", fontStyle: "italic" }} > Greek Aristoteles </h6>
                                    <div className="d-flex justify-content-center" >
                                        <div className="" style={{ borderTop: "1px solid #BA1A28", width: "50%" }} >
                                            <p className="p-1" style={{ fontSize: "10px", color: "#143d50", fontStyle: "italic" }} > Greek Aristoteles , Greek philosopher and scientist </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center mb-2">
                            <div className=' text-center widthRESOPNSIVE p-lg-4' style={{ backgroundColor: "#FBF4EC" }} >
                                <p className="SolutionDropDownFontFamily pt-3 pb-3" style={{ fontSize: "16px", color: "#BA1A28", }}  > Integrating an Instagram page into a website isn’t just about showcasing beautiful photos — it’s a strategic move that can lead to impressive business gains. With a bit of creativity and a hint of audacity, anyone can implement and enjoy the benefits of this unique initiative. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" >
                    <div className="container pt-4 p-lg-5">
                        <div className="row ">
                            <div className="col-lg-4">
                                <div>
                                    <img className='w-100 ' style={{ height: "100%" }} src={require("../images/4672CAR.png")} alt="" />
                                    <div className="pt-2">
                                        <div className="pt-2 pb-2">
                                            <button className="" style={{ backgroundColor: "#fff", border: "1px solid black", borderRadius: "5px 5px 5px 5px", height: "30px" }} > <b className="p-2" style={{ fontWeight: "400", fontSize: "13px" }} > Book Reviews </b> </button>
                                        </div>
                                        <h6 className="pt-1" style={{ color: "#BA1A28", fontWeight: "600", fontSize: "20px" }} > Unveiling the Magic of Websites </h6>
                                        <p className="pt-2" style={{ color: "#143d50", fontSize: "15px" }} > To quote Bill Gates, "If your business is not on the Internet, then your business will be out of business." So, are you ready to unveil the magic of websites and give your business the digital boost it needs? </p>
                                        <p className="" style={{ color: "#143d50", fontSize: "16px" }}>Unveiling the Magic of Websites is a game changer. Dive into reasons why every business owner needs one from creating an online presence to having a global footprint.</p>
                                        <div className="pt-2 pb-2">
                                            <NavLink to='/blog1'><button className="" style={{ backgroundColor: "red", border: "1px solid red", height: "35px", color: "#fff" }} > <b className="p-2" style={{ fontWeight: "400", fontSize: "13px" }} > READ MORE </b> </button></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div>
                                    <img className='w-100 ' style={{ height: "100%" }} src={require("../images/OldLady&Car.png")} alt="" />
                                    <div className="pt-2">
                                        <div className="pt-2 pb-2">
                                            <button className="" style={{ backgroundColor: "#fff", border: "1px solid black", borderRadius: "5px 5px 5px 5px", height: "30px" }} > <b className="p-2" style={{ fontWeight: "400", fontSize: "13px" }} > Book Reviews </b> </button>
                                        </div>
                                        <h6 className="pt-1" style={{ color: "#BA1A28", fontWeight: "600", fontSize: "20px" }} > Mastering the Virtual Market </h6>
                                        <p className="pt-2" style={{ color: "#143d50", fontSize: "16px" }} >Do you have an e-commerce website, and are you looking to master the virtual market? As brick-and-mortar stores face increasing challenges, many business owners are turning to the online world to secure their future success. </p>
                                        <p className="" style={{ color: "#143d50", fontSize: "16px" }}>This article will provide crucial e-commerce website tips for you to flourish in the virtual marketplace.</p>
                                        <div className="pt-2 pb-2">
                                            <NavLink to='/blog1'><button className="" style={{ backgroundColor: "red", border: "1px solid red", height: "35px", color: "#fff" }} > <b className="p-2" style={{ fontWeight: "400", fontSize: "13px" }} > READ MORE </b> </button></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div>
                                    <img className='w-100 ' style={{ height: "100%" }} src={require("../images/b2.jpg")} alt="" />
                                    <div className="pt-2">
                                        <div className="pt-2 pb-2">
                                            <button className="" style={{ backgroundColor: "#fff", border: "1px solid black", borderRadius: "5px 5px 5px 5px", height: "30px" }} > <b className="p-2" style={{ fontWeight: "400", fontSize: "13px" }} > Book Reviews </b> </button>
                                        </div>
                                        <h6 className="pt-1" style={{ color: "#BA1A28", fontWeight: "600", fontSize: "20px" }} >WHAT I'AM READING #7</h6>
                                        <p className="pt-2" style={{ color: "#143d50", fontSize: "15px" }} >Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellat debitis fugit aspernatur temporibus cupiditate soluta, facilis ad! Excepturi rem tempore pariatur! Explicabo saepe laboriosam fugit! Dolorem voluptatum fugit quam itaque.</p>
                                        <p className="" style={{ color: "#143d50", fontSize: "15px" }}> Unveiling the Magic of Websites is a game changer. Dive into reasons why every business owner needs one from creating an online presence to having a global footprint.</p>
                                        <div className="pt-2 pb-2">
                                            <NavLink to='/blog1'><button className="" style={{ backgroundColor: "red", border: "1px solid red", height: "35px", color: "#fff" }} > <b className="p-2" style={{ fontWeight: "400", fontSize: "13px" }} > READ MORE </b> </button></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <section>
                                <div className="container-fluid">
                                    <div className="container p-lg-5">
                                        <div className="row d-flex justify-content-center ">
                                            <div className=' text-center widthRESOPNSIVE p-lg-4'>
                                                <NavLink to="https://www.facebook.com/icloudsofttechnology" aria-label="facebook" rel="noopener" target="_blank" ><img className='' style={{ height: "5.1rem" }} src={require("../images/fb.png")} alt="" /></NavLink>
                                                <h4 className="SolutionDropDownFontFamily pb-2 pt-1" style={{ color: "#BA1A28", fontWeight: "800", fontSize: "30px", letterSpacing: "3px" }} > FACEBOOK </h4>
                                                <p className="SolutionDropDownFontFamily" style={{ fontSize: "18px", color: "#143d50" }}  > You've done it before and you can do it now. See the positive possibilities. Redirect the substantial energy of your frustration and turn it into positive, effective, unstoppable determination...</p>
                                                <div className="">
                                                    <h6 className="" style={{ color: "#BA1A28", fontStyle: "italic" }} > Ralph Marston </h6>
                                                    <div className="d-flex justify-content-center" >
                                                        <div className="" style={{ borderTop: "1px solid #BA1A28", width: "50%" }} >
                                                            <p className="p-1" style={{ fontSize: "10px", color: "#143d50", fontStyle: "italic" }} > Ralph Marston, Formar Football Player </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row d-flex justify-content-center ">
                                            <div className=' text-center widthRESOPNSIVE p-4' style={{ backgroundColor: "#FBF4EC" }} >
                                                <p className="SolutionDropDownFontFamily pt-3 pb-3" style={{ fontSize: "15px", color: "#BA1A28", }}  > A Facebook page in a website refers to the integration of your Facebook profile or business page into your website. It offers an interactive avenue where you can engage with your viewers directly from your site. It truly expands the reach of your business or brand. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section>
                                <div className="container-fluid">
                                    <div className="container pt-4 pb-4 p-lg-5">
                                        <div className="row ">
                                            <div className="col-lg-4">
                                                <div>
                                                    <img className='w-100 ' style={{ height: "100%" }} src={require("../images/4672CAR.png")} alt="" />
                                                    <div className="pt-2">
                                                        <div className="pt-2 pb-2">
                                                            <button className="" style={{ backgroundColor: "#fff", border: "1px solid black", borderRadius: "5px 5px 5px 5px", height: "30px" }} > <b className="p-2" style={{ fontWeight: "400", fontSize: "13px" }} > Book Reviews </b> </button>
                                                        </div>
                                                        <h6 className="pt-1" style={{ color: "#BA1A28", fontWeight: "600", fontSize: "20px" }} >WHAT I'AM READING #8</h6>
                                                        <p className="pt-2" style={{ color: "#143d50", fontSize: "15px" }} >Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellat debitis fugit aspernatur temporibus cupiditate soluta, facilis ad! Excepturi rem tempore pariatur! Explicabo saepe laboriosam fugit! Dolorem voluptatum fugit quam itaque.</p>
                                                        <p className="" style={{ color: "#143d50", fontSize: "15px" }}>Unveiling the Magic of Websites is a game changer. Dive into reasons why every business owner needs one from creating an online presence to having a global footprint.</p>
                                                        <div className="pt-2 pb-2">
                                                            <NavLink to='/blog1'><button className="" style={{ backgroundColor: "red", border: "1px solid red", height: "35px", color: "#fff" }} > <b className="p-2" style={{ fontWeight: "400", fontSize: "13px" }} > READ MORE </b> </button></NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div>
                                                    <img className='w-100 ' style={{ height: "100%" }} src={require("../images/OldLady&Car.png")} alt="" />
                                                    <div className="pt-2">
                                                        <div className="pt-2 pb-2">
                                                            <button className="" style={{ backgroundColor: "#fff", border: "1px solid black", borderRadius: "5px 5px 5px 5px", height: "30px" }} > <b className="p-2" style={{ fontWeight: "400", fontSize: "13px" }} > Book Reviews </b> </button>
                                                        </div>
                                                        <h6 className="pt-1" style={{ color: "#BA1A28", fontWeight: "600", fontSize: "20px" }} > CONNECTING </h6>
                                                        <p className="pt-2" style={{ color: "#143d50", fontSize: "15px" }} >Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellat debitis fugit aspernatur temporibus cupiditate soluta, facilis ad! Excepturi rem tempore pariatur! Explicabo saepe laboriosam fugit! Dolorem voluptatum fugit quam itaque.</p>
                                                        <p className="" style={{ color: "#143d50", fontSize: "15px" }}> Unveiling the Magic of Websites is a game changer. Dive into reasons why every business owner needs one from creating an online presence to having a global footprint.</p>
                                                        <div className="pt-2 pb-2">
                                                            <NavLink to='/blog1'><button className="" style={{ backgroundColor: "red", border: "1px solid red", height: "35px", color: "#fff" }} > <b className="p-2" style={{ fontWeight: "400", fontSize: "13px" }} > READ MORE </b> </button></NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div>
                                                    <img className='w-100 ' style={{ height: "100%" }} src={require("../images/14CAR.png")} alt="" />
                                                    <div className="pt-2">
                                                        <div className="pt-2 pb-2">
                                                            <button className="" style={{ backgroundColor: "#fff", border: "1px solid black", borderRadius: "5px 5px 5px 5px", height: "30px" }} > <b className="p-2" style={{ fontWeight: "400", fontSize: "13px" }} > Book Reviews </b> </button>
                                                        </div>
                                                        <h6 className="pt-1" style={{ color: "#BA1A28", fontWeight: "600", fontSize: "20px" }} >WHAT I'AM READING #7</h6>
                                                        <p className="pt-2" style={{ color: "#143d50", fontSize: "15px" }} >Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellat debitis fugit aspernatur temporibus cupiditate soluta, facilis ad! Excepturi rem tempore pariatur! Explicabo saepe laboriosam fugit! Dolorem voluptatum fugit quam itaque.</p>
                                                        <p className="" style={{ color: "#143d50", fontSize: "15px" }}>Unveiling the Magic of Websites is a game changer. Dive into reasons why every business owner needs one from creating an online presence to having a global footprint.</p>
                                                        <div className="pt-2 pb-2">
                                                            <NavLink to='/blog1'><button className="" style={{ backgroundColor: "red", border: "1px solid red", height: "35px", color: "#fff" }} > <b className="p-2" style={{ fontWeight: "400", fontSize: "13px" }} > READ MORE </b> </button></NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-lg-8 p-0">
                            <div className="HoverEffect h-100 position-relative" style={{ borderBottom: "2px solid white", borderRight: "1px solid white" }}>
                                <img className='w-100' style={{ height: "100%" }} src={require("../images/b2.jpg")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>Creative joy <br></br>Luxury redefined</h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0 gy-0 " >

                            <div className='h-50 HoverEffectVIDEO position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100' style={{ height: "100%" }} src={require("../images/Lady2.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px" }}><b> BMW explained </b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> Learn everything about BMW here </p></h5>
                                </div>
                            </div>

                            <div className='h-50 HoverEffect position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../images/LadyCar.png")} alt="" />
                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "16px" }}><b> Car photography </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>Anna Heupel: always moving </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../images/videoCAR10.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}> Individual mobility <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>When you can’t stop sheer driving pleasure </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffectVIDEO h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../images/Home_Bg.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>OBSESSED X BMW <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>Ruba Abu-Nimah and Tyshawn jones</p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                            <div className="HoverEffectVIDEO h-100 position-relative">
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../images/videoCAR6.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>

                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}> BMW Lifestyle  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>Joshua Vides to dress: understated cool </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-5">
                    <div className="row ">
                        <div className="col-lg-4 p-0 gy-0 " >
                            <div className='h-50 HoverEffectVIDEO position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../images/Lady&Car.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px" }}><b>Halloween comic</b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>Out of the shadows and into the light </p></h5>
                                </div>
                            </div>

                            <div className='h-50 HoverEffect position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../images/b4.jpg")} alt="" />
                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "16px" }}><b>friendly enjoyment </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>Time for sustainable luxury at Le Jardin de Berne</p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 p-0">
                            <div className="HoverEffect h-100 position-relative" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../images/Lady2.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>JOY is three letters  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> Discover the beauty of simple things that bring us JOY  </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#F2D6C0" }} >
                    <div className="container p-lg-4 pt-5 ">
                        <div className="row p-lg-4 d-flex justify-content-center">
                            <div className="text-center widthRESOPNSIVE">
                                <img src={require("../images/iCloud-logo.png")} alt="iCloud-logo" title="iCloud-logo" className="logo" />
                                <p className="SolutionDropDownFontFamily pb-1 pt-3" style={{ fontSize: "22px", color: "#BA1A28", fontWeight: "800" }} >2019 Web Development Company.com</p>
                                <p className="" style={{ fontSize: "13px" }} >Unauthorized use and/or duplication of this material without express and written permission from this site's author and/or owner is strictly prohibited. Excerpts and links may be used, provided that full and clear credit is given to 2019 Web Development Company.com with appropriate and specific direction to the original content.</p>
                                <div className="pt-2">
                                    <i class="fa-brands fa-square-instagram p-3" style={{ fontSize: "50px" }} ></i>
                                    <i class="fa-brands fa-square-facebook p-3" style={{ fontSize: "50px" }} ></i>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Blog;