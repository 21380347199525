import React from "react";
import Header from "./Header";
import { NavLink } from "react-router-dom";


const Frontend =()=>{

    return(
        <>
        <Header/>
        <div className="info">
            <div class="text-left ms-5 p-2">
            <NavLink exact to="/" style={{textDecoration:"none" ,color:"grey"}}>Home</NavLink>   Design 
            </div>
           </div>
           <div className="container-fluid">
            <div className="row dot1">
                <div className=" col-md-6 dot-box bg">
                    <h1 className="mt-5">Front-End Development Services </h1>
                    <p>Any Frontend Development Company can scrape together a few lines of code. We take you far beyond this. By maximizing the user experience, by developing design pieces into tangible pieces, we contemplate big ideas to tiny details and provide experience that inspires people.</p>
                </div>
                <div className="col-md-6 dot-box bg">
                    <img src={require("../images/Frontend.png")} alt="Frontend"  className="w-75 p-4"/>
                </div>
            </div>
            <div className="ux-box bg-light p-5">
                <h6>Our Passion</h6>
                <h2 className="mb-4">Front-end Development Services To Create Human-Centered Experiences With Visual Polish</h2>
                <p className="mb-5">Your project begins with what users identify and interact with. Poor development is certain to create a negative impact on your brand and user experience. Our front-end development team doesn’t let you fall into this immediate risk, by developing a complete package website from intuitive feel to look, functional experience to swiftness while ensuring you set off with the right strategies. Because, hitting bull’s eye without a right UX strategy is not possible.</p>
            </div>
            <div className="row ux1">
            <div className="col-md-6 dot-box bg">
                    <img src={require("../images/Angular.png")} alt="Angular" className="p-5 w-75"/>
                </div>
                <div className="col-md-6 dot-box bg">
                    <h1>Angular Development </h1>
                    <p>Drive your business growth with intuitive applications and dynamic single-page web applications from top Angular Development Company. Our experienced Angular developers build applications with optimal structure while delivering the best of Angular development services.</p>
                    <p>We as the leading Angular Development Company, blend the agile concepts, innovator’s approach, and our extensive experience to develop single page applications that are integrated with the aspects of MVVM and MVC. The deep and broadened experience of Angular developers into HTML benefits them to leverage the simplified component base framework completely and develop a web application with declarative UI and modular structure.</p>
                    <p>Level-up your web presence goals by hiring our proficiencies in Angular development services and see your business goals transforming to technical approaches.</p>
                </div>
                
            </div>
            <div className="ux-box bg-light p-5">
                    <h1>ExpressJS Development </h1>
                    <p>We bring the speed and efficiency of NodeJS framework – ExpressJS, to the front-end and back-end of your business applications. We understand your idea and application stands higher than the code, so with our quality code we make every effort to transform your solidified idea into a robust server-side application.</p>
                    <p>We help you experience the speed and scalability of ExpressJS with applications that stand brave on flexibility, speed, interactivity and simplicity. We are a leading ExpressJS development company in India that dedicatedly works to blend business objectives with best experiences. Being your go-to choice for ExpressJS development services, we do every effort to see your business goals transforming into an absolute server-side application. Our ExpressJS developers dig deeper to utilize framework’s potentials and bring out the best solutions. We foster high-end company growth with highly-scalable and real-time applications.</p>
               
                
            </div>
            <div className="row ux1">
               <div className="col-md-6 dot-box bg">
                    <img src={require("../images/NodeJS-1.png")} alt="NodeJS-1" className="p-5 w-75"/>
                </div>
                <div className="col-md-6 dot-box bg">
                    <h1>NodeJS Development </h1>
                    <p>We push your idea through our comprehensive and efficient NodeJS development services powered by adroit trained resources, who are proficient with the depths of technology.</p>
                    <p>The smart digital footprint of businesses widely embrace Nodejs because of its flexibility, adaptability and capability to change as per need. By hiring NodeJS Development Company you can actually implement an agile approach with faster and concurrent testing and implementation of changes. With our best-in-class NodeJS services you can develop rich, highly scalable and non-buffering applications in real-time.</p>
                </div>
               
            </div>
            <div className="ux-box bg-light p-5">
                    <h1>VueJS Development Services</h1>
                    <p>Known by its easy integration and flexible single page app development, we are early adopters and implementers of topnotch JavaScript framework VueJS, delivering great Frontends. Our VueJS applications are scalable, setting a high benchmark for interactiveness and intuitiveness.</p>
                    <p>We don’t just build beautiful front-ends. We build winning business solutions.</p>
                    <p>We know the right front-end development math.</p>
                    <p>Interactive UI + Engaged Users = High ROI.</p>
                    <p>When the word is about interactive UI, we are the best with our VueJS solutions. VueJS web developer takes the complete leverage of VueJS features, JavaScript libraries, component-composition caching, server-side declarative rendering, and its MVVM architecture, to develop user experience that will invite and retain users. With hands-down experience, we develop single-page web apps, tough to think Native, hybrid-Phonegap or Cordova apps.</p>
                    <p>We are a VueJS Development Company that doesn't stop until our development endeavors reach a competent space. We accept challenges that brings results within the defined time and cost of the project.</p>              
            </div>
            <div className="row ux1">
                <div className="col-md-6 dot-box bg">
                    <h1>React JS Development Services </h1>
                    <p>Appealing in speed and flawless in function, that’s the core of our ReactJS Development Services. Our ReactJS developers leverage the component based architecture of React to translate the business processes into lightning fast and rich real-time internet applications.</p>
                    <p>React, which is backed by top brands like Facebook, Instagram, Yahoo and one with most advanced frontend libraries, can be the best platform for web app development. In the age of most ho-hum websites and apps, we as the best ReactJs Development Company don’t settle for just ordinary. On the web, we take you to a space where you are the best of all. Rich internet apps. Best user interface. Quick Real-time updates. Swift loading. High performance. That’s our promised ReactJS development metaverse.</p>
                    <p>ReactJS gives you an edge for any kind of high-data web apps development. We give you an edge with swift, robust ReactJS apps with watermark efficiency.</p>
                </div>
                <div className="col-md-5 dot-box bg">
                    <img src={require("../images/ReactJS.png")} alt="ReactJS" className="p-5 w-75"/>
                </div>
                
            </div>
            
         </div>   
        
        </>
    )
}
export default Frontend;