import React from "react";
import Header from "./Header";
import { NavLink } from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";

const MicrosoftServices =()=>{

    return(
        <>
        <Header/>
        <div className="info">
            <div class="text-left ms-5 p-2">
            <NavLink exact to="/" style={{textDecoration:"none" ,color:"grey"}}>Home</NavLink> <NavigateNext/>  Microsoft 
            </div>
           </div>
           <div className="container-fluid">
            <div className="row dot1">
                <div className=" col-md-6 dot-box bg">
                    <h1 className="mt-5">Microsoft Development  Services </h1>
                    <p>We create memorable websites that sell. With our eCommerce expertise we work on every element of your website that perfectly aligns with your brand voice and volumes. If you are proud of your brand, our eCommerce development expertise will make you proud of your website.</p>
                </div>
                <div className="col-md-6 dot-box bg">
                    <img src={require("../images/Microsoft-1.png")} alt="Microsoft-1"  className="w-75 p-4"/>
                </div>
            </div>
            <div className="ux-box bg-light p-5">
                <h6>Our Passion</h6>
                <h2 className="pb-4">Microsoft Development Services for enterprise and startups.</h2>
                <p className="pb-5">Dedicated Microsoft developers at ICLOUDSOFT are here to unlock the true value of the business and fuel the growth opportunities with Microsoft development services. We implement the best strategy and development approach to ASP.NET and MVC.NET platforms to provide experiences that complete the digital ecosystem. With our .NET expertise we translate critical backends into exceptional UX to make complex functions seamless. Armed with robust software development competencies, backend development and consulting, ICLOUDSOFT makes most out of Microsoft platforms.</p>
            </div>
            <div className="row ux1">
            <div className="col-md-5 dot-box bg">
                    <img src={require("../images/ASP-NET.png")} alt="ASP-NET" className="p-5 w-75"/>
                </div>
                <div className="col-md-6 dot-box bg">
                    <h1>ASP.NET Development </h1>
                    <p>Whether you’re pursuing a new dynamic software solution or you are embarking to revive a legacy project, our ASP.NET development services transform your intimidating business challenges. Hire Asp.NET developers, evolving backend teams to develop dynamic, scalable, and standard web applications.</p>
                    <p>iCloudsoft Technology with its expanded .NET development services develops excellent enterprise-grade web applications for different domains. We have a long background in successfully implementing different microsoft technologies.</p>
                </div>
                
            </div>
            <div className="ux-box bg-light p-5">
               
                    <h1>MVC Dotnet Development Services </h1>
                    <p>When you want to create new grounds of innovation, no off-the-shelf applications will work for your goal. With our expertise in MVC DotNet we map your custom requirements, connect and complete your digital objectives with custom software that acts as a bridge between your vision and reality.</p>
                    <p>For your enterprise app development needs we can provide you industry's best MVC Dotnet development services by consolidating technical expertise and domain knowledge. Our dedicated Microsoft certified ASP.NET MVC developers boasts of huge expertise and track record in developing cutting-edge solutions using Microsoft technologies such as ASP.NET, ASP.NET MVC, Dot NET Core MVC, C#, .NET Microservices, .NET WCF and a host of custom and third party Web APIs.</p>
               
                
            </div>
         </div>   
        
        </>
    )
}
export default MicrosoftServices;