import React from "react";
import Header from "./Header";


const GameDevelopment = () => {

     return (
          <>

               <HelmetProvider>
                    <Helmet>
                         <meta charset="utf-8" />
                         <link rel="canonical" href="https://www.icloudsoft.co.in/gamedevelopment" />
                         <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,GameDevelopment " />
                         <title> GameDevelopment | Web Development Company In Pune  </title>
                    </Helmet>
               </HelmetProvider>

               <Header />
               <div className="Company bg-light">

                    <div className="company-boxA">
                         <div className="row p-4  m-2 ">
                              <div className="col-md-6 ps-5 mt-5">
                                   <h1><b>Game Development</b></h1>
                                   <p>Hire Game developers from a top game development company and Step Up Your game for you and every gamer out there. Throughout our entire life, we have been addicted to at least one game, whether it could be a card game, candy crush, or GTA. Like some of these, there are tons of fantastic games that exist already and will come into the market to entertain gamers, all thanks to game development.</p>
                                   <p>Whether it's a need to develop a ludo gaming application or a metaverse-based gaming application, proficient game developers of iCloudsoft can be a perfect choice. The game development team of iCloudsoft  can furnish your game development idea by combining amazing graphics with their years of experience and expertise to make it more than just a simple gaming application.</p>
                              </div>
                              <div className="col-md-6">
                                   <img src={require("../images/game.png")} alt="game.png" className="img-fluid w-75 " />
                              </div>
                         </div>
                    </div>



                    <div className="company-box2  bg-secondary-subtle m-3">
                         <h2 className="text-center p-3 mb-3">Area Of Expertise</h2>
                         <div className="row">
                              <div className="col-md-4 mb-3">
                                   <div className="co d-flex">
                                        <h5 className="ms-5 mt-4">2D/3D Game Development</h5>
                                   </div>
                                   <p className="p-3">Want to offer a fantastic 2D gaming experience or an immersive gaming experience through 3D game development; game developers at iCloudsoft hold years of experience and expertise for both.</p>
                              </div>
                              <div className="col-md-4">
                                   <div className="co  d-flex">
                                        <h5 className="ms-5 mt-4">HTML 5 Game Development</h5>

                                   </div>
                                   <p className="p-3">Need a browser-based cross-platform gaming solution? HTML 5 game development is the perfect solution for you. Hire dedicated game developers from iCloudsoft who are efficient enough to work with HTML 5 and fulfill your development requirements just the way you want.</p>
                              </div>
                              <div className="col-md-4">
                                   <div className="co d-flex">
                                        <h5 className="ms-5 mt-4">Unity 3D Game Development</h5>

                                   </div>
                                   <p className="p-3">Hire experienced game developers from a top game development company that can create three-dimensional games through unity 3D game development that are appealing to play offering support throughout the game conceptualization to development.</p>
                              </div>
                              <div className="col-md-4">
                                   <div className="co d-flex">
                                        <h5 className="ms-5 mt-4">Unreal Engine Game Development</h5>

                                   </div>
                                   <p className="p-3">Get a 3D cinematic gaming experience through unreal engine game development. Whether you have a game development idea that involves storytelling, photogrammetry, facial animation, character design, VR features, or anything else, our game developers know how to execute them well with Unreal engine game development.</p>
                              </div>
                              <div className="col-md-4">
                                   <div className="co d-flex">
                                        <h5 className="ms-5 mt-4">AR Game Development</h5>

                                   </div>
                                   <p className="p-3">AR game development is the perfect choice for a gaming application that combines the digital and the real world to give the inception to the new world. Clinging to the industry standards our game developers make sure to cater the best ever gaming solutions that fit your requirements perfectly well.</p>
                              </div>
                              <div className="col-md-4">
                                   <div className="co  d-flex">
                                        <h5 className="ms-5 mt-4">VR Game Development</h5>

                                   </div>
                                   <p className="p-3">Step into the virtual world with the help of an advanced tech stack to offer an immersive user experience through the VR game development service of iCloudsoft. Hire our game developers that are proficient enough to convert the most crucial concept into a working VR-based gaming solution using their technical development skills.</p>
                              </div>
                         </div>
                    </div>
               </div>


          </>
     )
}
export default GameDevelopment;