import React from "react";
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const PHP = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoft.co.in/php" />
                    <meta name="keywords" content="Web Development Company In Pune , iCloudsoft Technology,PHP " />
                    <title> PHP | Web Development Company In Pune  </title>
                </Helmet>
            </HelmetProvider>

            <Header />

            <div className="Company bg-light">
                <div className="company-boxA">
                    <div className="row p-lg-4 g-4 m-2 ">
                        <div className="col-md-6 ps-lg-5 mt-5">
                            <h1 className="mb-3"><b>PHP development services</b></h1>
                            <p >PHP development services are aimed at implementing secure and powerful server-sides of all types of web applications. Drawing on PHP development, ScienceSoft skillfully tames PHP to deliver robust web portals, B2C & B2B web apps, CMSs, and other web-based solutions.</p>
                            <p>PHP (Hypertext Preprocessor) is known as a general-purpose scripting language that can be used to develop dynamic and interactive websites. It was among the first server-side languages that could be embedded into HTML, making it easier to add functionality to web pages without needing to call external files for data.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../images/php-services.png")} alt="" className="img-fluid w-50 " style={{ marginLeft: "3rem" }} />
                        </div>
                    </div>
                </div>

                <div className="company-box2 bg-secondary-subtle m-3">
                    <h2 className="text-center p-3 mb-3">Area Of Expertise</h2>
                    <div className="row g-4">
                        <div className="col-md-6 ">
                            <div className="co p-2 d-flex">
                                <h3 className="ms-3 mt-2 mb-2">PHP consulting</h3>
                            </div>
                            <p className="p-3">We help you select the technology mix for a planned PHP project or provide expert recommendations on how to solve the tech challenges of an ongoing project:Identifying the system’s bottlenecks.Checking security vulnerabilities.Preparing an optimal scalability map</p>
                        </div>
                        <div className="col-md-6">
                            <div className="co p-2 d-flex">
                                <h3 className="ms-3 mt-2 mb-2">PHP development</h3>
                            </div>
                            <p className="p-3">Depending on the preferred interaction pattern, you can choose either an offshore dedicated team or team augmentation. We will make sure that the right candidates are preselected as per requirements set forth and available for your screening, with the process facilitated by our managers. And if you’re looking for a vendor to take over the whole PHP development process, you can explore our PHP outsourcing services.</p>
                        </div>


                    </div>
                </div>
            </div>

        </>
    )
}
export default PHP;