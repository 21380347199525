import React from "react";
import "./Header.css";
import { NavLink } from "react-router-dom";

const Header = () => {

  return (
    <>

      <nav class="navbar navbar-expand-lg " >
        <div class="container-fluid ">
          <img src={require("../images/iCloud-logo.png")} alt="iCloud-logo" title="iCloud-logo" className="logo" />
          <button class="navbar-toggler " type="button " data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav  me-auto " style={{ backgroundColor: "transparent" }} >
              <NavLink exact to="/" style={{ textDecoration: "none", color: "black" }}>
                <li class="nav-item mt-3" style={{ fontWeight: "400" }}>
                  <h6>Home</h6>
                </li></NavLink>

              <li className="nav-item dropdown menu-area" >
                <h6 className="mt-3" id="mega-one" role="button" data-bs-toggle="dropdown" >Solutions</h6>
                <ul className="dropdown-menu mega-area" aria-labelledby="mega-one">
                  <div className="row h1" >
                    <div className="col h2 head p-lg-5">
                      <h4>Solution</h4>
                      <p>We build on the IT domain expertise and industry knowledge to design sustainable technology solutions</p>
                    </div>
                    <div className="col h2">
                      <h5 className='dropdown-item' aria-labelledby="dropdownMenu2"><b>Solutions</b></h5>
                      <NavLink exact to="/solutions/crm" style={{ textDecoration: "none" }} ><h5 className='dropdown-item'>CRM</h5></NavLink>
                      <NavLink exact to="/marketing-advertising" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Marketing & Advertising</h5></NavLink>
                      <NavLink exact to="/human-resources" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Human Resources</h5></NavLink>
                      <NavLink exact to="/elearning" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>eLearning</h5></NavLink>
                      <NavLink exact to="/document-management" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Document Management</h5></NavLink>
                      <NavLink exact to="/supply-chain-management" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Supply Chain Management</h5></NavLink>
                      <NavLink exact to="/erp" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>ERP</h5></NavLink>
                    </div>
                    <div className="col h2">
                      <h5 className='dropdown-item' aria-labelledby="navbarDropdown"> <b></b></h5>
                      <NavLink exact to="/operations-management" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Operations Management</h5></NavLink>
                      <NavLink exact to="/financial-management" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Financial Management</h5></NavLink>
                      <NavLink exact to="/asset-management" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Asset Management</h5></NavLink>
                      <NavLink exact to="/project-management" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Project Management</h5></NavLink>
                      <NavLink exact to="/data-analysis" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Data Analysis</h5></NavLink>
                      <NavLink exact to="/e-commerce" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Ecommerce</h5></NavLink>
                      <NavLink exact to="/web-portals" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Web Portals</h5></NavLink>
                      <NavLink exact to="/cms" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>CMS</h5></NavLink>
                    </div>
                    <div className="col h2">
                      <h5 className='dropdown-item' aria-labelledby="dropdownMenu2"> <b>Industries</b></h5>
                      <NavLink exact to="/healthcare" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>HealthCare</h5></NavLink>
                      <NavLink exact to="/banking" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Banking ,Financial Services & Insurance</h5></NavLink>
                      <NavLink exact to="/manufacturing" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Manufacturing</h5></NavLink>
                      <NavLink exact to="/professional-services" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Professional Services</h5> </NavLink>
                      <NavLink exact to="/retail" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Retail</h5></NavLink>
                      <NavLink exact to="/transportation-and-logistics" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Transportation and Logistics</h5></NavLink>
                      <NavLink exact to="/oil-gas" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Oil & Gas</h5></NavLink>
                    </div>
                  </div>
                </ul>
              </li>

              <li className="nav-item dropdown menu-area" >
                <h6 className="mt-3" id="mega-one" role="button" data-bs-toggle="dropdown" >Technology</h6>
                <ul className="dropdown-menu mega-area" aria-labelledby="mega-one">
                  <div className="row h1">
                    <div className="col h2 head p-5">
                      <h4>Technologies</h4>
                      <p>Our expertise spans all major technologies and platforms, and advances to innovative technology trends.</p>
                    </div>
                    <div className="col h2">
                      <h5 className='dropdown-item'> <b>Programming</b></h5>
                      <NavLink exact to="/java" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Java</h5></NavLink>
                      <NavLink exact to="/net" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> .NET</h5></NavLink>
                      <NavLink exact to="/python" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Python</h5></NavLink>
                      <NavLink exact to="/javascript" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Javascript</h5></NavLink>
                      <NavLink exact to="/c++" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>C++</h5></NavLink>
                      <NavLink exact to="/nodejs" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Node.js</h5></NavLink>
                      <NavLink exact to="/react" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>React </h5></NavLink>
                      <NavLink exact to="/php" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>PHP </h5></NavLink>
                      <NavLink exact to="/mobile-app-development" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Mobile</h5></NavLink>

                    </div>
                    <div className="col h2">
                      <h5 className='dropdown-item'> <b>Trending</b></h5>
                      <NavLink exact to="/big-data" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Big Data</h5> </NavLink>
                      <NavLink exact to="/data-science" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Data Science</h5></NavLink>
                      <NavLink exact to="/artificialintelligence" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Artifical Intelligence</h5></NavLink>
                      <NavLink exact to="/virtual-reality" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Virtual Reality </h5></NavLink>
                      <NavLink exact to="/augmented-reality" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Augmented Reality </h5></NavLink>
                      <NavLink exact to="/computer-vision" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Computer Vision </h5></NavLink>
                    </div>
                    <div className="col h2">
                      <h5 className='dropdown-item'> <b>Platforms</b></h5>
                      <NavLink exact to="/microsoft" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Microsoft</h5></NavLink>
                      <NavLink exact to="/power-apps" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Power Apps</h5></NavLink>
                      <NavLink exact to="/power-bi" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Power BI</h5></NavLink>
                      <NavLink exact to="/sharepoint-office-365" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>SharePoint and Office 365 </h5></NavLink>
                      <NavLink exact to="/salesforce" style={{ textDecoration: "none" }}>   <h5 className='dropdown-item'>Salesforce</h5></NavLink>

                    </div>
                  </div>
                </ul>
              </li>

              <li className="nav-item dropdown menu-area  " autoClose="inside"   >
                <h6 className="mt-3" data-bs-toggle="dropdown" >Services</h6>
                <ul className="dropdown-menu mega-area" aria-labelledby="mega-one">
                  <div className="row h1">
                    <div className="col h2 head p-5">
                      <h4>Services</h4>
                      <p>Our service portfolio covers an entire software development life cycle and meets varied business needs.</p>
                    </div>
                    <div className="col h2">
                      <NavLink exact to="/software-development" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Software Development</h5></NavLink>
                      <NavLink exact to="/web-development" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Web Development</h5></NavLink>
                      <NavLink exact to="/testing-qa" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Testing and QA </h5></NavLink>
                      <NavLink exact to="/application-services" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Application Services</h5></NavLink>
                      <NavLink exact to="/ux-ui-design" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>UX/UI Design</h5></NavLink>
                      <NavLink exact to="/data-analysis" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Data Analystics</h5></NavLink>
                      <NavLink exact to="/e-commerce" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>E-Commerce</h5></NavLink>

                    </div>
                    <div className="col h2">
                      <NavLink exact to="/blockchain" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Blockchain</h5></NavLink>
                      <NavLink exact to="/al-ml" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Al & ML</h5></NavLink>
                      <NavLink exact to="/iot" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Internet Of Things</h5></NavLink>
                      <NavLink exact to="/devops" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>DevOps</h5></NavLink>
                    </div>
                  </div>
                </ul>
              </li>

              <NavLink exact to="/company" style={{ textDecoration: "none", color: "black" }}><li class="nav-item mt-3">
                <h6>Company</h6>
              </li></NavLink>

              <NavLink exact to="/Blog" style={{ textDecoration: "none", color: "black" }}><li class="nav-item mt-3">
                <h6>Blog</h6>
              </li></NavLink>

              <NavLink exact to="/contact-us" style={{ textDecoration: "none", color: "black" }}><li class="nav-item mt-3">
                <h6>Contact Us</h6>
              </li></NavLink>
            </ul>
            {/* <ul class="navbar-nav d-flex flex-row mb-2 w-0 justify-content-end">
              <li class="nav-item me-3 me-lg-0 ">
                <NavLink to="http://wa.me/9527468898" aria-label="whatsapp" rel="noopener" target="_blank" style={{ color: "green", marginRight: "2px" }}><i class="fa-solid fa-envelope me-3 fs-1"></i></NavLink>
                <NavLink to="https://www.facebook.com/icloudsofttechnology" aria-label="facebook" rel="noopener" target="_blank" style={{ color: "blue", marginRight: "2px" }}><i class="fa-solid fa-envelope me-3 fs-1"></i></NavLink>
                <NavLink to="mailto:info@icloudsoft.co.in" aria-label="email" rel="noopener" target="_blank" style={{ color: "Orange" }}><i class="fa-solid fa-envelope me-3 fs-1"></i></NavLink>
                <NavLink to="https://www.linkedin.com/company/icloudsoft/" target="_blank" aria-label="linkedin" rel="noopener" style={{ color: "blue" }}><i class="fa-solid fa-envelope me-3 fs-1"></i></NavLink>
              </li>

            </ul> */}
          </div>
        </div>

        {/* <div class="container-fluid d-sm-none d-block" style={{backgroundColor:"#fff"}} >
          <img src={require("../images/iCloud-logo.png")} alt="iCloud-logo" title="iCloud-logo" className="logo" />
          <button class="navbar-toggler " type="button " data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav d-flex justify-content-end me-auto " style={{ backgroundColor: "transparent" }} >
              <NavLink exact to="/" style={{ textDecoration: "none", color: "black" }}>
                <li class="nav-item mt-3">
                  <h6>Home</h6>
                </li></NavLink>
              <li className="nav-item dropdown menu-area" >
                <h6 className="mt-3" id="mega-one" role="button" data-bs-toggle="dropdown" >Solutions</h6>
                <ul className="dropdown-menu mega-area" aria-labelledby="mega-one">
                  <div className="row h1" >
                    <div className="col h2 head p-5">
                      <h4>Solution</h4>
                      <p>We build on the IT domain expertise and industry knowledge to design sustainable technology solutions</p>
                    </div>
                    <div className="col h2">
                      <h5 className='dropdown-item' aria-labelledby="dropdownMenu2"><b>Solutions</b></h5>
                      <NavLink exact to="/solutions/crm" style={{ textDecoration: "none" }} ><h5 className='dropdown-item'>CRM</h5></NavLink>
                      <NavLink exact to="/marketing-advertising" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Marketing & Advertising</h5></NavLink>
                      <NavLink exact to="/human-resources" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Human Resources</h5></NavLink>
                      <NavLink exact to="/elearning" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>eLearning</h5></NavLink>
                      <NavLink exact to="/document-management" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Document Management</h5></NavLink>
                      <NavLink exact to="/supply-chain-management" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Supply Chain Management</h5></NavLink>
                      <NavLink exact to="/erp" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>ERP</h5></NavLink>
                    </div>
                    <div className="col h2">
                      <h5 className='dropdown-item' aria-labelledby="navbarDropdown"> <b></b></h5>
                      <NavLink exact to="/operations-management" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Operations Management</h5></NavLink>
                      <NavLink exact to="/financial-management" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Financial Management</h5></NavLink>
                      <NavLink exact to="/asset-management" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Asset Management</h5></NavLink>
                      <NavLink exact to="/project-management" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Project Management</h5></NavLink>
                      <NavLink exact to="/data-analysis" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Data Analysis</h5></NavLink>
                      <NavLink exact to="/e-commerce" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Ecommerce</h5></NavLink>
                      <NavLink exact to="/web-portals" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Web Portals</h5></NavLink>
                      <NavLink exact to="/cms" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>CMS</h5></NavLink>
                    </div>
                    <div className="col h2">
                      <h5 className='dropdown-item' aria-labelledby="dropdownMenu2"> <b>Industries</b></h5>
                      <NavLink exact to="/healthcare" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>HealthCare</h5></NavLink>
                      <NavLink exact to="/banking" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Banking ,Financial Services & Insurance</h5></NavLink>
                      <NavLink exact to="/manufacturing" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Manufacturing</h5></NavLink>
                      <NavLink exact to="/professional-services" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Professional Services</h5> </NavLink>
                      <NavLink exact to="/retail" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Retail</h5></NavLink>
                      <NavLink exact to="/transportation-and-logistics" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Transportation and Logistics</h5></NavLink>
                      <NavLink exact to="/oil-gas" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Oil & Gas</h5></NavLink>
                    </div>
                  </div>
                </ul>
              </li>
              <li className="nav-item dropdown menu-area" >
                <h6 className="mt-3" id="mega-one" role="button" data-bs-toggle="dropdown" >Technology</h6>
                <ul className="dropdown-menu mega-area" aria-labelledby="mega-one">
                  <div className="row h1">
                    <div className="col h2 head p-5">
                      <h4>Technologies</h4>
                      <p>Our expertise spans all major technologies and platforms, and advances to innovative technology trends.</p>
                    </div>
                    <div className="col h2">
                      <h5 className='dropdown-item'> <b>Programming</b></h5>
                      <NavLink exact to="/java" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Java</h5></NavLink>
                      <NavLink exact to="/net" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> .NET</h5></NavLink>
                      <NavLink exact to="/python" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Python</h5></NavLink>
                      <NavLink exact to="/javascript" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Javascript</h5></NavLink>
                      <NavLink exact to="/c++" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>C++</h5></NavLink>
                      <NavLink exact to="/nodejs" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Node.js</h5></NavLink>
                      <NavLink exact to="/react" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>React </h5></NavLink>
                      <NavLink exact to="/php" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>PHP </h5></NavLink>
                      <NavLink exact to="/mobile-app-development" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Mobile</h5></NavLink>

                    </div>
                    <div className="col h2">
                      <h5 className='dropdown-item'> <b>Trending</b></h5>
                      <NavLink exact to="/big-data" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Big Data</h5> </NavLink>
                      <NavLink exact to="/data-science" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Data Science</h5></NavLink>
                      <NavLink exact to="/artificialintelligence" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Artifical Intelligence</h5></NavLink>
                      <NavLink exact to="/virtual-reality" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Virtual Reality </h5></NavLink>
                      <NavLink exact to="/augmented-reality" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Augmented Reality </h5></NavLink>
                      <NavLink exact to="/computer-vision" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Computer Vision </h5></NavLink>
                    </div>
                    <div className="col h2">
                      <h5 className='dropdown-item'> <b>Platforms</b></h5>
                      <NavLink exact to="/microsoft" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Microsoft</h5></NavLink>
                      <NavLink exact to="/power-apps" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Power Apps</h5></NavLink>
                      <NavLink exact to="/power-bi" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Power BI</h5></NavLink>
                      <NavLink exact to="/sharepoint-office-365" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>SharePoint and Office 365 </h5></NavLink>
                      <NavLink exact to="/salesforce" style={{ textDecoration: "none" }}>   <h5 className='dropdown-item'>Salesforce</h5></NavLink>

                    </div>
                  </div>
                </ul>
              </li>

              <li className="nav-item dropdown menu-area  " autoClose="inside"   >
                <h6 className="mt-3" data-bs-toggle="dropdown" >Services</h6>
                <ul className="dropdown-menu mega-area" aria-labelledby="mega-one">
                  <div className="row h1">
                    <div className="col h2 head p-5">
                      <h4>Services</h4>
                      <p>Our service portfolio covers an entire software development life cycle and meets varied business needs.</p>
                    </div>
                    <div className="col h2">
                      <NavLink exact to="/software-development" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Software Development</h5></NavLink>
                      <NavLink exact to="/web-development" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Web Development</h5></NavLink>
                      <NavLink exact to="/testing-qa" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Testing and QA </h5></NavLink>
                      <NavLink exact to="/application-services" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Application Services</h5></NavLink>
                      <NavLink exact to="/ux-ui-design" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>UX/UI Design</h5></NavLink>
                      <NavLink exact to="/data-analysis" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Data Analystics</h5></NavLink>
                      <NavLink exact to="/e-commerce" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>E-Commerce</h5></NavLink>

                    </div>
                    <div className="col h2">
                      <NavLink exact to="/blockchain" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Blockchain</h5></NavLink>
                      <NavLink exact to="/gamedevelopment" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Game Development</h5></NavLink>
                      <NavLink exact to="/al-ml" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>Al & ML</h5></NavLink>
                      <NavLink exact to="/iot" style={{ textDecoration: "none" }}><h5 className='dropdown-item'> Internet Of Things</h5></NavLink>
                      <NavLink exact to="/devops" style={{ textDecoration: "none" }}><h5 className='dropdown-item'>DevOps</h5></NavLink>
                    </div>
                  </div>
                </ul>
              </li>
              <NavLink exact to="/company" style={{ textDecoration: "none", color: "black" }}><li class="nav-item mt-3">
                <h6>Company</h6>
              </li></NavLink>
              <NavLink exact to="/Blog" style={{ textDecoration: "none", color: "black" }}><li class="nav-item mt-3">
                <h6>Blog</h6>
              </li></NavLink>
              <NavLink exact to="/contact-us" style={{ textDecoration: "none", color: "black" }}><li class="nav-item mt-3">
                <h6>Contact Us</h6>
              </li></NavLink>
            </ul>
            <ul class="navbar-nav d-flex flex-row mb-2 w-50 justify-content-end">
              <li class="nav-item me-3 me-lg-0">
                <NavLink to="http://wa.me/9527468898" aria-label="whatsapp" rel="noopener" target="_blank" style={{ color: "green", marginRight: "2px" }}></NavLink>
                <NavLink to="https://www.facebook.com/icloudsofttechnology" aria-label="facebook" rel="noopener" target="_blank" style={{ color: "blue", marginRight: "2px" }}></NavLink>
                <NavLink to="mailto:info@icloudsoft.co.in" aria-label="email" rel="noopener" target="_blank" style={{ color: "Orange" }}></NavLink>
                <NavLink to="https://www.linkedin.com/company/icloudsoft/" target="_blank" aria-label="linkedin" rel="noopener" style={{ color: "blue" }}></NavLink>
              </li>

            </ul>
          </div>
        </div> */}
      </nav>

    </>
  )
}
export default Header;